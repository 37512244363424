import { theme } from 'antd'
import { useConfig, useCurrentShop } from 'api'
import { FormCheckbox, FormInputNumber } from 'components/form-inputs'
import { FormSection } from 'components/form-section/form-section'
import { Message, PageError } from 'components/styled'
import { useEffect } from 'react'
import { Field, useField } from 'react-final-form'
import { Config, Feature } from 'types'
import { isNil, useTranslate } from 'utils'

export const ServiceGroupAppointment = () => {
    
    //hook:
    const { token: { margin } } = theme.useToken()
    const { __ } = useTranslate()
    const { data: config } = useConfig()
    const { data: shop } = useCurrentShop()
    
    //field:
    const { input: { value: groupAppointments } } = useField('groupAppointments')
    const { input: { onChange: updateGroupAppointmentSlots } } = useField('groupAppointmentSlots')

    useEffect(() => {
        if(!groupAppointments){
            updateGroupAppointmentSlots(null)
        }
    }, [groupAppointments])

    //hasGroupAppointment:
    const hasGroupAppointment = config && config.features.includes(Feature.GROUP_APPOINTMENTS)

    //render:
    return (!config || !shop) ? <PageError/> : (
        <FormSection
            title={__`group_appointments`}
            childrenWrapperStyle={{ display: 'flex', flexDirection: 'column', gap: margin }}
        >
            
            {/* multiple bookings */}
            <Field
                name='groupAppointments'
                text={
                    <>
                        {__`allow_multiple_bookings_per_time_slot`}
                        {!hasGroupAppointment && <Message type='warning' message={__`this_feature_is_not_available_due_to_your_plan`}/>}
                    </>
                }
                description={__`customers_can_book_multiple_appointments_per_time_slot`}
                type='checkbox'
                component={FormCheckbox}
                disabled={!hasGroupAppointment}
            />

            {/* max number */}
            <Field
                name='groupAppointmentSlots'
                title={
                    <>
                        {__`max_bookings_per_time_per_team_member`}
                        {!hasGroupAppointment && <Message type='warning' message={__`this_feature_is_not_available_due_to_your_plan`}/>}
                    </>
                }
                description={`${__`maximum_is`} ${findMaxGroupAppointments(config)}`}
                component={FormInputNumber}
                style={{ width: '100%' }}
                min={1}
                max={findMaxGroupAppointments(config)}
                keyboard
                disabled={!hasGroupAppointment || !groupAppointments}
            />

        </FormSection>
    )

}

//findMaxGroupAppointments:
const findMaxGroupAppointments = (config: Config) => {
    if(!isNil(config.plan?.maxGroupAppointmentsSlots)){
        return config.plan?.maxGroupAppointmentsSlots
    }else{
        return 0
    }
}
