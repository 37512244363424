import styled from 'styled-components'

type StepsContainerProps = {
    total: number
}

export const MainWrapper = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    gap: ${props => `${props.theme.marginSM}px`};
`

export const StepsContainer = styled.div<StepsContainerProps>`
    width: 100%;
    height: ${props => `${props.theme.marginSM*10/12}px`};
    display: flex;
    gap: ${props => `${props.theme.marginXXS}px`};
    padding-top: ${props => `${props.theme.paddingXXS/2}px`};
`

type StepProps = {
    colorRGB: string
}

export const Step = styled.div<StepProps>`
    flex: 1;
    height: 100%;
    background-color: ${props => props.colorRGB};
`

export const InfoWrapper = styled.div`
    display: flex;
    align-items: flex-end;
    gap: ${props => `${props.theme.marginXXS/2}px`};
`
