import { Config } from 'types'

//addDays:
export const addDays = (date: Date, days: number) => {
    const dateCopy = new Date(date.getTime())
    return new Date(dateCopy.setDate(dateCopy.getDate() + days))
}

//addHours:
export const addHours = (date: Date, hours: number) => {
    const dateCopy = new Date(date.getTime())
    return new Date(dateCopy.setHours(dateCopy.getHours() + hours))
}

//addMinutes:
export const addMinutes = (date: Date, minutes: number) => {
    const dateCopy = new Date(date.getTime())
    return new Date(dateCopy.setMinutes(dateCopy.getMinutes() + minutes))
}

//dateTimeToNumericHour:
export const dateTimeToNumericHour = (dateTime: string, currentDay: Date, startDayFromZero: boolean = false) => {
    const splittedDateTime = dateTime.split(' ')
    if(splittedDateTime.length === 2){
        
        const dateString = dateTime.split(' ')[0]
        const timeString = dateTime.split(' ')[1]
        const numericHour = timeToFloatHoursAndMinutes(timeString)

        if(isBeforeDate(new Date(`${dateString} 00:00`), currentDay)){
            if(startDayFromZero){
                return 0
            }else{
                const daysDifference = dateDiffInDays(new Date(dateTime), currentDay)
                const hoursDifferenceForDays = (daysDifference - 1)*24
                const hoursDifferenceForTime = 24 - numericHour
                return -(hoursDifferenceForDays + hoursDifferenceForTime)
            }
        }else if(isAfterDate(new Date(`${dateString} 00:00`), currentDay)){
            return 24
        }else{
            return numericHour
        }

    }else{
        return 0
    }
}

//timeToFloatHoursAndMinutes:
export const timeToFloatHoursAndMinutes = (time: string) => {
    const hour = timeToIntegerHours(time)
    const minute = timeToIntegerMinutes(time)
    return hour + minute/60
}

//timeToIntegerHours:
export const timeToIntegerHours = (time: string) => {
    const splittedTime = time.split(':')
    return Number(splittedTime[0])
}

//timeToIntegerMinutes:
export const timeToIntegerMinutes = (time: string) => {
    const splittedTime = time.split(':')
    return Number(splittedTime[1])
}

//dateDiffInDays:
export const dateDiffInDays = (first: Date, second: Date) => {
    const _MS_PER_DAY = 1000 * 60 * 60 * 24
    // Discard the time and time-zone information.
    const utc1 = Date.UTC(first.getFullYear(), first.getMonth(), first.getDate())
    const utc2 = Date.UTC(second.getFullYear(), second.getMonth(), second.getDate())
    return Math.floor((utc2 - utc1) / _MS_PER_DAY)
}

//isSameDay:
export const isSameDay = (first: Date, second: Date) =>
    first.getFullYear() === second.getFullYear() &&
    first.getMonth()    === second.getMonth()    &&
    first.getDate()     === second.getDate()

//isBeforeDate:
export const isBeforeDate = (first: Date, second: Date) =>
    (first.getFullYear()  <  second.getFullYear()) ||
    (first.getFullYear() === second.getFullYear() && first.getMonth()  <  second.getMonth()) ||
    (first.getFullYear() === second.getFullYear() && first.getMonth() === second.getMonth() && first.getDate() < second.getDate())

//isAfterDate:
export const isAfterDate = (first: Date, second: Date) =>
    (first.getFullYear()  >  second.getFullYear()) ||
    (first.getFullYear() === second.getFullYear() && first.getMonth()  >  second.getMonth()) ||
    (first.getFullYear() === second.getFullYear() && first.getMonth() === second.getMonth() && first.getDate() > second.getDate())

//getDateWeekday
export const getDateWeekday = (date: Date | string, local: string | string[] = ['en-US'] ) => {
    let dateObj = date
    if(typeof date === 'string'){
        dateObj = new Date(`${date} 00:00`)
    }
    return dateObj.toLocaleString(local, { weekday: 'long' })
}

//getIsoStringYearMonthDay:
export const getIsoStringYearMonthDay = (date: Date) => {
    const year  = date.toLocaleString(['en-US'], { year:  'numeric' })
    const month = date.toLocaleString(['en-US'], { month: '2-digit' })
    const day   = date.toLocaleString(['en-US'], { day:   '2-digit' })
    return `${year}-${month}-${day}`
}

//calcTimezoneOffset:
export const calcTimezoneOffset = (apiNow: string) => {
    const browserNow = new Date()
    const shopNow = new Date(apiNow)
    const offsetInHours = ((shopNow.getTime() - browserNow.getTime())/(1000*60*60))
    const offsetInHalfHours = offsetInHours/0.5
    const AbsOffsetInHalfHours = Math.abs(offsetInHalfHours)
    const roundUppedOffsetInHalfHours = offsetInHalfHours >= 0 ? Math.ceil(AbsOffsetInHalfHours) : -Math.floor(AbsOffsetInHalfHours)
    const roundUppedOffsetInMinutes = roundUppedOffsetInHalfHours*30
    return roundUppedOffsetInMinutes
}

//getTimeFromDateTimeString:
export const getTimeFromDateTimeString = (dateTime: string) => {
    const splitted = dateTime.split(' ')
    if(splitted.length === 2){
        return splitted[1]
    }else{
        return null
    }
}

//getDateRange:
export const getDateRange = (startDate: Date, duration: number, config: Config, weekDay: boolean) => {
    const dateTimeFormat = new Intl.DateTimeFormat([config.locale, 'en-US'], {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        weekday: weekDay ? 'short' : undefined
    })
    let endDate = new Date(startDate.getTime())
    endDate = addDays(endDate, duration - 1)
    return dateTimeFormat.formatRange(startDate, endDate)
}
