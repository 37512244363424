import styled from 'styled-components'
import { SLOT_DETAILS_NAVIGATION_Z_INDEX } from 'z-indexes'

export const MainWrapper = styled.div`
    width: 100%;
    padding: ${props => `
        ${props.theme.paddingLG}px
        ${props.theme.padding}px
        0
        ${props.theme.padding}px
    `};
    background-color: ${props => props.theme.colorBgContainer};
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: sticky;
    top: 0;
    left: 0;
    z-index: ${SLOT_DETAILS_NAVIGATION_Z_INDEX};
`
