import styled from 'styled-components'

type Props = {
    isDarkTheme: boolean
}

export const MainContainer = styled.div<Props>`
    width: calc(100% - ${props => props.theme.margin*2 + 'px'});
    max-width: ${props => props.theme.marginXXL*7.9 + 'px'};
    margin: ${props => props.theme.margin + 'px'};
    display: inline-block;
    padding: ${props => props.theme.paddingLG + 'px 0'};
    border-radius: ${props => props.theme.borderRadiusLG*2 + 'px'};
    background-color: ${props => props.isDarkTheme ? '#161A34' : 'transparent'};
    border: ${props => props.isDarkTheme ? 'solid 1px ' + props.theme.colorBorderSecondary : 'none'};
    box-shadow: ${props => props.isDarkTheme ? props.theme.boxShadow : 'none'};
`

export const MainWrapper = styled.div<Props>`
    width: 100%;
    min-height: ${props => props.theme.marginXXL*13.1 + 'px'};
    padding: ${props => props.theme.paddingMD*2 + 'px ' + props.theme.paddingXL + 'px'};
    border-radius: ${props => props.theme.borderRadiusLG*2 + 'px'};
    background-color: ${props => props.isDarkTheme ? 'transparent' : props.theme.colorBgContainer};
    border: ${props => props.isDarkTheme ? 'none' : 'solid 1px ' + props.theme.colorBorderSecondary};
    box-shadow: ${props => props.isDarkTheme ? 'none' : props.theme.boxShadow};
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: ${props => `${props.theme.marginXL}px`};
`

export const Header = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: ${props => `${props.theme.marginMD}px`};
`

export const TitleWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: ${props => `${props.theme.marginXXS}px`};
`

export const PriceWrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: ${props => `${props.theme.marginXS}px`};
`
