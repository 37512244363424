import styled from 'styled-components'
import { APPOINTMENT_DETAILS_EVENTS_SHADOW_Z_INDEX } from 'z-indexes'

export const MainWrapper = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
`

export const MainContainer = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
`

export const FlexSpacer = styled.div`
    width: 100%;
    flex: 1;
`

export const EventsButtonWrapper = styled.div`
    width: 100%;
    background-color: ${props => props.theme.colorBgContainer};
    position: sticky;
    left: 0;
    bottom: 0;
`

type EventsShadowProps = {
    isOpen: boolean
}

export const EventsShadow = styled.div<EventsShadowProps>`
    width: 100%;
    height: 100%;
    background-color: ${props => props.theme.colorTextTertiary};
    position: absolute;
    left: 0;
    bottom: 0;
    opacity: ${props => props.isOpen ? 1 : 0};
    visibility: ${props => props.isOpen ? 'visible' : 'hidden'};
    transition: visibility 300ms ease, opacity 300ms ease;
    z-index: ${APPOINTMENT_DETAILS_EVENTS_SHADOW_Z_INDEX};
`
