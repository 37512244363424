export const slotColors = {
    'purple': 'rgb(85, 50, 135)',
    'indigo': 'rgb(40, 65, 120)',
    'blue': 'rgb(0, 70, 170)',
    'teal': 'rgb(30, 105, 90)',
    'green': 'rgb(10, 120, 10)',
    'yellow': 'rgb(100, 110, 0)',
    'orange':'rgb(180, 75, 35)',
    'red':'rgb(222, 54, 24)',
    'sky': 'rgb(50, 90, 190)',
    'ink': 'rgb(25, 45, 55)',
}

export const syncedSlotColor = 'rgb(100, 100, 100)'
