import { Logo } from './logo'
import styled from 'styled-components'
import { LoadingOutlined } from '@ant-design/icons'
import { useWindowDimensions } from 'utils'
import { ScreenSize } from 'types'

type Props = {
    style?: object
}

export const LogoLoading = ({ style }: Props) => {

    const { height } = useWindowDimensions()

    return (
        <Wrapper style={style}>
            <div style={{ transform: height > ScreenSize.SM ? 'translate(0, -75%)' : undefined }}>
                <Logo withName width={160}/>
            </div>
        </Wrapper>
    )
}

export const Loading = ({ style }: Props) => (
    <Wrapper style={style}>
        <LoadingOutlined/>
    </Wrapper>
)

const Wrapper = styled.div`
    width: 100%;
    height: 100%;
    padding: ${props => `${props.theme.paddingXS}px`};
    font-size: ${props => `${props.theme.fontSizeXL}px`};
    color: ${props => props.theme.colorTextSecondary};
    display: flex;
    justify-content: center;
    align-items: center;
`
