import { useEffect, useRef, useState } from 'react'

export const useElementPosition = (elementId: string) => {

    const lastEventTimeoutId = useRef(0)

    const calcElementPosition = () => {

        const element = document.getElementById(elementId)

        const bcr = element?.getBoundingClientRect()
        const top = bcr?.top
        const bottom = bcr?.bottom
        const left = bcr?.left
        const right = window.innerWidth - (bcr?.right ?? 0)
        
        return { top, bottom, left, right }

    }
    
    const [ elementPosition, setElementPosition ] = useState(calcElementPosition())
    const [ isFirstLoading, setIsFirstLoading ] = useState(true)

    useEffect(() => {

        const handleEvent = () => {
            clearTimeout(lastEventTimeoutId.current)
            const timeoutId = setTimeout(() => setElementPosition(calcElementPosition()), 100)
            lastEventTimeoutId.current = timeoutId as unknown as number
        }

        if(isFirstLoading){
            handleEvent()
            setIsFirstLoading(false)
        }

        window.addEventListener('resize', handleEvent)

        return () => window.removeEventListener('resize', handleEvent)

    }, [])

    return elementPosition

}
