import React from 'react'
import { queryClient } from 'utils'
import { QueryClientProvider } from '@tanstack/react-query'
import { ConfigProvider } from 'antd'
import { ThemeProvider } from 'context/theme-provider'
import { BrowserRouter } from 'react-router-dom'
import { App } from 'app'
import { theme } from 'data'

export const AppContexts = () => (
    <React.StrictMode>
        <QueryClientProvider client={queryClient}>
            <ConfigProvider theme={{ token: theme }}>
                <ThemeProvider>
                    <BrowserRouter>
                        <App/>
                    </BrowserRouter>
                </ThemeProvider>
            </ConfigProvider>
        </QueryClientProvider>
    </React.StrictMode>
)
