import { canCreateFlow } from '../flow-list/flow-list'
import { useEffect, useState } from 'react'
import { setIn } from 'final-form'
import { useConfig, useCreateFlow, useCurrentShop, useFlowPreset, useFlows } from 'api'
import { Feature, FlowAction, FlowData, FlowFormSchema, FlowInterval, FlowTrigger, flowBodyExtensions } from 'types'
import { Loading, PageError } from 'components/styled'
import { getDefaultExtensionIndex } from 'components/flow/flow-template/flow-template'
import { beautifyJsonString, getShopId, queryClient, useTranslate } from 'utils'
import { useNavigate, useParams } from 'react-router-dom'
import { FlowForm } from 'pages/flow/flow-form/flow-form'
import { message } from 'antd'

export const CreateFlow = () => {
    
    //hooks:
    const { data: config } = useConfig()
    const { data: shop } = useCurrentShop()
    const params = useParams()
    const navigate = useNavigate()
    
    //flows:
    const { data: flows, total: totalFlows, isLoading: isFlowsLoading } = useFlows(null, 15)

    // redirect if it doesn't have access to flow:
    useEffect(() => {
        if(config && shop && flows){
            if(
                !config.features.includes(Feature.FLOWS) ||
                !canCreateFlow(config, true, totalFlows)
            ){
                navigate('/')
            }
        }
    }, [config, shop, flows])

    return (
        isFlowsLoading ? <Loading/> :
        !flows ? <PageError/> :
        params.presetId ? <CreateFlowWithPreset presetId={params.presetId}/> :
        <CreateFlowController preset={defaultValues}/>
    )
    
}

type CreateFlowWithPresetProps = {
    presetId: string
}

const CreateFlowWithPreset = ({ presetId }: CreateFlowWithPresetProps) => {
    const { data: flowPreset, isLoading } = useFlowPreset(presetId)
    return isLoading ? <Loading/> : !flowPreset ? <PageError/> :
        <CreateFlowController preset={flowPreset.data}/>
}

type CreateFlowControllerProps = {
    preset: FlowData
}

const CreateFlowController = ({ preset }: CreateFlowControllerProps) => {
    
    //translate:
    const { __ } = useTranslate()

    //navigation:
    const navigate = useNavigate()

    //state:
    const [ initialValues ] = useState({
        ...preset,
        template: {
            headers: beautifyJsonString(preset.template.headers),
            body: beautifyJsonString(preset.template.body)
        }}
    )
    const [ savingValue, setSavingValue ] = useState(preset)
    const [ action, setAction ] = useState(preset.action)
    const [ bodyExt, setBodyExt ] = useState(flowBodyExtensions[getDefaultExtensionIndex(preset.action)][0])

    //validateFormValues:
    const validateFormValues = (schema: any) => async (values: FlowData) => {
        if(values.action !== action){
            setAction(values.action)
        }
        if(typeof schema === 'function')
            schema = schema()
        try{
            await schema.validate(values, { abortEarly: false })
        }catch(err: any){
            const errors = err.inner.reduce((formError: object, innerError: any) => {
                return setIn(formError, innerError.path, innerError.message)
            }, {})
            return errors
        }
    }

    //save:
    const create = (values: FlowData) => {
        setSavingValue(values)
        mutate()
    }

    //create:
    const { mutate, isLoading, data: response } = useCreateFlow(savingValue)

    //after create:
    useEffect(() => {
        if(response){
            queryClient.invalidateQueries(['flows', getShopId()])
            message.success(__`flow_created`)
            navigate('/flow')
        }
    }, [isLoading])

    //render:
    return (
        <FlowForm
            initialValues={initialValues}
            onBodyExtensionChanged={i => setBodyExt(flowBodyExtensions[i][0])}
            isCreate={true}
            isSaving={isLoading}
            onValidate={validateFormValues(FlowFormSchema(action, bodyExt as string, __))}
            onSave={values =>
                FlowFormSchema(action, bodyExt as string, __).validate(values).then(
                    data => create(data as FlowData)
                )
            }
            onBack={() => navigate('/flow/presets')}
        />
    )
    
}

//default values:
const defaultValues: FlowData = {
    name: '',
    action: FlowAction.WEBHOOK,
    target: '',
    trigger: FlowTrigger.APPOINTMENT_CREATED,
    when: {
        amount: 0,
        unit: FlowInterval.IMMEDIATELY
    },
    template: {
        headers: '{\"Content-Type\": \"application/json\"}',
        body: '{\"data\": \"hello {{CustomerName}}!\"}'
    },
    status: true
}
