import styled from 'styled-components'
import { CALENDAR_PAGE_AVAILABLE_SLOT_WRAPPER_Z_INDEX } from 'z-indexes'

type MainWrapperProps = {
    height: number
    top: number
}

export const MainWrapper = styled.div<MainWrapperProps>`
    width: 100%;
    height: ${props => `${props.height}px`};
    padding: 1px 3px 1px 3px;
    position: absolute;
    top: ${props => `${props.top}px`};
    left: 0;
    z-index: ${CALENDAR_PAGE_AVAILABLE_SLOT_WRAPPER_Z_INDEX};
`

type ButtonWrapperProps = {
    isSelected: boolean
}

export const ButtonWrapper = styled.button<ButtonWrapperProps>`
    width: 100%;
    height: 100%;
    padding: 0;
    border: ${props => `solid 1px ${props.theme.colorPrimaryTextActive}`};
    border-radius: ${props => `${props.theme.borderRadiusSM}px`};
    outline: none;
    background-color: ${props => props.isSelected ? props.theme.colorPrimary : props.theme.colorBgContainer };
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    &:focus, &:hover{
        background-color: ${props => props.isSelected ? props.theme.colorPrimary : props.theme.colorPrimaryBg };
    }
`
