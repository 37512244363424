import { PageHeader } from 'components/page-header/page-header'
import { PlusOutlined } from '@ant-design/icons'
import { useTranslate, useWindowDimensions } from 'utils'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { CALENDAR_LCL_STR_SCALE_KEY, CALENDAR_DEFAULT_SCALE } from 'app.constants'
import { FormSection } from 'components/form-section/form-section'
import { Scale } from 'components/calendar/scale/scale'
import { CalendarPageNavigation } from 'components/calendar-page/calendar-page-navigation/calendar-page-navigation'
import { CalendarPageCalendar } from 'components/calendar-page/calendar-page-calendar/calendar-page-calendar'
import { PageContentWrapper, VerticalContainer } from 'components/styled'
import { theme } from 'antd'
import { TeamMember } from 'types'
import { useCurrentShop } from 'api'
import { CALENDAR_PAGE_CALENDAR_NAVIGATION_Z_INDEX, CALENDAR_PAGE_SCALE_Z_INDEX } from 'z-indexes'

export const CalendarPage = () => {

    //theme:
    const { token: {
        colorBgContainer,
        borderRadiusLG,
        marginXXS,
        margin,
        paddingXXS,
        paddingXS,
        paddingSM,
        padding,
        paddingLG
    }} = theme.useToken()

    //window dimensions:
    const { SM, LG } = useWindowDimensions()

    //translate:
    const { __ } = useTranslate()

    //navigation:
    const navigate = useNavigate()

    //shop:
    const { data: shop } = useCurrentShop()
    
    //state:
    const [ date, setSelectedDate ] = useState(new Date())
    const [ selectedTeamMembers, setSelectedTeamMembers ] = useState(undefined as TeamMember[] | undefined)
    const [ scale, setScale ] = useState(getScaleFromLocalStorage())
    const [ areSlotsLoading, setAreSlotsLoading ] = useState(false)

    //handleSaveScale:
    const handleSaveScale = (scale: number) => {
        setScale(scale)
        saveScaleToLocalStorage(scale)
    }

    //render:
    return (!shop ? null :
        <PageContentWrapper fullWidth noBottomPadding>

            <VerticalContainer style={{
                width: SM ? '100%' : 'fit-content',
                gap: SM ? margin : marginXXS
            }}>
                
                {/* header */}
                <div style={{
                    width: SM ? '100%' : '100vw',
                    position: SM ? 'static' : 'sticky',
                    left: 0
                }}>
                    <PageHeader
                        title={__`calendar`}
                        description={__`see_and_manage_your_teams_schedule`}
                        primaryButton={{
                            name: __`book`,
                            icon: <PlusOutlined/>,
                            onClick: () => navigate('/calendar/book')
                        }}
                    />
                </div>

                <div style={{ padding: SM ? `0 ${padding}px` : 0 }}>

                    <FormSection
                        style={{
                            paddingTop: SM ? paddingSM : 0,
                            paddingBottom: SM ? paddingXXS : 0,
                            flex: 1,
                        }}
                        childrenWrapperStyle={{ padding: 0 }}
                    >

                        {/* navigation */}
                        <div style={{
                            width: SM ? '100%' : '100vw',
                            paddingTop: SM ? paddingSM : padding,
                            paddingBottom: SM ? padding : paddingXS,
                            paddingLeft: SM ? paddingLG : padding,
                            paddingRight: SM ? paddingLG : padding,
                            backgroundColor: colorBgContainer,
                            position: 'sticky',
                            top: LG ? 60 : 0,
                            left: 0,
                            zIndex: CALENDAR_PAGE_CALENDAR_NAVIGATION_Z_INDEX
                        }}>
                            <CalendarPageNavigation
                                shop={shop}
                                startDate={date}
                                numberOfDays={1}
                                selectedTeamMembers={selectedTeamMembers}
                                areSlotsLoading={areSlotsLoading}
                                onStartDateChange={setSelectedDate}
                                onMembersChange={setSelectedTeamMembers}
                            />
                        </div>

                        {/* calendar */}
                        <div style={{
                            padding: `
                                0
                                ${SM ? (paddingLG) : 0}px
                                0
                                ${SM ? paddingLG : padding}px
                            `
                        }}>
                            <CalendarPageCalendar
                                date={date}
                                selectedTeamMembers={selectedTeamMembers}
                                scale={scale}
                                setAreSlotsLoading={areSlotsLoading => setAreSlotsLoading(areSlotsLoading)}
                            />
                        </div>

                        {/* scale */}
                        <div style={{
                            width: SM ? '100%' : '100vw',
                            padding: `0 ${SM ? paddingLG : padding}px`,
                            backgroundColor: colorBgContainer,
                            borderBottomLeftRadius: SM ? `${borderRadiusLG*2}px` : 0,
                            borderBottomRightRadius: SM ? `${borderRadiusLG*2}px` : 0,
                            position: 'sticky',
                            bottom: 0,
                            left: 0,
                            zIndex: CALENDAR_PAGE_SCALE_Z_INDEX
                        }}>
                            <Scale
                                scale={scale}
                                style={{ paddingRight: 0 }}
                                onScaleChange={handleSaveScale}
                            />
                        </div>

                    </FormSection>

                </div>

            </VerticalContainer>

        </PageContentWrapper>
    )
    
}

//getScaleFromLocalStorage:
const getScaleFromLocalStorage = () => {
    let scale = CALENDAR_DEFAULT_SCALE
    try{
        const scaleString = localStorage.getItem(CALENDAR_LCL_STR_SCALE_KEY)
        if(scaleString){
            scale = parseFloat(scaleString)
        }
    }catch(e){
        scale = CALENDAR_DEFAULT_SCALE
    }
    return scale ? scale : CALENDAR_DEFAULT_SCALE
}

//saveScaleToLocalStorage:
const saveScaleToLocalStorage = (scale: number) => {
    try{
        localStorage.setItem(CALENDAR_LCL_STR_SCALE_KEY, scale.toString())
    }catch(e){e}
}
