import { VerticalContainer } from 'components/styled'
import { env, useTranslate } from 'utils'
import { GoogleOutlined, LoadingOutlined } from '@ant-design/icons'
import { Button, Typography, theme } from 'antd'
import { useEffect, useState } from 'react'
import { useInitGoogleSync, useMe } from 'api'
const { Text } = Typography

export const ProfileGoogleStrategy = () => {

    //translate:
    const { __ } = useTranslate()

    //theme:
    const { token: { fontSize, fontSizeLG, padding, paddingXS } } = theme.useToken()

    //state:
    const [ isRedirecting, setIsRedirecting ] = useState(false)
    
    //me:
    const { data: me } = useMe()

    //mutation:
    const { mutate: init, isLoading, data: code } = useInitGoogleSync()

    //redirect:
    useEffect(() => {
        if(code && me){
            setIsRedirecting(true)
            location.href = `${env().GOOGLE_SYNC_ENDPOINT}?email=${me.email}&code=${code}`
        }
    }, [code, me])

    //render:
    return (
        <VerticalContainer>

            {/* title */}
            <Text style={{ fontFamily: 'inter-medium' }}>
                {__`connect_your_google_account`}
            </Text>
            
            {/* button */}
            <Button
                size='large'
                icon={isLoading || isRedirecting ? <LoadingOutlined style={{ fontSize: fontSizeLG }}/> : <GoogleOutlined style={{ fontSize: fontSizeLG }}/>}
                style={{
                    flex: 1,
                    width: '100%',
                    fontSize,
                    padding: `${paddingXS}px ${padding}px`
                }}
                onClick={() => !isLoading && !isRedirecting && init()}
            >
                {__`google`}
            </Button>

        </VerticalContainer>
    )
    
}
