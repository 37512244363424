import { OptionsWrapper } from './appointment-details-line-item-properties.style'
import { LineItemProperty } from 'types'
import { Info } from 'components/info/info'

type Props = {
    customOptions: LineItemProperty[]
}

export const AppointmentDetailLineItemProperties = ({ customOptions }: Props) => (
    <OptionsWrapper>
        {customOptions.map(option =>
            <Info
                key={option.name}
                name={option.name}
                value={option.value}
            />
        )}
    </OptionsWrapper>
)
