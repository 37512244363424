import { ContentColumn, MainWrapper, SlotsColumn, TimeBox, TimeBoxColumn, TimelineColumn } from './booking-calendar-cell.style'
import { useCell } from 'context/calendar-provider'
import { useConfig } from 'api'
import { BookingSlot, Config, FlexStartInterval } from 'types'
import { addHours, min } from 'utils'
import { TimelineBlock } from 'components/calendar/timeline-block/timeline-block'
import { BookingAction, BookingPageDispatch, BookingPageState } from 'pages/booking/booking-page/booking-page'
import { BookingAvailableSlot } from 'components/booking/booking-available-slot/booking-available-slot'

type BookingCalendarCellProps = {
    date: Date
    index: number
    startHourOfFirstSlotOfAllDays: number
    pageState: BookingPageState
    pageStateDispatch: BookingPageDispatch
}

export const BookingCalendarCell = ({ date, index, startHourOfFirstSlotOfAllDays, pageState, pageStateDispatch }: BookingCalendarCellProps) => {

    //hooks:
    const info = useCell(date)
    const slots: BookingSlot[] = info.slots
    const { data: config, isLoading: isConfigLoading } = useConfig()
    
    //day start hour:
    const dayStartHour = min(9, startHourOfFirstSlotOfAllDays)

    //getAvailableHours:
    const getAvailableHours = (config: Config) => {
        const hours: { value: number, formatted: string }[] = []
        let d = new Date(date.getTime())
        d.setHours(dayStartHour + 1)
        for(let i = d.getHours(); i <= 24; i++){
            hours.push({ value: i, formatted: d.toLocaleString([config.locale, 'en-US'], { hour: 'numeric' })})
            d = addHours(d, 1)
        }
        return hours
    }

    //isSlotAvailable:
    const isSlotAvailable = (slot: BookingSlot) =>
        (slot.type !== 'RESERVATION' && slot.type !== 'APPOINTMENT' && slot.type !== 'EXTERNAL' && slot.type !== 'BUFFER') || slot.status === 'available'

    //getServiceName:
    // const getServiceName = (slot: BookingSlot) =>
    //     slot.type === 'EXTERNAL' ? __`synced_event` : slot.type === 'RESERVATION' ? __`reserved` : slot.status === 'unavailable' ? __`booked` : __`available`
    
    //render:
    return (isConfigLoading || !config) ? null : (
        <MainWrapper>

            {/* timeline */}
            {index === 0 &&
                <TimelineColumn>
                        
                    {getAvailableHours(config).map(hour =>
                        <TimelineBlock
                            key={hour.formatted}
                            hour={hour.formatted}
                            scale={pageState.scale}
                        />
                    )}
                
                </TimelineColumn>
            }

            <ContentColumn>

                {/* time boxes */}
                <TimeBoxColumn>{
                    getAvailableHours(config).map(hour =>
                        <TimeBox
                            key={hour.formatted}
                            scale={pageState.scale}
                        />
                )}</TimeBoxColumn>

                {/* appointment slots */}
                <SlotsColumn>
                    
                    {slots.map(slot =>
                    
                        isSlotAvailable(slot) ?

                            <BookingAvailableSlot
                                key={getSlotKey(slot)}
                                date={date}
                                dayStartHour={dayStartHour}
                                startDateTime={slot.startDateTime}
                                duration={slot.duration}
                                length={getFlexStartAsNumber(pageState.service?.flexStartInterval) ?? slot.duration}
                                scale={pageState.scale}
                                isSelected={isSlotSelected(pageState.slot, slot)}
                                onClick={() => pageStateDispatch({
                                    type: BookingAction.SET_SLOT,
                                    payload: {
                                        slot: isSlotSelected(pageState.slot, slot) ? null : slot }
                                    })
                                }
                            />

                        :

                            <></>

                            // <TimeSlot
                            //     key={getSlotKey(slot)}
                            //     date={date}
                            //     isAvailable={false}
                            //     type={slot.type}
                            //     dayStartHour={dayStartHour}
                            //     startDateTime={slot.startDateTime}
                            //     duration={slot.duration}
                            //     scale={pageState.scale}
                            //     serviceName={getServiceName(slot)}
                            //     customer={slot.data?.customer?.name ?? slot.data?.customer?.email ?? null}
                            //     colorRGB={getColorById(isSlotAvailable(slot) ? pageState.service?.id : null)}
                            //     onClick={() => {}}
                            // />

                    )}

                </SlotsColumn>

            </ContentColumn>
            
        </MainWrapper>
    )

}

//getSlotKey:
const getSlotKey = (slot: BookingSlot) => `${slot.startDateTime} ${slot.duration} ${slot.remainingSlots} ${slot.status} ${slot.type}`

//isSlotSelected:
const isSlotSelected = (selectedSlot: BookingSlot | null, slot: BookingSlot) => selectedSlot ? getSlotKey(selectedSlot) === getSlotKey(slot) : false

const getFlexStartAsNumber = (flexStartInterval: FlexStartInterval | null | undefined) => {
    switch(flexStartInterval){
        case 'FIFTEEN':
            return 15
        case 'THIRTY':
            return 30
        case 'FORTY_FIVE':
            return 45
        case 'SIXTY':
            return 60
        default:
            return null
    }
}
