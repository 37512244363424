import { Button, Checkbox, theme } from 'antd'
import { Avatar } from 'components/avatar/avatar'
import { useWindowDimensions } from 'utils'

type Props = {
    name: string
    title: string
    image: string | null
    isSelected: boolean
    isDisabled: boolean
    onSelect: () => void
}

export const TeamServiceItem = ({
    name,
    title,
    image,
    isSelected,
    isDisabled,
    onSelect
}: Props) => {

    //theme:
    const { token: { padding, marginXS } } = theme.useToken()

    //window dimensions:
    const { SM } = useWindowDimensions()

    //render:
    return (
        <Button
            type='text'
            disabled={isDisabled}
            title={title}
            style={{
                width: '100%',
                height: 'auto',
                padding: `0 ${padding}px`,
                display: 'flex',
                alignItems: 'center',
                gap: marginXS,
                borderRadius: SM ? undefined : 0,
                opacity: isDisabled ? .7 : 1
            }}
            onClick={onSelect}>

            <Checkbox
                checked={isSelected}
                disabled={isDisabled}
            />
            
            <Avatar
                name={name}
                title={title}
                image={image}
                shape='square'
                size='small'
            />

        </Button>
    )
    
}
