import { BookingForm } from '../booking-form/booking-form'
import { BookingSlot, Service, ExternalCustomer, Variant, Appointment, SimpleTeamMember } from 'types'
import { useNavigate, useParams } from 'react-router-dom'
import { Dispatch } from 'react'
import { useAppointment } from 'api'
import { Loading, PageError } from 'components/styled'
import { useTranslate } from 'utils'
import { message } from 'antd'

export const BookingPage = () => {

    //params:
    const params = useParams()

    //navigation:
    const navigate = useNavigate()

    //preset:
    const appointmentId = params.appointmentId
    const serviceId = params.serviceId

    //render:
    return (
        appointmentId ? (
            <ReschedulePage
                appointmentId={appointmentId}
                onBack={() => navigate(findBookingPathRoute())}
            />
        ) : (
            <BookingForm
                servicePreset={serviceId ?? null}
                onSave={bookingId => navigate(`${findBookingPathRoute()}/book/draft/${bookingId}`)}
                onBack={() => navigate(findBookingPathRoute())}
            />
        )
    )
}

type ReschedulePageProps = {
    appointmentId: string
    onBack: () => void
}

const ReschedulePage = ({ appointmentId, onBack }: ReschedulePageProps) => {

    //translate:
    const { __ } = useTranslate()

    //navigation:
    const navigate = useNavigate()

    //appointment:
    const { data: appointment, isLoading: isAppointmentLoading } = useAppointment(appointmentId)

    //render:
    return isAppointmentLoading ? <Loading/> : !appointment ? <PageError/> : !appointment.service ? <PageError message={__`the_service_is_not_available`}/> : (
        <BookingForm
            servicePreset={{
                serviceId: appointment.service.id,
                externalVariantId: appointment.externalVariantId,
                participants: appointment.quantity
            }}
            appointment={appointment}
            onSave={() => {
                message.success(__`the_appointment_saved`)
                navigate('/calendar')
            }}
            onBack={onBack}
        />
    )

}

//page state:
export type BookingPageState = {
    service: Service | null
    variant: Variant | null
    participants: number
    teamMember: SimpleTeamMember | null
    appointment: Appointment | null
    customer: ExternalCustomer | null
    startDate: Date
    endDate: Date
    numberOfDays: number
    areSlotsLoading: boolean
    scale: number
    slot: BookingSlot | null
}

//actions type:
export enum BookingAction {
    SET_SERVICE,
    SET_VARIANT,
    SET_PARTICIPANTS,
    SET_TEAM_MEMBER,
    SET_APPOINTMENT,
    SET_CUSTOMER,
    SET_START_DATE,
    SET_END_DATE,
    SET_NUMBER_OF_DAYS,
    SET_ARE_SLOTS_LOADING,
    SET_SCALE,
    SET_SLOT
}

//actions:
export type BookingPageAction =
    | { type: BookingAction.SET_SERVICE          , payload: { service:         Service                 }}
    | { type: BookingAction.SET_VARIANT          , payload: { variant:         Variant | null          }}
    | { type: BookingAction.SET_PARTICIPANTS     , payload: { participants:    number                  }}
    | { type: BookingAction.SET_TEAM_MEMBER      , payload: { teamMember:      SimpleTeamMember | null }}
    | { type: BookingAction.SET_APPOINTMENT      , payload: { appointment:     Appointment             }}
    | { type: BookingAction.SET_CUSTOMER         , payload: { customer:        ExternalCustomer | null }}
    | { type: BookingAction.SET_START_DATE       , payload: { startDate:       Date                    }}
    | { type: BookingAction.SET_END_DATE         , payload: { endDate:         Date                    }}
    | { type: BookingAction.SET_NUMBER_OF_DAYS   , payload: { numberOfDays:    number                  }}
    | { type: BookingAction.SET_SCALE            , payload: { scale:           number                  }}
    | { type: BookingAction.SET_ARE_SLOTS_LOADING, payload: { areSlotsLoading: boolean                 }}
    | { type: BookingAction.SET_SLOT             , payload: { slot:            BookingSlot | null      }}

//dispatch:
export type BookingPageDispatch = Dispatch<BookingPageAction>

//default state
export const bookingPageDefaultState: BookingPageState = {
    service: null,
    variant: null,
    participants: 1,
    teamMember: null,
    appointment: null,
    customer: null,
    startDate: new Date(),
    endDate: new Date(),
    numberOfDays: 0,
    areSlotsLoading: false,
    scale: 1.5,
    slot: null
}

//pageStateReducer:
export const pageStateReducer = (pageState: BookingPageState, action: BookingPageAction ) => {
    switch(action.type){
        case BookingAction.SET_SERVICE:
            return {
                ...pageState,
                service: action.payload.service,
                variant: action.payload.service.variants[0],
                participants: 1,
                slot: null
            }
        case BookingAction.SET_VARIANT:
            return {
                ...pageState,
                variant: action.payload.variant,
                slot: null
            }
        case BookingAction.SET_PARTICIPANTS:
            return {
                ...pageState,
                participants: action.payload.participants,
                slot: null
            }
        case BookingAction.SET_TEAM_MEMBER:
            return {
                ...pageState,
                teamMember: action.payload.teamMember,
                slot: null
            }
        case BookingAction.SET_APPOINTMENT:
            return {
                ...pageState,
                useAppointment: action.payload.appointment,
                slot: null
            }
        case BookingAction.SET_CUSTOMER:
            return {
                ...pageState,
                customer: action.payload.customer
            }
        case BookingAction.SET_START_DATE:
            return {
                ...pageState,
                startDate: action.payload.startDate,
                slot: null
            }
        case BookingAction.SET_END_DATE:
            return {
                ...pageState,
                endDate: action.payload.endDate,
                slot: null
            }
        case BookingAction.SET_NUMBER_OF_DAYS:
            return {
                ...pageState,
                numberOfDays: action.payload.numberOfDays,
                slot: null
            }
        case BookingAction.SET_ARE_SLOTS_LOADING:
            return {
                ...pageState,
                areSlotsLoading: action.payload.areSlotsLoading
            }
        case BookingAction.SET_SCALE:
            return {
                ...pageState,
                scale: action.payload.scale
            }
        case BookingAction.SET_SLOT:
            return {
                ...pageState,
                slot: action.payload.slot
            }
        default:
            return pageState
    }
}

//findBookingPathRoute:
const findBookingPathRoute = () => location.pathname.includes('/service') ? '/service' : '/calendar'
