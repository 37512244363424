import { slotColors, syncedSlotColor } from 'data'

export const getColorById = (id: string | null | undefined) => {
    if(!id){//external
        return syncedSlotColor
    }
    const colorsArray = Object.keys(slotColors).map(key => slotColors[key as keyof typeof slotColors])
    const colorsLength = colorsArray.length
    const hash = stringHash(id)
    return colorsArray[(colorsLength - 1 - (hash % colorsLength))]
}

const stringHash = (str: string) => {
    let hash = 0, i, chr
    if(str.length === 0) return hash
    for(i = 0; i < str.length; i++){
        chr   = str.charCodeAt(i)
        hash  = ((hash << 5) - hash) + chr
        hash |= 0 //convert to 32bit integer
    }
    return Math.abs(hash)
}
