import styled from 'styled-components'

type MainWrapperProp = {
    scale: number
}

export const MainWrapper = styled.div<MainWrapperProp>`
    width: 100%;
    height: ${props => `${props.scale*60}px`};
`

export const TimeContainer = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
`

export const TimeBlock05 = styled.div`
    flex: 1;
    width: ${props => `${props.theme.marginXXS}px`};
    border-bottom: solid 1px ${props => props.theme.colorBorder};
`

export const TimeBlock15 = styled.div`
    flex: 1;
    width: ${props => `${props.theme.marginSM}px`};
    border-bottom: solid 1px ${props => props.theme.colorBorder};
`

export const TimeBlock30 = styled.div`
    flex: 1;
    width: ${props => `${props.theme.marginMD}px`};
    border-bottom: solid 1px ${props => props.theme.colorBorder};
`

type TimeBlock60Prop = {
    hour: string
}

export const TimeBlock60 = styled.div<TimeBlock60Prop>`
    flex: 1;
    width: 100%;
    position: relative;
    ::after{
        content: "${props => props.hour}";
        position: absolute;
        font-size: ${props => `${props.theme.fontSizeSM}px`};
        color: #777;
        left: 0;
        bottom: 0;
        transform: translate(0, 50%);
    }
`
