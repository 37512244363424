import { ButtonWrapper, ImageWrapper, MainContainer, MainWrapper, NameDescriptionWrapper } from './flow-card.style'
import { Button, Image, Typography, theme } from 'antd'
import { RightOutlined } from '@ant-design/icons'
import { setUnavailableCache, useTranslate } from 'utils'
import { useNavigate } from 'react-router-dom'
import { FlowPreset } from 'types'
const { Title, Paragraph } = Typography

type Props = {
    flowPreset: FlowPreset
}

export const FlowCard = ({ flowPreset }: Props) => {

    //hooks:
    const { token: { fontSize, fontSizeSM, marginXS } } = theme.useToken()
    const { __ } = useTranslate()
    const navigate = useNavigate()
    
    return (
        <MainContainer>
            <MainWrapper>

                {/* image */}
                <ImageWrapper>
                    <Image
                        height={165}
                        src={flowPreset.image}
                        preview={false}
                    />
                </ImageWrapper>

                <NameDescriptionWrapper>
                
                    {/* name */}
                    <Title
                        level={4}
                        style={{
                            fontWeight: 'normal',
                            margin: 0
                        }}
                    >
                        {flowPreset.name}
                    </Title>

                    {/* description */}
                    <Paragraph
                        type='secondary'
                        style={{ marginBottom: marginXS }}
                    >
                        {flowPreset.description}
                    </Paragraph>

                </NameDescriptionWrapper>

                {/* button */}
                <ButtonWrapper>
                    <Button
                        type="primary"
                        size='large'
                        style={{ fontSize, fontFamily: 'inter-medium' }}
                        onClick={() => {
                            setUnavailableCache(['flowPreset', flowPreset.id], flowPreset)
                            navigate(`/flow/new/${flowPreset.id}`)
                        }}
                    >
                        {__`use_flow`}
                        <RightOutlined style={{ fontSize: fontSizeSM }} />
                    </Button>
                </ButtonWrapper>
                
            </MainWrapper>
        </MainContainer>
    )
    
}
