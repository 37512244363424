import { ArrowsWrapper, DatePickerWrapper, MenuWrapper, NavigationContainer } from './availability-calendar-navigation.style'
import { Button, DatePicker, Typography, theme } from 'antd'
import { addDays, getDateRange, useTranslate } from 'utils'
import { LeftOutlined, RightOutlined } from '@ant-design/icons'
import { useConfig } from 'api'
import dayjs from 'dayjs'
import { WeekPickerPanelWrapper } from 'components/styled'
const { Text } = Typography

type Props = {
    startDate: Date
    numberOfDays: number
    onStartDateChange: (startDate: Date) => void
}

export const AvailabilityCalendarNavigation = ({ startDate, numberOfDays, onStartDateChange }: Props) => {

    //translate:
    const { __ } = useTranslate()

    //theme:
    const { token: { fontSizeSM, fontSizeLG } } = theme.useToken()
    
    //config:
    const { data: config, isLoading: isConfigLoading } = useConfig()
    
    //render:
    return (
        (isConfigLoading || !config) ? null :
        <NavigationContainer>

            {/* date */}
            <Text style={{ fontSize: fontSizeLG, fontFamily: 'inter-medium' }}>
                {getDateRange(startDate, numberOfDays, config, false)}
            </Text>

            <MenuWrapper>

                <DatePickerWrapper>

                    {/* date picker */}
                    <DatePicker
                        inputReadOnly
                        picker="week"
                        defaultValue={dayjs(startDate)}
                        value={dayjs(startDate)}
                        allowClear={false}
                        style={{ width: '100%' }}
                        panelRender={panelNode => <WeekPickerPanelWrapper>{panelNode}</WeekPickerPanelWrapper>}
                        onChange={val => onStartDateChange(dayjs(val).toDate())}
                    />

                    <ArrowsWrapper>

                        {/* left arrow */}
                        <Button
                            icon={<LeftOutlined style={{ fontSize: fontSizeSM }}/>}
                            title={__`previous_day`}
                            size={'middle'}
                            style={{ fontSize: fontSizeSM, backgroundColor: 'transparent' }}
                            onClick={() => onStartDateChange(addDays(startDate, -numberOfDays))}
                        />
                        
                        {/* right arrow */}
                        <Button
                            icon={<RightOutlined style={{ fontSize: fontSizeSM }}/>}
                            title={__`next_day`}
                            size={'middle'}
                            style={{ fontSize: fontSizeSM, backgroundColor: 'transparent' }}
                            onClick={() => onStartDateChange(addDays(startDate, +numberOfDays))}
                        />
                        
                    </ArrowsWrapper>

                </DatePickerWrapper>

            </MenuWrapper>

        </NavigationContainer>
    )
    
}
