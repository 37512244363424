import { theme } from 'antd'
import { useTranslate } from 'utils'
import { useNavigate } from 'react-router-dom'
import { MessagePageUI } from 'pages/message-page/message-page'
import { WarningOutlined, HomeOutlined } from '@ant-design/icons'
import { useConfig } from 'api'
import { LogoLoading, StaticPageError } from 'components/styled'

export const ErrorPage400 = () => (
    <ErrorPage
        errorKey='400_bad_request'
        descriptionKey='something_went_wrong_in_the_process'
    />
)

export const ErrorPage404 = () => (
    <ErrorPage
        errorKey='404_not_found'
        descriptionKey='this_resource_does_not_exist_please_try_another_way'
    />
)

export const ErrorPage500 = () => (
    <ErrorPage
        errorKey='500_something_went_wrong'
        descriptionKey='something_internally_went_wrong_please_try_again_later'
    />
)

type Props = {
    errorKey: string,
    descriptionKey: string
}

export const ErrorPage = ({ errorKey, descriptionKey }: Props) => {
    
    //hooks:
    const { __ } = useTranslate()
    const { token: { colorPrimary } } = theme.useToken()
    const { data: config, isLoading: isConfigLoading } = useConfig()
    const navigate = useNavigate()
    
    return isConfigLoading ? <LogoLoading/> : (!config) ? <StaticPageError/> :
        <MessagePageUI
            icon={<WarningOutlined/>}
            message={__(errorKey)}
            description={__(descriptionKey)}
            actionButton={{
                text: __`go_to_home`,
                icon: <HomeOutlined style={{ color: colorPrimary }}/>,
                onSelect: () => navigate('/')
            }}
        />
    
}
