import { Flow, FlowData, FlowPreset } from 'types'
import { useMutationHandler, usePaginationHandler, useQueryHandler, getShopId } from 'utils'

//useFlowPresets:
export const useFlowPresets = (search: string | null, limit: number) => usePaginationHandler<FlowPreset[]>({
    query: `
        query getFlowPresets (

            $searchTerm: String,
            $limit: Int!,
            $after: String,
            $before: String

        ) {
            getFlowPresets(
                
                searchTerm: $searchTerm,
                limit: $limit,
                after: $after,
                before: $before
                
            ) {

                data {

                    id
                    name
                    description
                    image
                    listed

                    data {

                        name
                        action
                        target
                        trigger

                        when {
                            amount
                            unit
                        }

                        template {
                            headers
                            body
                        }

                        status
                        
                    }
                }

                totalItems
                remainingItems
                nextCursor
                previousCursor
                
            }
        }
    `,
    variables: {
        searchTerm: search,
        limit,
        after: null,
        before: null
    },
    cacheKey: ['flowPresets'],
    limit,
    getResponse: json => json.data.getFlowPresets,
    onSuccess: response => response.data
})

//useFlowPreset:
export const useFlowPreset = (id: string) => useQueryHandler<FlowPreset>({
    query: `
        query getFlowPreset (
            $id: String!
        ) {
            getFlowPreset (
                id: $id
            ) {

                id
                name
                description
                image
                listed

                data {

                    name
                    action
                    target
                    trigger

                    when {
                        amount
                        unit
                    }

                    template {
                        headers
                        body
                    }

                    status

                }

            }
        }
    `,
    variables: {
        id
    },
    cacheKey: ['flowPreset', id],
    onSuccess: json => json.data.getFlowPreset
})

//useFlows:
export const useFlows = (search: string | null, limit: number) => {

    //shop id:
    const shopId = getShopId()

    //query handler:
    return usePaginationHandler<Flow[]>({
        query: `
            query getFlows (
                
                $shop: String!,
                $searchTerm: String,
                $limit: Int!,
                $after: String,
                $before: String

            ) {
                getFlows (

                    shop: $shop,
                    searchTerm: $searchTerm,
                    limit: $limit,
                    after: $after,
                    before: $before

                ) {
                    
                    data {
                
                        id
                        name
                        action
                        target
                        trigger

                        when {
                            amount
                            unit
                        }

                        template {
                            headers
                            body
                        }

                        status

                    }

                    totalItems
                    remainingItems
                    nextCursor
                    previousCursor
                    
                }
            }
        `,
        variables: {
            shop: shopId,
            searchTerm: search,
            limit,
            after: null,
            before: null
        },
        cacheKey: ['flows', shopId],
        limit,
        getResponse: json => json.data.getFlows,
        onSuccess: response => response.data
    })

}

//useFlowPreset:
export const useFlow = (id: string, onNotFound?: (json: any) => void) => {

    //shop id:
    const shopId = getShopId()
    
    //query handler:
    return useQueryHandler<Flow>({
        query: `
            query getFlow (

                $shop: String!,
                $id: String!

            ) {
                getFlow (

                    shop: $shop,
                    id: $id

                ) {

                    id
                    name
                    action
                    target
                    trigger

                    when {
                        amount
                        unit
                    }

                    template {
                        headers
                        body
                    }

                    status

                }
            }
        `,
        variables: {
            shop: shopId,
            id
        },
        cacheKey: ['flow', id, shopId],
        onSuccess: json => json.data.getFlow,
        onNotFound
    })

}

//create flow:
export const useCreateFlow = (flow: FlowData) => {

    //shop id:
    const shopId = getShopId()
    
    //query handler:
    return useMutationHandler<boolean>({
        query: `
            mutation createFlow (

                $shop: String!,
                $request: CreateFlowRequest!

            ){
                createFlow (

                    shop: $shop,
                    request: $request

                ) {
                    name
                }
            }
        `,
        variables: {
            shop: shopId,
            request: {
                name: flow.name,
                action: flow.action,
                target: flow.target,
                trigger: flow.trigger,
                when: flow.when,
                template: {
                    ...flow.template,
                    headers: typeof flow.template.headers === 'string' ? flow.template.headers : JSON.stringify(flow.template.headers),
                    body: typeof flow.template.body === 'string' ? flow.template.body : JSON.stringify(flow.template.body)
                },
                status: flow.status
            }
        },
        onSuccess: json => json.data.createFlow.name === flow.name
    })

}

//update flow:
export const useUpdateFlow = (flow: Flow) => {

    //shop id:
    const shopId = getShopId()
    
    //query handler:
    return useMutationHandler<boolean>({
        query: `
            mutation updateFlow (

                $shop: String!,
                $id: String!,
                $request: UpdateFlowGraphRequest!

            ){
                updateFlow (

                    shop: $shop,
                    id: $id,
                    request: $request

                ) {
                    name
                }
            }
        `,
        variables: {
            shop: shopId,
            id: flow.id,
            request: {
                name: flow.name,
                action: flow.action,
                target: flow.target,
                trigger: flow.trigger,
                when: flow.when,
                template: {
                    headers: typeof flow.template.headers === 'string' ? flow.template.headers : JSON.stringify(flow.template.headers),
                    body: typeof flow.template.body === 'string' ? flow.template.body : JSON.stringify(flow.template.body)
                },
                status: flow.status
            }
        },
        onSuccess: json => json.data.updateFlow.name === flow.name
    })

}

//remove flow:
export const useRemoveFlow = (id: string) => {

    //shop id:
    const shopId = getShopId()
    
    //query handler:
    return useMutationHandler<boolean>({
        query: `
            mutation deleteFlow (

                $shop: String!,
                $id: String!

            ) {
                deleteFlow(

                    shop: $shop,
                    id: $id

                ) {
                    message
                }
            }
        `,
        variables: {
            shop: shopId,
            id
        },
        onSuccess: json => json.data.deleteFlow.message === 'Done'
    })

}
