import { Typography, theme } from 'antd'
import { FormInput } from 'components/form-inputs'
import { VerticalContainer } from 'components/styled'
import { Field } from 'react-final-form'
import { NullableField, useTranslate, useWindowDimensions } from 'utils'
const { Title } = Typography

type Props = {
    isRegister: boolean
}

export const AppInfo = ({ isRegister }: Props) => {

    //hooks:
    const { __ } = useTranslate()
    const { token: { paddingXXS, marginSM, marginXXS } } = theme.useToken()
    const { SM } = useWindowDimensions()

    return (
        <VerticalContainer style={{ gap: marginSM }}>

            {/* Info */}
            <Title
                level={5}
                style={{
                    paddingTop: SM ? 0 : paddingXXS,
                    margin: `${marginXXS}px 0`
                }}
            >
                {__`info`}
            </Title>
            
            {/* name */}
            <Field
                name='name'
                title={__`name`}
                placeholder={__`my_app`}
                description={__`app_names_have_to_be_unique`}
                disabled={!isRegister}
                component={FormInput}
            />

            {/* title */}
            <NullableField
                name='title'
                title={__`title`}
                placeholder={__`my_app_the_best_app`}
                description={__`the_title_will_be_shown_to_the_others`}
                component={FormInput}
            />
            
            {/* description */}
            <NullableField
                name='description'
                title={__`description`}
                placeholder={__`my_apps_description`}
                description={__`describe_your_app_in_a_short_form`}
                component={FormInput}
            />

        </VerticalContainer>
    )

}
