import { ButtonsWrapper, Container, DropdownWrapper, LabelsWrapper, MainWrapper } from './service-item.style'
import { getDurationVariants } from '../service-duration/service-duration'
import { Button, Dropdown, MenuProps, theme } from 'antd'
import { MoreOutlined } from '@ant-design/icons'
import { Avatar } from 'components/avatar/avatar'
import { getShopId, setUnavailableCache, useTranslate, useWindowDimensions } from 'utils'
import { Label } from 'components/styled'
import { Service } from 'types'
import { useNavigate } from 'react-router-dom'

type Props = {
    service: Service
}

export const ServiceItem = ({ service }: Props) => {

    //translate:
    const { __ } = useTranslate()

    //theme:
    const { token: { paddingXXS, paddingXS, paddingSM, padding, borderRadius } } = theme.useToken()

    //window dimensions:
    const { SM } = useWindowDimensions()

    //navigation:
    const navigate = useNavigate()

    //items:
    const items: MenuProps['items'] = [
        { key: 'manage', label: __`manage`},
        { key: 'book'  , label: __`book`  }
    ]

    //on menu clicked:
    const onClick: MenuProps['onClick'] = ({ domEvent, key }) => {
        domEvent.stopPropagation()
        switch(key){
            case 'manage':
                onManageClick()
                break
            case 'book':
                onBookClick()
                break
        }
    }

    //onManageClick:
    const onManageClick = () => {
        setUnavailableCache(['service', service.id, getShopId()], service)
        navigate(`/service/${service.id}`)
    }

    //onBookClick:
    const onBookClick = () => navigate(`/service/book/${service.id}`)

    const hasMember = service.teamMembers.length > 0
    const hasDuration = getDurationVariants(service.variants).length > 0

    //render:
    return (
        <Button
            type='text'
            style={{
                width: '100%',
                height: 'auto',
                padding: SM ? 0 : `0 ${padding}px 0 ${paddingSM}px`,
                borderRadius: SM ? borderRadius : 0
            }}
            onClick={onManageClick}
        >

            <MainWrapper>

                {/* image and title */}
                <Avatar
                    name={service.title}
                    image={service.image.path}
                    style={{ paddingLeft: paddingXXS }}
                />

                <Container>
                    
                    {/* labels */}
                    {(!hasMember || !hasDuration) && (
                        <LabelsWrapper>

                            {!hasMember &&
                                <Label
                                    type='warning'
                                    text={__`no_team_members`}
                                />
                            }

                            {!hasDuration &&
                                <Label
                                    type='warning'
                                    text={__`no_durations`}
                                />
                            }

                        </LabelsWrapper>
                    )}
                    
                    {/* actions(button) */}
                    <ButtonsWrapper>
                        <Button
                            type="text"
                            style={{ paddingLeft: paddingXS, paddingRight: paddingXS}}
                            onClick={e => {
                                e.stopPropagation()
                                onManageClick()
                            }}
                        >
                            {__`manage`}
                        </Button>
                        <Button
                            type="text"
                            style={{ paddingLeft: paddingXS, paddingRight: paddingXS}}
                            disabled={!hasMember || !hasDuration}
                            onClick={e => {
                                e.stopPropagation()
                                onBookClick()
                            }}
                        >
                            {__`book`}
                        </Button>
                    </ButtonsWrapper>

                    {/* actions(dropdown) */}
                    <DropdownWrapper>
                        <Dropdown menu={{ items, onClick }} trigger={['click']}>
                            <Button
                                type={'text'}
                                shape={'circle'}
                                size={'large'}
                                icon={<MoreOutlined/>}
                                onClick={e => e.stopPropagation()}
                            />
                        </Dropdown>
                    </DropdownWrapper>
                    
                </Container>

            </MainWrapper>

        </Button>
    )
    
}
