import { theme } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
import { PropsWithChildren } from 'react'
import { HEXtoRGB, RGBtoRGBA } from 'utils'

type Props = {
    hasLoading?: boolean
    style?: object
}

export const WhiteShadowLoading = ({ hasLoading = true, style, children }: PropsWithChildren<Props>) => {
    
    //theme:
    const { token: { colorBgContainer, fontSizeXL } } = theme.useToken()
    const colorBgContainerInRGB = HEXtoRGB(colorBgContainer)

    //render:
    return (
        <div
            style={{
                ...style,
                width: '100%',
                height: '100%',
                backgroundColor: RGBtoRGBA(`rgb(${colorBgContainerInRGB?.red}, ${colorBgContainerInRGB?.green}, ${colorBgContainerInRGB?.blue})`, .6),
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                position: 'absolute',
                top: 0,
                left: 0
            }}
            onClick={e => e.stopPropagation()}
        >

            {hasLoading && <LoadingOutlined style={{ fontSize: fontSizeXL}}/>}

            {children}

        </div>
    )

}
