import { BOOKING_SLOTS_CACHE_STALE_TIME, CACHE_REFETCH_INTERVAL } from 'app.constants'
import { BookingSlot, FlexStartInterval } from 'types'
import { getIsoStringYearMonthDay, useMutationHandler, useQueryHandler, getShopId } from 'utils'

type BookingSlotsResponse = {
    date: string
    slots: BookingSlot[]
}[]

//slots:
export const useBookingSlots = (
    serviceId: string,
    variantId: string,
    quantity: number | null,
    flexStartInterval: FlexStartInterval | null | undefined,
    roundStartInterval: number,
    ids: string[][],
    start: Date,
    end: Date,
    timezone: string,
    enabled: boolean
) => {

    const shopId = getShopId()

    return useQueryHandler<BookingSlotsResponse>({
        query: `
            query getAvailableSlots (

                $shop: String!,
                $service: String!,
                $variantId: String!,
                $quantity: Float,
                $ids: [[String!]!]!,
                $start: String,
                $end: String,
                $timezone: String!

            ) {
                getAvailableSlots (

                    shop: $shop,
                    service: $service,
                    variantId: $variantId,
                    quantity: $quantity,
                    ids: $ids,
                    start: $start,
                    end: $end,
                    timezone: $timezone

                ) {

                    date
                    slots {

                        startTime
                        duration
                        status
                        remainingSlots

                    }

                }
            }
        `,
        variables: {
            shop: shopId,
            service: serviceId,
            variantId,
            quantity,
            ids,
            start: getIsoStringYearMonthDay(start),
            end: getIsoStringYearMonthDay(end),
            timezone
        },
        cacheKey: [
            'bookingSlots',
            serviceId,
            variantId,
            quantity,
            flexStartInterval,
            roundStartInterval,
            ids,
            start,
            end,
            timezone,
            shopId
        ],
        onSuccess: json => {

            // temporary solution until reading the unavailable slots from another query:
            const response: BookingSlotsResponse = []
            json.data.getAvailableSlots.map((day: any) => {

                // add just the available slots to the day:
                const slots: BookingSlot[] = []
                day.slots.map((slot: any) => {
                    if(slot.status === 'available'){
                        slots.push({
                            startDateTime: `${day.date} ${slot.startTime}`,
                            duration: slot.duration,
                            type: 'APPOINTMENT',
                            status: slot.status,
                            remainingSlots: slot.remainingSlots,
                            data: null
                        })
                    }
                })

                // add the day to the response:
                response.push({
                    date: day.date,
                    slots: slots
                })

            })

            return response

        },
        enabled,
        staleTime: BOOKING_SLOTS_CACHE_STALE_TIME,
        refetchInterval: CACHE_REFETCH_INTERVAL,
        keepPreviousData: true
    })

}

//create draft:
export const useCreateDraftOrder = (
    serviceId: string,
    variantId: string,
    quantity: number,
    teamMemberId: string,
    customerId: string,
    start: Date,
    time: string
) => {

    //shop id:
    const shopId = getShopId()

    //query handler:
    return useMutationHandler<string>({
        query: `
            mutation createDraftOrder (
                $request: GetBookingRequest!
            ) {
                createDraftOrder(
                    request: $request
                ) {
                    bookingId
                }
            }
        `,
        variables: {
            request: {
                shop: shopId,
                serviceId: serviceId,
                variantId,
                quantity,
                teamMemberId,
                customerId,
                date: getIsoStringYearMonthDay(start),
                time
            }
        },
        onSuccess: json => json.data.createDraftOrder.bookingId
    })

}

//complete order:
export const useCompleteDraftOrder = (bookingId: string) => {

    //shop id:
    const shopId = getShopId()
    
    //query handler:
    return useMutationHandler<boolean>({
        query: `
            mutation completeDraftOrder (

                $shop: String!,
                $draftOrderId: String!

            ) {
                completeDraftOrder (

                    shop: $shop,
                    draftOrderId: $draftOrderId

                ){
                    bookingId
                }
            }
        `,
        variables: {
            shop: shopId,
            draftOrderId: bookingId
        },
        onSuccess: json => json.data.completeDraftOrder.bookingId === bookingId
    })

}

//reschedule:
export const useRescheduleAppointment = (appointmentId: string, teamMemberId: string, dateTime: string) => {
    
    //query handler:
    return useMutationHandler<boolean>({
        query: `
            mutation rescheduleAppointment (

                $id: String!,
                $request: RescheduleAppointmentRequest!

            ) {
                rescheduleAppointment (

                    id: $id,
                    request: $request

                ){
                    message
                }
            }
        `,
        variables: {
            id: appointmentId,
            request: {
                teamMemberId,
                dateTime,
                content: null
            }
        },
        onSuccess: json => json.data.rescheduleAppointment.message === 'Done'
    })

}

