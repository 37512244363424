import { USER_LOGIN_STRATEGIES_STALE_TIME } from 'app.constants'
import { LoginStrategy } from 'types'
import { accessToken, env, refreshToken, requestFetchAPI, updateAccessToken, updateRefreshToken, useMutationHandler, useQueryHandler } from 'utils'

//useAvailableLoginStrategies:
export const useAvailableLoginStrategies = () => useQueryHandler<LoginStrategy[]>({
    query: `
        query getAvailableStrategies {
            getAvailableStrategies {
                strategies
            }
        }
    `,
    cacheKey: ['availableLoginStrategies'],
    onSuccess: json => json.data.getAvailableStrategies.strategies
})

//useUserLoginStrategies:
type LoginStrategiesResponse = null | {
    result: 'success'
    payload: { strategies: LoginStrategy[] }
} | {
    result: 'userNotActivated' | 'userNotFound'
    payload: null
}

export const useLoginStrategies = (email: string, enabled: boolean) => useQueryHandler<LoginStrategiesResponse>({
    query: `
        query getLoginStrategies (
            $request: EmailRequest!
        ) {
            getLoginStrategies (
                request: $request
            ) {
                strategies
            }
        }
    `,
    variables: {
        request: {
            email
        }
    },
    cacheKey: ['loginStrategies', email],
    staleTime: USER_LOGIN_STRATEGIES_STALE_TIME,
    enabled,
    onSuccess: json => { return { result: 'success', payload: { strategies: json.data.getLoginStrategies.strategies } }},
    onError: json => {
        switch(json.errors[0].extensions.errors[0].constraints[0].name){
            case 'active_user':
                return { result: 'userNotActivated', payload: null }
            case 'not_found':
                return { result: 'userNotFound', payload: null }
            default:
                return null
        }
    }
})

//useLoginByPassword:
type LoginByPasswordResponse = null | {
    result: 'success'
    payload: {
        refreshToken: string,
        accessToken: string
    }
} | {
    result: 'wrongPassword' | 'userNotActivated' | 'userNotFound'
    payload: null
} | {
    result: 'wrongStrategy'
    payload: { strategies: LoginStrategy[] }
}

export const useLoginByPassword = (email: string, password: string) => useMutationHandler<LoginByPasswordResponse>({
    query: `
        query loginByPassword (
            $request: LoginWithPasswordRequest!
        ) {
            loginByPassword (
                request: $request
            ) {
                refresh_token
                access_token
            }
        }
    `,
    variables: {
        request: {
            email,
            password
        }
    },
    onSuccess: json => ({
        result: 'success',
        payload: {
            refreshToken: json.data.loginByPassword.refresh_token,
            accessToken: json.data.loginByPassword.access_token
        }}),
    onError: json => {
        switch(json.errors[0].extensions.errors[0].constraints[0].name){
            case 'correct_password':
                return { result: 'wrongPassword', payload: null }
            case 'login_strategy':
                return { result: 'wrongStrategy', payload: { strategies: json.errors[0].payload } }
            case 'active_user':
                return { result: 'userNotActivated', payload: null }
            case 'not_found':
                return { result: 'userNotFound', payload: null }
            default:
                return null
        }
    }
})

//useSendMagicLink:
type SendMagicLinkResponse = null | {
    result: 'success'
    payload: null
} | {
    result: 'existingOTP' | 'wrongStrategy' | 'userNotActivated' | 'userNotFound'
    payload: null
}

export const useSendMagicLink = (email: string) => useMutationHandler<SendMagicLinkResponse>({
    query: `
        mutation sendMagicLink (
            $request: EmailRequest!
        ) {
            sendMagicLink (
                request: $request
            ){
                message
            }
        }
    `,
    variables: {
        request: {
            email
        }
    },
    onSuccess: json => {
        if(json.data.sendMagicLink.message === 'Done'){
            return { result: 'success', payload: null }
        }else{
            return null
        }
    },
    onError: json => {
        switch(json.errors[0].extensions.errors[0].constraints[0].name){
            case 'existing_otp':
                return { result: 'existingOTP', payload: null }
            case 'login_strategy':
                return { result: 'wrongStrategy', payload: null }
            case 'active_user':
                return { result: 'userNotActivated', payload: null }
            case 'not_found':
                return { result: 'userNotFound', payload: null }
            default:
                return null
        }
    }
})

//useLoginWithOTP:
type LoginWithOtpResponse = null | {
    result: 'success'
    payload: { 
        refreshToken: string,
        accessToken: string,
    }
} | {
    result: 'invalidOTP' | 'userNotActivated' | 'userNotFound'
    payload: null
}

export const useLoginWithOTP = (email: string, code: string) => useMutationHandler<LoginWithOtpResponse>({
    query: `
        query loginWithOTP (
            $request: EmailCodeRequest!
        ) {
            loginWithOTP (
                request: $request
            ) {
                refresh_token
                access_token
            }
        }
    `,
    variables: {
        request: {
            email,
            code
        }
    },
    onSuccess: json => ({
        result: 'success',
        payload: {
            refreshToken: json.data.loginWithOTP.refresh_token,
            accessToken: json.data.loginWithOTP.access_token
        }
    }),
    onError: json => {
        switch(json.errors[0].extensions.errors[0].constraints[0].name){
            case 'valid_otp':
                return { result: 'invalidOTP', payload: null }
            case 'active_user':
                return { result: 'userNotActivated', payload: null }
            case 'not_found':
                return { result: 'userNotFound', payload: null }
            default:
                return null
        }
    }
})

//useSendPasswordReset:
type SendPasswordResetResponse = null | {
    result: 'success'
    payload: null
} | {
    result: 'existingOTP' | 'wrongStrategy' | 'userNotActivated' | 'userNotFound'
    payload: null
}

export const useSendPasswordReset = (email: string) => useMutationHandler<SendPasswordResetResponse>({
    query: `
        mutation initForgotPassword (
            $request: EmailRequest!
        ) {
            initForgotPassword (
                request: $request
            ){
                message
            }
        }
    `,
    variables: {
        request: {
            email
        }
    },
    onSuccess: json => {
        if(json.data.initForgotPassword.message === 'Done'){
            return { result: 'success', payload: null }
        }else{
            return null
        }
    },
    onError: json => {
        switch(json.errors[0].extensions.errors[0].constraints[0].name){
            case 'existing_otp':
                return { result: 'existingOTP', payload: null }
            case 'login_strategy':
                return { result: 'wrongStrategy', payload: null }
            case 'active_user':
                return { result: 'userNotActivated', payload: null }
            case 'not_found':
                return { result: 'userNotFound', payload: null }
            default:
                return null
        }
    }
})

//useResetPassword:
type ResetPasswordResponse = null | {
    result: 'success'
    payload: null
} | {
    result: 'invalidOTP' | 'passwordsNotMatch' | 'userNotFound'
    payload: null
}

export const useResetPassword = (email: string, code: string, password: string) => useMutationHandler<ResetPasswordResponse>({
    query: `
        mutation resetPassword (
            $request: CompleteForgotPasswordRequest!
        ) {
            resetPassword (
                request: $request
            ) {
                message
            }
        }
    `,
    variables: {
        request: {
            email,
            code,
            password,
            rePassword: password
        }
    },
    onSuccess: json => {
        if(json.data.resetPassword.message === 'Done'){
            return { result: 'success', payload: null }
        }else{
            return null
        }
    },
    onError: json => {
        switch(json.errors[0].extensions.errors[0].constraints[0].name){
            case 'valid_otp':
                return { result: 'invalidOTP', payload: null }
            case 're_password':
                return { result: 'passwordsNotMatch', payload: null }
            case 'not_found':
                return { result: 'userNotFound', payload: null }
            default:
                return null
        }
    }
})

//requestLogout:
export const requestLogout = async () => requestFetchAPI(
    {
        'Content-Type': 'application/json',
        'authorization': `Bearer ${accessToken()}`
    },
    `
        mutation logout (
            $request: GetRefreshTokenRequest!
        ) {
            logout (
                request: $request
            ) {
                message
            }
        }
    `,
    {
        request: {
            refreshToken: refreshToken()
        }
    }
)

//refreshAccessToken:
export const refreshAccessToken = async (logout: () => void) => {
    if(refreshToken()){
        window.isAccessTokenRefreshing = true
        try{
            const response = await fetch(`${env().REST_API_BASE_URL}/auth/refresh-token`, {
                method: 'POST',
                body: JSON.stringify({ refreshToken: refreshToken() }),
                headers: {
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*'
                }
            })
            window.isAccessTokenRefreshing = false
            if(response.status === 201){
                const result = await response.json()
                updateRefreshToken(result.refresh_token)
                updateAccessToken(result.access_token)
                return true
            }else if(response.status === 401){
                logout()
                return false
            }else{
                return false
            }
        }catch(e){
            window.isAccessTokenRefreshing = false
            return false
        }
    }else{
        logout()
        return false
    }
}
