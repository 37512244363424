import { Typography, theme } from 'antd'
import { CheckOutlined, CopyOutlined } from '@ant-design/icons'
import { isNil, useTouchDetector, useTranslate } from 'utils'
import { ReactNode, useState } from 'react'
import { HorizontalContainer, VerticalContainer } from 'components/styled'
const  { Text, Paragraph } = Typography

type Props = {
    name?: string
    value: string | ReactNode
    unit?: string
    copyable?: boolean
}

export const Info = ({ name, value, unit, copyable = true }: Props) => {

    //translate:
    const { __ } = useTranslate()

    //touchscreen
    const { isTouchscreen } = useTouchDetector()

    //theme:
    const { token: { fontSizeSM, colorTextTertiary, colorInfoActive, colorTextDescription, paddingXXS } } = theme.useToken()

    //state:
    const [ isHovered, setIsHovered ] = useState(false)
    
    return (
        <VerticalContainer style={{ gap: 0 }}>
            
            {/* name */}
            {name &&
                <Paragraph style={{
                    margin: 0,
                    fontSize: fontSizeSM,
                    color: colorTextDescription,

                }}>
                    {name}
                </Paragraph>
            }

            {/* value and unit */}
            {typeof value === 'object' ?
                <HorizontalContainer style={{ justifyContent: 'flex-start' }}>
                    {value}
                    {/* unit */}
                    {!isNil(unit) &&
                        <Text
                            style={{
                                color: colorTextDescription,
                                whiteSpace: 'nowrap'
                            }}
                        >
                            &nbsp;{unit}
                        </Text>
                    }
                </HorizontalContainer>

                :

                <Paragraph
                    style={{
                        width: 'fit-content',
                        margin: 0,
                        display: 'flex',
                        alignItems: 'center'
                    }}
                    copyable={copyable && {
                        text: value?.toString(),
                        icon: [
                            <CopyOutlined  key={1} style={{ opacity: isHovered || isTouchscreen ? 1 : 0, padding: paddingXXS, color: colorTextTertiary }}/>,
                            <CheckOutlined key={2} style={{ opacity: isHovered || isTouchscreen ? 1 : 0, padding: paddingXXS, color: colorInfoActive   }}/>
                        ],
                        tooltips: [__`copy`, __`copied`]
                    }}
                    onMouseEnter={() => setIsHovered(true)}
                    onMouseLeave={() => setIsHovered(false)}
                >
                    {value}

                    {/* unit */}
                    {!isNil(unit) &&
                        <Text
                            style={{
                                color: colorTextDescription,
                                whiteSpace: 'nowrap'
                            }}
                        >
                            &nbsp;{unit}
                        </Text>
                    }

                </Paragraph>
            }
            
        </VerticalContainer>
    )

}
