import styled from 'styled-components'
import styledContainerQuery from 'styled-container-query'
import { BOOKING_SCALE_WRAPPER_Z_INDEX } from 'z-indexes'

export const LeftColumn = styled.div`
    flex: 3;
    min-width: 450px;
    max-width: 650px;
    display: flex;
    flex-direction: column;
    gap: ${props => props.theme.marginLG + 'px'};
`

export const RightColumn = styled.div`
    flex: 4;
    display: flex;
    flex-direction: column;
    gap: ${props => props.theme.margin + 'px'};
`

export const ColumnContainer = styledContainerQuery.div`
    flex: 1;
    display: flex;
    gap: ${(props: any) => `${props.theme.marginLG}px`};
    &:container(max-width: 992px){ // MD
        flex-direction: column;
        ${LeftColumn}{
            min-width: unset;
            max-width: unset;
        }
    }
`

export const CalendarWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${props => `${props.theme.marginXXS}px`};
`

type ScaleWrapperProps = {
    bottom: number
}

export const ScaleWrapper = styled.div<ScaleWrapperProps>`
    width: 100%;
    background-color: ${props => props.theme.colorBgContainer};
    position: sticky;
    right: ${props => `${props.theme.padding}px`};
    bottom: ${props => `${props.bottom}px`};
    z-index: ${BOOKING_SCALE_WRAPPER_Z_INDEX};
`
