import styled from 'styled-components'
import { BOOKING_CALENDAR_TIMELINE_COLUMN_Z_INDEX } from 'z-indexes'

export const MainWrapper = styled.div`
    width: 100%;
    display: flex;
`

export const ContentColumn = styled.div`
    flex: 1;
    position: relative;
`

export const TimeBoxColumn = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    & > div:last-child{
        border-bottom: none
    }
`

export const SlotsColumn = styled.div`
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    overflow: hidden;
`

export const TimelineColumn = styled.div`
    width: ${props => `${props.theme.marginXXS*12}px`};
    background-color: ${props => props.theme.colorBgContainer};
    border-right: solid 1px ${props => props.theme.colorBorderSecondary};
    display: flex;
    flex-direction: column;
    position: sticky;
    left: 0;
    z-index: ${BOOKING_CALENDAR_TIMELINE_COLUMN_Z_INDEX};
    & > div:nth-last-child(1){ // hide the last hour badge
        & > div{
            & > div:last-child{
                &::after{
                    display: none
                }
            }
        }
    }
`

type TimeBoxProps = {
    scale: number
}

export const TimeBox = styled.div<TimeBoxProps>`
    width: 100%;
    height: ${props => `${props.scale*60}px`}; //60 minutes
    border-bottom: solid 1px ${props => props.theme.colorBorderSecondary};
    border-right: solid 1px ${props => props.theme.colorBorderSecondary};
    display: flex;
    flex-direction: column;
    justify-content: center;
    ::after{
        content: "";
        width: 100%;
        height: 1px;
        background-color: ${props => props.scale >= 3 ? props.theme.colorBorderSecondary : 'transparent'}
    }
`
