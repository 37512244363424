import { useConfig } from './config.api'
import { Feature, Settings } from 'types'
import { isNil, useMutationHandler, useQueryHandler, getShopId } from 'utils'

//useSettings:
export const useSettings = () => {

    const { data: config } = useConfig()
    const shopId = getShopId()
    
    return useQueryHandler<Settings>({
        query: `
            query getConfig (
                $id: String,
                $experienceSelector: Boolean!
            ) {
                getConfig (
                    id: $id
                ) {

                    settings {

                        status
                        
                        timezone
                        hideTimezoneFromStorefront
                        emailCalendarEventToMerchant
                        emailCalendarEventToCustomer
                        
                        calendarExperience @include(if: $experienceSelector)
                        locale
                        timeFormat
                        storefrontDateFormat
                        reservationPeriod
                        instantBooking {
                            requiredFields
                        }
                        removeBranding

                    }

                }
            }
        `,
        variables: {
            id: shopId,
            experienceSelector: config ? config.features.includes(Feature.EXPERIENCE_SELECTOR) : false
        },
        cacheKey: ['settings', shopId],
        onSuccess: json => json.data.getConfig.settings
    })
}

//useUpdateSettings:
export const useUpdateSettings = (shopId: string, settings: Settings) => useMutationHandler({
    query: `
        mutation updateShop (

            $id: String!,
            $request: UpdateShopGraphRequest!

        ) {
            updateShop(

                id: $id,
                request: $request

            ){
                settings {
                    status
                }
            }
        }
    `,
    variables: {
        id: shopId,
        request: {
            settings
        }
    },
    onSuccess: json => !isNil(json.data.updateShop.settings)
})
