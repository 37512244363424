import styled from 'styled-components'

type MainWrapperProp = {
    backgroundColor: string
}

export const MainWrapper = styled.div<MainWrapperProp>`
    width: 100%;
    padding: ${props => `${props.theme.padding}px`};
    background-color: ${props => `${props.backgroundColor}`};
    border-radius: ${props => `${props.theme.borderRadius}px`};
    display: flex;
    flex-direction: column;
`

export const ButtonsWrapper = styled.div`
    width: 100%;
    text-align: end;
`

type LoadingContainer = {
    isDanger: boolean
}

export const LoadingContainer = styled.div<LoadingContainer>`
    width: 100%;
    height: 100%;
    background-color: ${props => props.isDanger ? props.theme.colorError : props.theme.colorPrimary};
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
`
