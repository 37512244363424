import styled from 'styled-components'

type Props = {
    lineClamp?: number
}

export const LineClampText = styled.span<Props>`
    max-width: 100%;
    max-height: ${props => `${props.theme.lineHeight*50}px`}; //fallback
    color: ${props => props.theme.colorText};
    text-align: center;
    white-space: break-spaces;
    line-clamp: ${props => props.lineClamp ?? 2};
    -webkit-line-clamp: ${props => props.lineClamp ?? 2};
    box-orient: vertical;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    text-overflow: ellipsis;
    overflow: hidden;
`
