import { Button, Typography, theme } from 'antd'
import { InfoCircleOutlined, LoadingOutlined, RightOutlined } from '@ant-design/icons'
import { TranslateFunction, isNil, useTranslate } from 'utils'
import { HorizontalContainer, VerticalContainer } from 'components/styled'
import { Field, Form } from 'react-final-form'
import { setIn } from 'final-form'
import { object, string } from 'yup'
import { FormInput } from 'components/form-inputs'
import { useEffect, useState } from 'react'
import { LoginStrategy } from 'types'
import { useLoginStrategies } from 'api'
const  { Text, Paragraph } = Typography

const LoginSendEmailSchema = (__: TranslateFunction) => object({
    email: string().required(__`enter_a_valid_email`).email(__`enter_a_valid_email`)
})

type Props = {
    onSend: (email: string, strategies: LoginStrategy[]) => void
}

export const LoginSendEmail = ({ onSend }: Props) => {

    //translate:
    const { __ } = useTranslate()

    //theme:
    const { token: {
        fontSize,
        fontSizeSM,
        colorTextSecondary,
        marginXS,
        colorBgContainer,
        colorErrorBorder,
        borderRadius,
        paddingXS,
        paddingSM
    }} = theme.useToken()

    //state:
    const [ initEmail, setInitEmail ] = useState('')
    const [ errorMessage, setErrorMessage ] = useState(null as string | null)

    //login strategies:
    const { data, isLoading } = useLoginStrategies(initEmail, initEmail !== '')

    //onValidate:
    const onValidate = (schema: any) => async (values: { email: string }) => {
        if(typeof schema === 'function')
            schema = schema()
        try{
            await schema.validate(values, { abortEarly: false })
        }catch(err: any){
            const errors = err.inner.reduce((formError: object, innerError: any) => {
                return setIn(formError, innerError.path, innerError.message)
            }, {})
            return errors
        }
    }

    //handle response:
    useEffect(() => {
        if(data){
            switch(data.result){
                case 'success':
                    setErrorMessage(null)
                    onSend(initEmail, data.payload.strategies)
                    break
                case 'userNotActivated':
                    setErrorMessage(`your_account_has_not_been_activated_yet_you_can_activate_your_account_by_opening_the_activation_link_that_sent_to_your_email_before`)
                    break
                case 'userNotFound':
                    setErrorMessage(`we_cant_seem_to_find_the_right_email_for_you_are_you_sure_you_have_an_account`)
                    break
            }
        }
    }, [data, isLoading])
    
    //render:
    return (
        <Form
            initialValues={{ email: initEmail }}
            validate={onValidate(LoginSendEmailSchema(__))}
            onSubmit={(values: { email: string }) =>
                LoginSendEmailSchema(__).validate(values).then(
                    data => setInitEmail(data.email)
                )
            }
            render={({ handleSubmit }) => (

                <form onSubmit={handleSubmit} noValidate >

                    <VerticalContainer style={{ gap: marginXS }}>
                
                        {/* email */}
                        <Field
                            name='email'
                            type='email'
                            placeholder={__`enter_your_email`}
                            
                            component={FormInput}
                            size={'large'}
                            style={{ fontSize }}
                        />

                        {/* error message */}
                        {!isNil(errorMessage) &&
                            <HorizontalContainer
                                style={{
                                    padding: paddingSM,
                                    backgroundColor: colorErrorBorder,
                                    borderRadius: borderRadius,
                                    alignItems: 'flex-start',
                                    gap: paddingXS
                                }}
                            >
                                <InfoCircleOutlined style={{ color: colorTextSecondary, marginTop: 1 }}/>
                                <Paragraph style={{ margin: 0, fontSize: fontSizeSM }}>
                                    {__(errorMessage)}
                                </Paragraph>
                            </HorizontalContainer>
                        }

                        {/* button */}
                        <Button
                            type='primary'
                            size='large'
                            style={{ fontSize, color: colorBgContainer }}
                            onClick={() => {!(isLoading && initEmail !== '') && handleSubmit()}}
                        >

                            <HorizontalContainer style={{ justifyContent: 'space-between' }}>

                                <RightOutlined style={{ visibility: 'hidden' }}/>

                                <Text style={{ color: colorBgContainer, fontFamily: 'inter-medium' }}>{__`continue`}</Text>

                                {(isLoading && initEmail !== '') ? <LoadingOutlined/> : <RightOutlined/>}

                            </HorizontalContainer>

                        </Button>

                    </VerticalContainer>

                    <input type='submit' hidden/>

                </form>
                
            )}
        />
    )

}
