import { useRef, useState } from 'react'
import { Button, Input, InputRef, Typography, theme } from 'antd'
import { DeleteFilled, LoadingOutlined } from '@ant-design/icons'
import { FormSection } from 'components/form-section/form-section'
import { isNil, useTranslate } from 'utils'
import { VerticalContainer } from 'components/styled'
const  { Paragraph } = Typography

type Props = {
    title: string
    message: string
    description?: string
    isLoading?: boolean
    isDisabled?: boolean
    onRemove: () => void
}

export const FormRemove = ({ title, message, description, isLoading = false, isDisabled = false, onRemove }: Props) => {

    //translate:
    const { __ } = useTranslate()

    //theme:
    const { token: { marginXXS, marginSM, fontSize } } = theme.useToken()

    //ref:
    const confirmInput = useRef<InputRef>(null)

    //state:
    const [ isConfirmationVisible, setIsConfirmationVisible ] = useState(false)
    const [ confirmationValue    , setConfirmationValue     ] = useState('')

    //handleClick:
    const handleDelete = () => {
        if(!isLoading){
            if(isConfirmationVisible){
                if(confirmationValue.toLowerCase() === __`remove`.toLowerCase()){
                    onRemove()
                }else{
                    confirmInput.current?.focus()
                }
            }else{
                setIsConfirmationVisible(true)
                setTimeout(() => confirmInput.current?.focus(), 300)
            }
        }
    }
    
    //render:
    return (
        <FormSection title={title}>

            <VerticalContainer style={{ gap: marginSM }}>

                {/* description */}
                <VerticalContainer>
                    <Paragraph style={{ margin: 0 }}>{message}</Paragraph>
                    {!isNil(description) && <Paragraph style={{ margin: 0 }} type='secondary'>{description}</Paragraph>}
                </VerticalContainer>

                {/* confirmation input */}
                {isConfirmationVisible &&
                    <VerticalContainer style={{ marginBottom: marginXXS }}>

                        <Paragraph style={{ margin: 0 }}>{__`please_type_remove_to_confirm`}</Paragraph>
                        
                        <Input
                            status={'error'}
                            placeholder={__`remove`}
                            defaultValue={confirmationValue}
                            onChange={e => setConfirmationValue(e.target.value)}
                            onKeyDown={e => e.code === 'Enter' && handleDelete()}
                            ref={confirmInput}
                        />

                    </VerticalContainer>
                }

                {/* remove button */}
                <Button
                    type="primary"
                    danger
                    icon={isLoading ? <LoadingOutlined/> : <DeleteFilled/>}
                    size={'large'}
                    style={{ width: 'fit-content', fontSize, fontFamily: 'inter-medium' }}
                    disabled={isDisabled}
                    onClick={handleDelete}
                >
                    {__`remove`}
                </Button>

            </VerticalContainer>

        </FormSection>
    )

}
