import { Button, Typography, message, theme } from 'antd'
import { InfoCircleOutlined, LoadingOutlined, RightOutlined } from '@ant-design/icons'
import { TranslateFunction, isNil, useTranslate } from 'utils'
import { HorizontalContainer, VerticalContainer } from 'components/styled'
import { Field, Form } from 'react-final-form'
import { setIn } from 'final-form'
import { object, string } from 'yup'
import { FormPassword } from 'components/form-inputs'
import { useLoginByPassword } from 'api'
import { useEffect, useState } from 'react'
import { PASSWORD_MAX_LENGTH } from 'app.constants'
const  { Text, Paragraph } = Typography

const LoginPasswordFormSchema = (__: TranslateFunction) => object({
    password: string().required(__`enter_new_password`)
})

type Props = {
    email: string
    onLogin: (accessToken: string, refreshToken: string) => void
    onForgotSelect: () => void
    onBackToLogin: () => void
}

export const LoginPassword = ({ email, onLogin, onForgotSelect, onBackToLogin }: Props) => {

    //translate:
    const { __ } = useTranslate()

    //theme:
    const { token: {
        marginXS,
        paddingSM,
        paddingXS,
        fontSize,
        fontSizeSM,
        colorTextSecondary,
        colorBgContainer,
        colorErrorBorder,
        borderRadius
    }} = theme.useToken()

    //state:
    const [ password, setPassword ] = useState('')
    const [ errorMessage, setErrorMessage ] = useState(null as string | null)

    //onValidate:
    const onValidate = (schema: any) => async (values: { password: string }) => {
        if(typeof schema === 'function')
            schema = schema()
        try{
            await schema.validate(values, { abortEarly: false })
        }catch(err: any){
            const errors = err.inner.reduce((formError: object, innerError: any) => {
                return setIn(formError, innerError.path, innerError.message)
            }, {})
            return errors
        }
    }

    //mutation:
    const { mutate, isLoading, data } = useLoginByPassword(email, password)

    //sent request:
    useEffect(() => { password !== '' && mutate() }, [password])

    //handle response:
    useEffect(() => {
        if(data){
            switch(data.result){
                case 'success':
                    setErrorMessage(null)
                    onLogin(data.payload.refreshToken, data.payload.accessToken)
                    break
                case 'wrongPassword':
                    setErrorMessage(`your_can_disconnect_sesami_from_team_members_calendar_by_selecting_revoke_button`)
                    break
                case 'userNotActivated':
                    setErrorMessage(`your_account_has_not_been_activated_yet_you_can_activate_your_account_by_opening_the_activation_link_that_sent_to_your_email_before`)
                    break
                case 'wrongStrategy':
                case 'userNotFound':
                    message.error(__`oops_something_went_wrong`)
                    onBackToLogin()
                    break
            }
        }
    }, [data])
    
    //render:
    return (
        <Form
            initialValues={{ password: null }}
            validate={onValidate(LoginPasswordFormSchema(__))}
            onSubmit={(values: { password: string }) =>
                LoginPasswordFormSchema(__).validate(values).then(
                    data => setPassword(data.password)
                )
            }
            render={({ handleSubmit }) => (

                <form onSubmit={handleSubmit}>
                
                    <VerticalContainer style={{ gap: marginXS }}>
                
                        {/* password */}
                        <Field
                            name="password"
                            placeholder={__`enter_your_password`}
                            component={FormPassword}
                            size={'large'}
                            style={{ fontSize }}
                            maxLength={PASSWORD_MAX_LENGTH}
                        />

                        {/* error message */}
                        {!isNil(errorMessage) &&
                            <HorizontalContainer
                                style={{
                                    padding: paddingSM,
                                    backgroundColor: colorErrorBorder,
                                    borderRadius: borderRadius,
                                    alignItems: 'flex-start',
                                    gap: paddingXS
                                }}
                            >
                                <InfoCircleOutlined style={{ color: colorTextSecondary, marginTop: 1 }}/>
                                <Paragraph style={{ margin: 0, fontSize: fontSizeSM }}>
                                    {__(errorMessage)}
                                </Paragraph>
                            </HorizontalContainer>
                        }

                        {/* login button */}
                        <Button
                            type='primary'
                            size='large'
                            style={{ fontSize, color: colorBgContainer }}
                            onClick={() => {!isLoading && handleSubmit()}}
                        >

                            <HorizontalContainer style={{ justifyContent: 'space-between' }}>

                                <RightOutlined style={{ visibility: 'hidden' }}/>
                                <Text style={{ color: colorBgContainer, fontFamily: 'inter-medium' }}>{__`login_to_sesami`}</Text>
                                {isLoading ? <LoadingOutlined/> : <RightOutlined/>}

                            </HorizontalContainer>

                        </Button>

                        {/* forgot button */}
                        <Button
                            type='link'
                            size='small'
                            style={{
                                width: 'fit-content',
                                paddingLeft: 0,
                                paddingRight: 0,
                                alignSelf: 'flex-end'
                            }}
                            onClick={onForgotSelect}
                        >
                            {__`forgot_your_password`}
                        </Button>

                    </VerticalContainer>

                    <input type='submit' hidden/>

                </form>
                
            )}
        />
    )

}
