import { ReactNode, useEffect, useState } from 'react'
import {
    CalendarOutlined,
    CustomerServiceOutlined,
    MenuOutlined,
    TeamOutlined
} from '@ant-design/icons'
import { Button, Drawer, theme } from 'antd'
import { useLocation, useNavigate } from 'react-router-dom'
import { HorizontalContainer, LogoContainer } from 'components/styled'
import { useWindowDimensions } from 'utils'
import { DrawerMenu } from 'components/layout/drawer-menu/drawer-menu'
import { BOTTOM_MENU_Z_INDEX } from 'z-indexes'

//menuItems:
const menuItems = [
    { title: 'dashboard', navTo: '/'        , getIcon: (fontSize: number, color: string) => <LogoContainer size={17} style={{ fontSize, color }} />},
    { title: 'calendar' , navTo: '/calendar', getIcon: (fontSize: number, color: string) => <CalendarOutlined        style={{ fontSize, color }} />},
    { title: 'service'  , navTo: '/service' , getIcon: (fontSize: number, color: string) => <CustomerServiceOutlined style={{ fontSize, color }} />},
    { title: 'team'     , navTo: '/team'    , getIcon: (fontSize: number, color: string) => <TeamOutlined            style={{ fontSize, color }} />}
]

export const BottomMenu = () => {

    //theme:
    const { token: {
        colorBorder,
        colorBgContainer,
        paddingXS,
        padding
    }} = theme.useToken()

    //window dimensions:
    const { width, SM, LG } = useWindowDimensions()

    //navigation:
    const navigate = useNavigate()
    const location = useLocation()

    //state:
    const [ isDrawerMenuOpen, setIsDrawerMenuOpen ] = useState(false)

    //close drawer on size change:
    useEffect(() => {LG && setIsDrawerMenuOpen(false)}, [LG])

    //render:
    return (
        <HorizontalContainer style={{
            width: '100%',
            paddingBottom: paddingXS,
            borderTop: `solid 1px ${colorBorder}`,
            backgroundColor: colorBgContainer,
            justifyContent: 'center',
            zIndex: BOTTOM_MENU_Z_INDEX
        }}>

            <HorizontalContainer style={{
                width: '100%',
                maxWidth: SM ? 370 : '100%',
                padding: `${paddingXS}px ${padding}px`,
                justifyContent: 'space-between'
            }}>

                {/* menu */}
                {menuItems.map(item => {
                    const isSelected = item.navTo === location.pathname
                    return (
                        <MenuItem
                            key={item.title}
                            getIcon={item.getIcon}
                            isSelected={isSelected}
                            onclick={() => {
                                if(item.navTo){
                                    navigate(item.navTo)
                                }
                            }}
                        />
                    )
                })}

                <MenuItem
                    getIcon={
                        (fontSize: number, color: string) =>
                            <MenuOutlined style={{ fontSize, color }}/>
                    }
                    isSelected={false}
                    onclick={() => setIsDrawerMenuOpen(true)}
                />

            </HorizontalContainer>

            {/* drawer */}
            <Drawer
                closable={false}
                placement={SM ? 'right' : 'bottom'}
                width={SM ? 440 : width } // full width in mobile
                height={'85%'}
                styles={{ body: { padding: 0 }}}
                open={isDrawerMenuOpen}
                onClose={() => setIsDrawerMenuOpen(false)}
            >
                <DrawerMenu onClose={() => setIsDrawerMenuOpen(false)}/>
            </Drawer>

        </HorizontalContainer>
    )

}

type MenuItemProps = {
    isSelected: boolean
    getIcon: (fontSize: number, color: string) => ReactNode
    onclick: () => void
}

const MenuItem = ({ isSelected, getIcon, onclick }: MenuItemProps) => {

    //theme:
    const { token: { fontSizeLG, colorText, colorBgContainer }} = theme.useToken()
    
    //render:
    return (
        <Button
            type='text'
            shape='circle'
            size='large'
            style={{
                backgroundColor: isSelected ? colorText : undefined,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
            }}
            onClick={onclick}
        >
            {getIcon(fontSizeLG, isSelected ? colorBgContainer : colorText)}
        </Button>
    )
}
