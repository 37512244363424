import { theme } from 'antd'
import { FormInput } from 'components/form-inputs'
import { FormSection } from 'components/form-section/form-section'
import { VerticalContainer } from 'components/styled'
import { NullableField, useTranslate } from 'utils'

export const AppNetwork = () => {

    //hooks:
    const { __ } = useTranslate()
    const { token: { marginSM } } = theme.useToken()

    return (
        <FormSection title={__`network`}>

            <VerticalContainer style={{ gap: marginSM }}>

                {/* domain */}
                <NullableField
                    name='domain'
                    title={__`domain`}
                    placeholder={__`app_domain_sample`}
                    description={__`the_main_domain_of_the_app`}
                    component={FormInput}
                />

                {/* redirectURIs */}
                <NullableField
                    name='redirectURIs'
                    title={__`redirect_uri`}
                    placeholder={__`app_redirect_uri_sample`}
                    description={__`you_can_add_multiple_uris_separated_with_comma`}
                    component={FormInput}
                    parse={(value: string) => {
                        const spaceRemoved = value.replaceAll(' ', '')
                        const splitted = spaceRemoved.split(',')
                        const redirectURIs: string[] = []
                        splitted.map(uri => uri && redirectURIs.push(uri))
                        return redirectURIs
                    }}
                />

            </VerticalContainer>

        </FormSection>
    )

}
