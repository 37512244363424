import { Availability } from './availability.type'
import { object, string } from 'yup'
import { TranslateFunction } from 'utils'
import { CalendarSlot, Service } from 'types'

export type TeamMember = {
    
    id: string
    name: string
    email: string | null
    mobile: string | null
    description: string | null
    image: {
        id: string | null
        path: string | null
    }
    status: boolean
    notificationEmailStatus: boolean
    eventDescription: string | null
    availabilities: Availability[]
    services: Pick<Service, 'id'>[]

    calendarSync?: CalendarSync

}

export type CalendarSync = {
    canUseCalendarSync: boolean
    calendarSyncStatus: CalendarSyncStatus
}

export type CalendarSyncStatus = 'Active' | 'Pending' | null

export type AppointmentTeamMember = Pick<TeamMember, 'id' | 'name' | 'image' | 'description'>

export type SimpleTeamMember = Pick<TeamMember, 'id' | 'name' | 'image' | 'availabilities'>

export type CalendarSlotTeamMember = Pick<TeamMember, 'id' | 'name' | 'image' | 'description'> & {
    slots: CalendarSlot[]
}

export const TeamMemberFormSchema = (__: TranslateFunction) => object({
    name:               string().required(__`this_field_is_required`),
    email:              string().email(__`must_be_a_valid_email`).nullable(),
    mobile:             string().nullable(),
    description:        string().nullable(),
    eventDescription:   string().nullable()
})
