import { UserPermissionHeader } from 'components/user/user-permission-header/user-permission-header'
import { useState } from 'react'
import { theme } from 'antd'
import { Avatar } from 'components/avatar/avatar'
import { useTranslate } from 'utils'
import { RightOutlined } from '@ant-design/icons'
import { useServices } from 'api'
import { VerticalContainer } from 'components/styled'
import { Search } from 'components/search/search'
import { VerticalPaginatedList } from 'components/vertical-paginated-list/vertical-paginated-list'

type Props = {
    onSelect: (id: string) => void
    onBack: () => void
}

export const UserPermissionServiceSelect = ({ onSelect, onBack }: Props) => {

    //translate:
    const { __ } = useTranslate()

    //theme:
    const { token: { fontSizeSM, margin, paddingXS, paddingLG } } = theme.useToken()

    //state:
    const [ search, setSearch ] = useState(null as string | null)
    
    //services:
    const {
        data: services,
        pageNum,
        totalPage,
        isLoading,
        isPreviousData,
        isNextDisabled,
        isPreviousDisabled,
        fetchNext,
        fetchPrevious
    } = useServices(search, 15)

    //render:
    return (
        <VerticalContainer style={{ height: '100%', paddingBottom: paddingXS, gap: margin }}>

            <VerticalContainer>

                {/* header */}
                <UserPermissionHeader
                    title={__`service`}
                    onBack={onBack}
                >

                    {/* search */}
                    <Search
                        placeholder={__`search_services`}
                        onSearch={setSearch}
                    />

                </UserPermissionHeader>

            </VerticalContainer>

            <VerticalPaginatedList
                isFirstLoading={isLoading}
                isForbidden={services === null}
                isError={services === false}
                notFound={(services && services.length === 0) ?? false}
                pageNum={pageNum}
                totalPage={totalPage}
                isPreviousData={isPreviousData}
                isNextDisabled={isNextDisabled}
                isPreviousDisabled={isPreviousDisabled}
                scrollable={true}
                fetchNext={fetchNext}
                fetchPrevious={fetchPrevious}
            >

                {services && services.map(service =>
                    <Avatar
                        key={service.id}
                        name={service.title}
                        image={service.image.path}
                        description={service.eventDescription}
                        extraIcon={<RightOutlined style={{ fontSize: fontSizeSM }}/>}
                        style={{ width: '100%', paddingLeft: paddingLG, paddingRight: paddingLG, borderRadius: 0 }}
                        onClick={() => onSelect(service.id)}
                    />
                )}

            </VerticalPaginatedList>

        </VerticalContainer>
    )

}
