export const adminLanguageOptions: { label: string, value: string }[] = [
    // { value: 'zh-CN', label: 'Chinese(Simplified, China)' },
    // { value: 'zh-HK', label: 'Chinese(Traditional, Hong Kong)' },
    // { value: 'zh-TW', label: 'Chinese(Traditional, Taiwan)' },
    // { value: 'da-DK', label: 'Danish(Denmark)' },
    // { value: 'de-AT', label: 'Deutsch(Austria)' },
    // { value: 'de-DE', label: 'Deutsch(Germany)' },
    // { value: 'de-CH', label: 'Deutsch(Switzerland)' },
    // { value: 'nl-NL', label: 'Dutch(Netherlands)' },
    // { value: 'en-AU', label: 'English(Australia)' },
    // { value: 'en-CA', label: 'English(Canada)' },
    // { value: 'en-GB', label: 'English(United Kingdom)' },
    // { value: 'en-IE', label: 'English(Ireland)' },
    // { value: 'en-IN', label: 'English(India)' },
    // { value: 'en-NZ', label: 'English(New Zealand)' },
    { value: 'en-US', label: 'English(United States)' },
    // { value: 'en-ZA', label: 'English(South Africa)' },
    // { value: 'fi-FI', label: 'Finnish(Finland)' },
    // { value: 'fr-BE', label: 'French(Belgium)' },
    // { value: 'fr-CA', label: 'French(Canada)' },
    // { value: 'fr-CH', label: 'French(Switzerland)' },
    // { value: 'fr-FR', label: 'French(France)' },
    // { value: 'it-CH', label: 'Italian(Switzerland)' },
    // { value: 'it-IT', label: 'Italian(Italy)' },
    // { value: 'ja-JP', label: 'Japanese(Japan)' },
    // { value: 'no-NO', label: 'Norwegian(Norway)' },
    // { value: 'pl-PL', label: 'Polish(Poland)' },
    // { value: 'pt-BR', label: 'Portuguese(Brazil)' },
    // { value: 'pt-PT', label: 'Portuguese(Portugal)' },
    // { value: 'es-AR', label: 'Spanish(Argentina)' },
    // { value: 'es-CL', label: 'Spanish(Chile)' },
    // { value: 'es-CO', label: 'Spanish(Colombia)' },
    // { value: 'es-ES', label: 'Spanish(Spain)' },
    // { value: 'es-MX', label: 'Spanish(Mexico)' },
    // { value: 'es-US', label: 'Spanish(United States)' },
    // { value: 'sv-SE', label: 'Swedish(Sweden)' },
]

export const experienceLanguageOptions: { label: string, value: string }[] = [
    { value: 'zh-CN', label: 'Chinese(Simplified, China)' },
    // { value: 'zh-HK', label: 'Chinese(Traditional, Hong Kong)' },
    // { value: 'zh-TW', label: 'Chinese(Traditional, Taiwan)' },
    { value: 'da', label: 'Danish(Denmark)' },
    // { value: 'de-AT', label: 'Deutsch(Austria)' },
    { value: 'de', label: 'Deutsch(Germany)' },
    // { value: 'de-CH', label: 'Deutsch(Switzerland)' },
    // { value: 'en-AU', label: 'English(Australia)' },
    // { value: 'en-CA', label: 'English(Canada)' },
    // { value: 'en-GB', label: 'English(United Kingdom)' },
    // { value: 'en-IE', label: 'English(Ireland)' },
    // { value: 'en-IN', label: 'English(India)' },
    // { value: 'en-NZ', label: 'English(New Zealand)' },
    { value: 'en', label: 'English(United States)' },
    // { value: 'en-ZA', label: 'English(South Africa)' },
    { value: 'fi', label: 'Finnish(Finland)' },
    // { value: 'fr-BE', label: 'French(Belgium)' },
    // { value: 'fr-CA', label: 'French(Canada)' },
    // { value: 'fr-CH', label: 'French(Switzerland)' },
    { value: 'fr', label: 'French(France)' },
    // { value: 'it-CH', label: 'Italian(Switzerland)' },
    { value: 'it', label: 'Italian(Italy)' },
    { value: 'ja', label: 'Japanese(Japan)' },
    { value: 'no', label: 'Norwegian(Norway)' },
    { value: 'pl', label: 'Polish(Poland)' },
    { value: 'pt', label: 'Portuguese(Brazil)' },
    // { value: 'pt-PT', label: 'Portuguese(Portugal)' },
    // { value: 'es-AR', label: 'Spanish(Argentina)' },
    // { value: 'es-CL', label: 'Spanish(Chile)' },
    // { value: 'es-CO', label: 'Spanish(Colombia)' },
    { value: 'es', label: 'Spanish(Spain)' },
    // { value: 'es-MX', label: 'Spanish(Mexico)' },
    // { value: 'es-US', label: 'Spanish(United States)' },
    { value: 'sv', label: 'Swedish(Sweden)' },
]
