import { Alert, Button, Typography, theme } from 'antd'
import { LinkOutlined } from '@ant-design/icons'
import { FormSection } from 'components/form-section/form-section'
import { useTranslate } from 'utils'
import { useField } from 'react-final-form'
import { Variant } from 'types'
import { Label } from 'components/styled'
import { useProductURL } from 'api'
import { HOW_SETUP_ACCOUNT_URL } from 'app.constants'
const { Text } = Typography

export const ServiceDuration = () => {

    //translate:
    const { __ } = useTranslate()

    //theme:
    const { token: { marginXS, marginSM } } = theme.useToken()

    //product url:
    const { input: { value: serviceId } } = useField('id')
    const productUrl = useProductURL(serviceId)
    
    //durations:
    const { input: { value: variants } } = useField('variants')

    //durationVariants:
    const durationVariants = getDurationVariants(variants)

    //render:
    return (
        <FormSection
            title={__`duration`}
            footer={__`shopify_product_option_duration_click_manage_to_modify_durations`}
            topLink={productUrl ? {
                label: __`manage_product`,
                icon: <LinkOutlined/>,
                href: productUrl
            } : undefined}>
            
            <div>
                {durationVariants.length > 0 ? (
                    
                    //list:
                    durationVariants.map(dv => 
                        <Text
                            key={dv.variantId}
                            style={{
                                display: 'inline-block',
                                marginRight: marginXS,
                                marginBottom: marginSM
                            }}
                        >
                            <Label
                                text={dv.duration + ' ' + __`minutes`}
                                size='medium'
                            />
                        </Text>
                    )

                ) : (

                    //no duration:
                    <Alert
                        message={__`duration_is_not_set_for_any_of_variants`}
                        description={__`we_need_to_know_how_long_each_of_your_appointments_services_will_take_in_order_to_manage_your_calendar_please_add_at_least_one_variant_with_a_duration_option`}
                        type='warning'
                        showIcon
                        action={
                            <Button
                                type='link'
                                size='small'
                                href={HOW_SETUP_ACCOUNT_URL}
                                target='_blank'
                            >
                                {__`how_to_add_options`}
                            </Button>
                        }
                    />

                )

            }
            </div>

        </FormSection>
    )

}

//getDurationVariants:
export const getDurationVariants = (variants: Variant[]) => {

    //duration variants:
    const durationVariants: {
        variantId: string,
        duration: string
    }[] = []

    //find duration:
    variants.map((variant: Variant) => {
        variant.options.map(option => {
            if(option.name.toLowerCase() === 'duration'){
                durationVariants.push({
                    variantId: variant.id,
                    duration: option.value
                })
            }
        })
    })

    return durationVariants

}
