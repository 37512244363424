import { PropsWithChildren, ReactNode, useEffect, useState } from 'react'
import { Button, Typography, theme } from 'antd'
import { ArrowLeftOutlined, LoadingOutlined } from '@ant-design/icons'
import { HorizontalContainer, VerticalContainer } from 'components/styled'
import { useElementPosition, useElementScrollBreak, useWindowDimensions } from 'utils'
import { LAYOUT_HEADER_Z_INDEX } from 'z-indexes'
const  { Title, Paragraph } = Typography

export type HeaderButton = {
    name: string
    icon?: ReactNode
    title?: string
    style?: object
    mode?: 'normal' | 'disabled' | 'loading'
    onClick?: () => void
}

type Props = {
    title: string | ReactNode
    description?: string | null
    primaryButton?: HeaderButton
    secondaryButton?: HeaderButton
    backButton?: HeaderButton
    isFormDirty?: boolean,
    zIndex?: number,
    onFooterRender?: (isVisible: boolean) => void
}

export const PageHeader = ({
    title,
    description,
    primaryButton,
    secondaryButton,
    backButton,
    isFormDirty = false,
    zIndex = LAYOUT_HEADER_Z_INDEX,
    onFooterRender,
    children
}: PropsWithChildren<Props>) => {

    //hooks:
    const { token: {
        fontSize,
        marginXXS,
        marginXS,
        marginSM,
        paddingXS,
        padding,
        paddingMD,
        colorBgBase,
        colorBgLayout,
        colorBorder,
        boxShadowTertiary
    }} = theme.useToken()
    const { SM, MD, LG } = useWindowDimensions()
    const { right: headerRight } = useElementPosition('layoutHeader')
    const { isScrollBreak: isLayoutScrollOver75 } = useElementScrollBreak('layoutScrollContainer', 75)
    const { isScrollBreak: isLayoutScrollOver0 } = useElementScrollBreak('layoutScrollContainer', 0)

    //isDualRow:
    const isSingleRow = MD || !backButton

    //state:
    const [ isFooterVisible, setIsFooterVisible ] = useState(false)

    useEffect(() => setIsFooterVisible(LG && isFormDirty && isLayoutScrollOver75), [LG, isFormDirty, isLayoutScrollOver75])
    useEffect(() => onFooterRender?.(isFooterVisible), [isFooterVisible])

    //backButtonJSX:
    const backButtonJSX = (
        <Button
            type='text'
            shape='circle'
            size='large'
            icon={backButton?.icon ?? <ArrowLeftOutlined style={{ fontSize }}/>}
            title={backButton?.name}
            style={{
                marginTop: -marginXXS,
                marginLeft: -marginSM
            }}
            onClick={() => backButton?.onClick?.()}
        />
    )

    //render:
    return (
        <HorizontalContainer style={{
            width: '100%',
            padding: `${padding}px ${padding}px ${paddingXS}px ${padding + (isSingleRow ? 2 : 0)}px`,
            backgroundColor: SM ? colorBgLayout : colorBgBase,
            flexDirection: isSingleRow ? 'row' : 'column-reverse' ,
            justifyContent: 'space-between',
            alignItems: 'flex-start',
            gap: isSingleRow ? 0 : marginXXS,

            // fix the entire header when it is single row and small screen
            position: (!LG && isSingleRow) ? 'sticky' : 'static',
            top: (!LG && isSingleRow) ? 0 : 'unset',
            zIndex: (!LG && isSingleRow) ? zIndex : 'unset',
            borderBottom: (!LG && isSingleRow && isLayoutScrollOver0) ? `solid 1px ${colorBorder}` : 'unset',

            //space for dual row fixed header:
            marginTop: (!LG && !isSingleRow) ? 44 : 0

        }}>

            <HorizontalContainer style={{
                width: '100%',
                flexDirection: isSingleRow ? 'row' : 'column',
                justifyContent: 'flex-start',
                alignItems: 'flex-start',
                gap: marginXS
            }}>

                {/* (laptop) back button */}
                {backButton && isSingleRow && backButtonJSX}
                
                <VerticalContainer>

                    {/* title */}
                    {typeof title === 'object' ? title : (

                        <Title
                            level={3}
                            style={{
                                margin: 0,
                                fontWeight: 'normal'
                            }}
                        >
                            {title}
                        </Title>
                        
                    )}

                    {/* description */}
                    <Paragraph type='secondary' style={{ margin: 0 }}>{description}</Paragraph>

                </VerticalContainer>

            </HorizontalContainer>

            <HorizontalContainer style={{
                width: isSingleRow ? 'unset' : '100%',
                backgroundColor: SM ? colorBgLayout : colorBgBase,
                justifyContent: isSingleRow ? 'flex-end' : 'space-between',

                // fix action and back button when it is dual row and small screen:
                padding: (!LG && !isSingleRow) ? `${padding}px ${padding}px ${paddingXS}px ${padding + (isSingleRow ? 2 : 0)}px` : 0,
                boxShadow: (!LG && !isSingleRow && isLayoutScrollOver0) ? boxShadowTertiary : 'unset',
                borderBottom: (!LG && !isSingleRow && isLayoutScrollOver0) ? `solid 1px ${colorBorder}` : 'unset',
                position: (!LG && !isSingleRow) ? 'fixed' : 'static',
                top: (!LG && !isSingleRow) ? 0 : 'unset',
                left: (!LG && !isSingleRow) ? 0 : 'unset',
                zIndex: (!LG && !isSingleRow) ? zIndex : 'unset'
                
            }}>

                {/* (mobile) back button */}
                {(backButton && !isSingleRow) ? backButtonJSX : <span/>}
                
                {/* fix the action buttons at the bottom */}
                <HorizontalContainer style={isFooterVisible ? {
                    width: '100%',
                    padding: `${paddingXS}px ${paddingMD}px`,
                    backgroundColor: colorBgBase,
                    borderTop: `solid 1px ${colorBorder}`,
                    boxShadow: boxShadowTertiary,
                    justifyContent: 'flex-end',
                    alignItems: 'center',
                    position: 'fixed',
                    bottom: 0,
                    right: headerRight,
                    zIndex: zIndex
                } : undefined}>

                    {/* secondary button */}
                    {secondaryButton &&
                        <Button
                            type={'link'}
                            size={'large'}
                            title={secondaryButton.title}
                            icon={secondaryButton.mode === 'loading' ? <LoadingOutlined/> : secondaryButton.icon}
                            styles={{ icon: { margin: 0 } }}
                            disabled={secondaryButton.mode === 'disabled'}
                            style={{ ...{
                                fontSize,
                                display: 'flex',
                                alignItems: 'center',
                                gap: marginXS
                            }, ...secondaryButton.style }}
                            onClick={secondaryButton.mode === 'loading' ? undefined : secondaryButton.onClick}
                        >
                            {secondaryButton.name}
                        </Button>
                    }

                    {/* primary button */}
                    {primaryButton &&
                        <Button
                            type={'primary'}
                            size={'large'}
                            title={primaryButton.title}
                            icon={primaryButton.mode === 'loading' ? <LoadingOutlined/> : primaryButton.icon}
                            styles={{ icon: { margin: 0 } }}
                            disabled={primaryButton.mode === 'disabled'}
                            style={{ ...{
                                fontSize,
                                display: 'flex',
                                alignItems: 'center',
                                gap: marginXS
                            }, ...primaryButton.style }}
                            onClick={primaryButton.mode === 'loading' ? undefined : primaryButton.onClick}
                        >
                            {primaryButton.name}
                        </Button>
                    }

                    {children}

                </HorizontalContainer>

            </HorizontalContainer>
            
        </HorizontalContainer>
    )

}
