import styled from 'styled-components'

type MembersContainerProps = {
    isEmpty: boolean
}

export const MembersContainer = styled.div<MembersContainerProps>`
    display: flex;
    align-items: ${props => props.isEmpty ? 'center' : 'flex-start'};
    overflow-y: hidden;
    overflow-x: auto;
    position: relative;
`

export const MembersWrapper = styled.div`
    height: 100%;
    padding-bottom: ${props => `${props.theme.marginXS}px`};
    display: flex;
    gap: ${props => `${props.theme.marginXS}px`};
`

type SelectedMemberIndicatorProps = {
    isSelected: boolean
}

export const SelectedMemberIndicator = styled.div<SelectedMemberIndicatorProps>`
    background-color: ${props => props.isSelected ? props.theme.colorFillSecondary : 'transparent'};
    border-radius: ${props => `${props.theme.borderRadius}px`};
    transition: all 200ms ease;
`
