import { TranslateFunction } from 'utils'
import { AppTarget } from '@sesamiapp/app-message'
import { array, object, string } from 'yup'

export type ShopApp = {
    id: string
    name: string
    title: string | null
    icon: {
        id: string | null
        path: string | null
    }
    description: string | null
}

export type RegisteredApp = {
    id: string
    name: string
    title: string | null
    icon: {
        id: string | null
        path: string | null
    }
    description: string | null
    domain: string
    redirectURIs: string[]
    extensions: AppExtension[]
    distribution: AppDistribution
    clientId: string | null
}

export type AppExtension = {
    id: string
    name: string
    target: AppTarget
    url: string
}

export type RegisteredExtension = AppExtension & {
    appId: string
}

export enum AppDistribution {
    PUBLIC = 'PUBLIC',
    PRIVATE ='PRIVATE'
}

export const AppExtensionFormSchema = (__: TranslateFunction) => object({
    name: string().required(__`this_field_is_required`),
    url: string().matches(urlReg, __`must_be_a_valid_url`).required(__`this_field_is_required`)
})

export const RegisterAppFormSchema = (__: TranslateFunction) => object({
    name: string().required(__`this_field_is_required`),
    domain: string().matches(urlReg, __`must_be_a_valid_url`).required(__`this_field_is_required`),
    redirectURIs: array().of(string().matches(urlReg, __`must_be_a_valid_url`)).min(1, __`you_must_specify_at_least_one_uri`)
})

export const UpdateAppFormSchema = (__: TranslateFunction) => object({
    domain: string().matches(urlReg, __`must_be_a_valid_url`).required(__`this_field_is_required`),
    redirectURIs: array().of(string().matches(urlReg, __`must_be_a_valid_url`)).min(1)
})

export const urlReg = RegExp('^(([^:/?#]+):)?(//([^/?#]*))?([^?#]*)(\\?([^#]*))?(#(.*))?')
