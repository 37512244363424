import styled from 'styled-components'
import styledContainerQuery from 'styled-container-query'

export const TextWrapper = styled.div`
    display: flex;
    align-items: center;
`

export const MembersWrapper = styled.div`
    flex: 1;
    max-width: 50%;
`

export const DatePickerWrapper = styled.div`
    flex: 1;
    display: flex;
    justify-content: space-between;
    gap: ${props => `${props.theme.marginXXS}px`};
`

export const MenuWrapper = styledContainerQuery.div`
    width: 490px;
    display: flex;
    gap: ${(props: any) => `${props.theme.marginXS}px`};
    &:container(max-width: 480px){
        flex-direction: column;
        ${MembersWrapper}{
            max-width: 100%;
        }
    }
`

export const MainWrapper = styledContainerQuery.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: ${(props: any) => `${props.theme.marginXS}px`};
    &:container(max-width: 700px){
        flex-direction: column;
        align-items: flex-start;
        ${MenuWrapper}{
            width: 100%;
        }
    }
`
