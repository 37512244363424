export const objectAssign = (mainObject: object, assignObject: object | undefined) => {
    if(assignObject){
        Object.keys(assignObject).forEach((key: any) => {
            if (assignObject[key as keyof typeof assignObject] !== undefined){
                mainObject[key as keyof typeof mainObject] = assignObject[key as keyof typeof assignObject]
            }
        })
    }
    return mainObject
}
