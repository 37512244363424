import { theme } from 'antd'
import { objectAssign } from 'utils'

export type labelType = 'success'| 'info' | 'warning' | 'danger' | 'disable'

type Prop = {
    text: string
    type?: labelType
    size?: 'tiny' | 'small' | 'medium' | 'large'
    color?: string
    style?: object
}

export const Label = ({ text, type = 'info', size = 'small', color, style = {} }: Prop) => {
    
    //theme:
    const { token: {
        paddingXXS,
        paddingXS,
        fontSizeSM,
        fontSize,
        fontSizeLG,
        borderRadiusLG,
        colorText,
        colorTextSecondary,
        colorErrorBorder,
        colorWarningBorder,
        colorSuccessBorder,
        colorInfoBgHover,
        colorFillSecondary
    }} = theme.useToken()

    //render:
    return (
        <mark style={objectAssign({
            // preventing the CSS conflict on re-rendering by not using the shortened names
            paddingTop: size === 'tiny' ? 2 : paddingXXS,
            paddingBottom: size === 'tiny' ? 2 : paddingXXS,
            paddingLeft: paddingXS,
            paddingRight: paddingXS,
            fontSize:
                size === 'tiny'   ? fontSizeSM :
                size === 'small'  ? fontSizeSM :
                size === 'medium' ? fontSize   :
                size === 'large'  ? fontSizeLG :
                fontSize,
            fontWeight: 'normal',
            textAlign: 'center',
            borderRadius: borderRadiusLG,
            backgroundColor: color ?? (
                type === 'info'    ? colorInfoBgHover   :
                type === 'success' ? colorSuccessBorder :
                type === 'warning' ? colorWarningBorder :
                type === 'danger'  ? colorErrorBorder   :
                type === 'disable' ? colorFillSecondary :
                'none'
            ),
            color: type === 'disable' ? colorTextSecondary : colorText,
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap'
        }, style)}>
            {text}
        </mark>
    )
}
