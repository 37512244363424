import { CalendarContainer, CalendarLoadingContainer, MessageContainer } from './booking-calendar.style'
import { BookingCalendarHeader } from '../booking-calendar-header/booking-calendar-header'
import { BookingCalendarCell } from '../booking-calendar-cell/booking-calendar-cell'
import { CalendarProvider } from 'context/calendar-provider'
import { Loading, NotFound, PageError, WhiteShadowLoading } from 'components/styled'
import { useEffect, useMemo, useState } from 'react'
import { Calendar } from 'components/calendar/calendar/calendar'
import { BookingAction, BookingPageDispatch, BookingPageState } from 'pages/booking/booking-page/booking-page'
import { addDays, dateTimeToNumericHour, isSameDay, min, useTranslate, useWindowDimensions } from 'utils'
import { useBookingSlots } from 'api'
import { BookingSlot, Settings } from 'types'
import { theme } from 'antd'
import { BOOKING_CALENDAR_HEADER_Z_INDEX, BOOKING_CALENDAR_SHADOW_Z_INDEX } from 'z-indexes'

type Props = {
    settings: Settings
    pageState: BookingPageState
    pageStateDispatch: BookingPageDispatch
}

export const BookingCalendar = ({ settings, pageState, pageStateDispatch }: Props) => {

    //hooks:
    const { token: { colorBgContainer } } = theme.useToken()
    const { SM, LG } = useWindowDimensions()
    const { __ } = useTranslate()

    //state:
    const [ startHourOfFirstSlotOfAllDays, setStartHourOfFirstSlotOfAllDays ] = useState(9)

    //availabilities:
    const { data: availabilities, isLoading, isFetching, isPreviousData } = useBookingSlots(
        pageState.service?.id ?? '',
        pageState.variant?.id ?? '',
        pageState.participants,
        pageState.service?.flexStartInterval,
        pageState.service?.roundStartInterval ?? 0,
        [[pageState.teamMember?.id ?? '']],
        pageState.startDate,
        pageState.endDate,
        settings.timezone,
        (
            pageState.service    !== null &&
            pageState.teamMember !== null &&
            pageState.variant    !== null
        )
    )

    //data model:
    const dataModel = useMemo(() => {

        if(!availabilities || availabilities.length === 0){
            return getDefaultDataModel(pageState.startDate, pageState.numberOfDays)
        }else{

            let dayStartHour = startHourOfFirstSlotOfAllDays
            const dataModel = []

            for(let i = 0; i < pageState.numberOfDays; i++){

                const startDate = new Date(pageState.startDate.getTime())
                const currentColumnDate = addDays(startDate, i)

                let slots: BookingSlot[] = []
                availabilities.map(availableDay => {

                    const availableDate = new Date(`${availableDay.date} 00:00`)

                    // start of the days:
                    availableDay.slots.map(slot => {
                        dayStartHour = Math.floor(min(dayStartHour, dateTimeToNumericHour(slot.startDateTime, availableDate, true)))
                    })

                    // push slots:
                    if(isSameDay(currentColumnDate, availableDate)){
                        slots = availableDay.slots
                    }

                })

                // add to data model:
                dataModel.push({
                    date: currentColumnDate,
                    info: {
                        slots: slots
                    }
                })

            }

            setStartHourOfFirstSlotOfAllDays(dayStartHour)
            return ([dataModel])

        }
        
    }, [availabilities, isLoading, pageState])

    // update slots loading:
    useEffect(() => {
        pageStateDispatch({ type: BookingAction.SET_ARE_SLOTS_LOADING, payload: { areSlotsLoading: isFetching }})
    }, [isFetching])

    return (
        <CalendarLoadingContainer>

            <CalendarContainer>

                <CalendarProvider dataModel={dataModel}>

                    <Calendar
                        headerRenderer={(date, index) => (
                            <BookingCalendarHeader numberOfDays={pageState.numberOfDays} date={date} index={index}/>
                        )}
                        headerStyle={{
                            backgroundColor: colorBgContainer,
                            position: 'sticky',
                            top: LG ? 112 : SM ? 52 : 85,
                            zIndex: BOOKING_CALENDAR_HEADER_Z_INDEX
                        }}
                        cellRenderer={(date, index) =>
                            <BookingCalendarCell
                                date={date}
                                index={index}
                                startHourOfFirstSlotOfAllDays={startHourOfFirstSlotOfAllDays}
                                pageState={pageState}
                                pageStateDispatch={pageStateDispatch}
                            />
                        }
                    />

                </CalendarProvider>

            </CalendarContainer>

            {/* shadow */}
            {(!availabilities || isPreviousData) &&
                <>
                    <WhiteShadowLoading
                        hasLoading={false}
                        style={{ zIndex: BOOKING_CALENDAR_SHADOW_Z_INDEX }}
                    >

                        <MessageContainer>
                            {
                                (pageState.service === null || pageState.variant === null) ? (
                                    <NotFound message={__`select_a_service`}/>
                                ) : pageState.teamMember === null ? (
                                    <NotFound message={__`select_a_team_member`}/>
                                ) : availabilities === null ? (
                                    <PageError/>
                                ) : (
                                    <Loading style={{ width: 'auto', height: 'auto' }}/>
                                )
                            }
                        </MessageContainer>

                    </WhiteShadowLoading>
                </>
            }

        </CalendarLoadingContainer>
    )

}

//getDefaultDataModel:
const getDefaultDataModel = (startDate: Date, numberOfDays: number) => {
    const dataModel = []
    for(let i = 0; i < numberOfDays; i++){
        dataModel.push({
            date: addDays(new Date(startDate.getTime()), i),
            info: {
                slots: []
            }
        })
    }
    return [dataModel]
}
