import styled from 'styled-components'

export const MainWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: ${props => `${props.theme.marginXS}px`};
`

export const ActionsWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
`
