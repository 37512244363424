import { LabelWrapper } from './appointment-item.style'
import { Button, Typography, theme } from 'antd'
import { getStatusText, getStatusType, useTranslate, useWindowDimensions } from 'utils'
import { FormSection } from 'components/form-section/form-section'
import { LinkOutlined } from '@ant-design/icons'
import { Appointment, Config } from 'types'
import { useConfig, useOrderURL } from 'api'
import { Info } from 'components/info/info'
import { HorizontalContainer, Label, LineClampText, VerticalContainer } from 'components/styled'
const { Text } = Typography

type Props = {
    appointment: Appointment
    onClick: () => void
}

export const AppointmentItem = ({ appointment, onClick }: Props) => {

    //hooks:
    const { __ } = useTranslate()
    const { token: { colorTextDescription, colorBorderSecondary, paddingSM, padding, marginSM, marginXS, fontSizeSM, fontSizeLG } } = theme.useToken()
    const { SM } = useWindowDimensions()
    const { data: config, isLoading: isConfigLoading } = useConfig()
    const orderUrl = useOrderURL(appointment.orderId)

    //getTime:
    const getTime = (date: Date, config: Config) => (
        date.toLocaleString([config.locale, 'en-US'], {
            month: 'short',
            day: 'numeric',
            weekday: 'short',
            hour: 'numeric',
            minute: '2-digit'
        })
    )

    return ((isConfigLoading || !config) ? null : (
        <Button
            type='text'
            style={{
                width: '100%',
                height: 'auto',
                padding: 0,
                borderRadius: 0,
                textAlign:'left',
                border: 'none'
            }}
            onClick={onClick}
        >
            <FormSection
                title={
                    <LineClampText
                        lineClamp={2}
                        style={{
                            fontSize: fontSizeLG,
                            fontFamily: 'inter-medium',
                            textAlign: 'left',
                            marginTop: marginXS,
                            marginBottom: marginSM
                        }}
                    >
                        {appointment.service?.title ?? `[${__`deleted_service`}]`}
                    </LineClampText>
                }
                topLink={!orderUrl ? undefined : {
                    label: __`view_order`,
                    icon: <LinkOutlined/>,
                    href: orderUrl
                }}
                forceDropdown={true}
                style={{
                    // preventing the CSS conflict on re-rendering by not using the shortened names
                    paddingTop: paddingSM,
                    paddingBottom: padding,
                    background: 'transparent',
                    borderTop: `solid 1px ${colorBorderSecondary}`,
                    borderLeft: 'none',
                    borderRight: 'none',
                    borderBottom: 'none',
                    borderRadius: 0,
                    boxShadow: 'none',
                    gap: marginXS
                }}
            >

                <HorizontalContainer
                    style={{
                        flexDirection: SM ? 'row' : 'column' ,
                        alignItems: 'flex-start',
                        gap: SM ? marginSM : marginXS,
                        position: 'relative'
                    }}
                >

                    <VerticalContainer style={{ width: '50%', gap: marginXS }}>

                        <VerticalContainer>

                            {/* time */}
                            <Text>{getTime(new Date(appointment.datetime), config)}</Text>

                            {/* duration */}
                            <Text
                                title={appointment.variantTitle ?? undefined}
                                style={{
                                    fontSize: fontSizeSM,
                                    color: colorTextDescription,
                                    textOverflow: 'ellipsis',
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden'
                                }}
                            >
                                {appointment.variantTitle}
                            </Text>

                        </VerticalContainer>
                        
                        <VerticalContainer>

                            <HorizontalContainer style={{ justifyContent: 'flex-start' }}>

                                {/* order name */}
                                <Text>{__`order`}</Text>
                                <Info value={appointment.orderName}/>

                            </HorizontalContainer>

                            <HorizontalContainer style={{ justifyContent: 'flex-start' }}>

                                {/* order id */}
                                <Text
                                    title={__`order_id`}
                                    style={{
                                        fontSize: fontSizeSM,
                                        color: colorTextDescription
                                    }}
                                >
                                    {appointment.orderId}
                                </Text>

                                {/* price & currency */}
                                <Text
                                    style={{
                                        fontSize: fontSizeSM,
                                        color: colorTextDescription
                                    }}
                                >
                                    • {appointment.price.toString()} {appointment.currency}
                                </Text>
                            </HorizontalContainer>

                        </VerticalContainer>

                    </VerticalContainer>

                    <VerticalContainer style={{ width: '50%', gap: marginXS }}>

                        <VerticalContainer>

                            {/* customer */}
                            <Text>{
                                appointment.customer?.name ??
                                appointment.customer?.email ??
                                appointment.customer?.phone ??
                                appointment.customer?.billingPhone ??
                                __`unknown`
                            }</Text>
                            <Text
                                style={{
                                    fontSize: fontSizeSM,
                                    color: colorTextDescription
                                }}
                            >
                                {__`customer`}
                            </Text>

                        </VerticalContainer>


                        <VerticalContainer>

                            {/* team member */}
                            <Text>{appointment.teamMember?.name ?? <Label type='danger' text={__`not_assigned`}/>}</Text>
                            <Text
                                style={{
                                    fontSize: fontSizeSM,
                                    color: colorTextDescription
                                }}
                            >
                                {__`team_member`}
                            </Text>

                        </VerticalContainer>

                    </VerticalContainer>

                    {/* status */}
                    <LabelWrapper>
                        <Label
                            text={getStatusText(appointment.status, __)}
                            type={getStatusType(appointment.status)}
                            style={{ minWidth: 60, display: 'block' }}
                            size='tiny'
                        />
                    </LabelWrapper>

                </HorizontalContainer>

            </FormSection>
        </Button>
    ))
    
}
