import styled from 'styled-components'

export const MainWrapper = styled.div`
    width: 100%;
    display: flex;
    padding-top: ${props => `${props.theme.paddingXXS}px`};
    border-bottom: solid 1px ${props => props.theme.colorBorderSecondary};
`

export const MainContainer = styled.div`
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
`

export const DateContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: ${props => `${props.theme.marginXS}px 0`};
`
