import { MainWrapper } from './availability-calendar-header.style'
import { useConfig } from 'api'
import { Typography } from 'antd'
const { Text } = Typography

type Props = {
    date: Date
}

export const AvailabilityCalendarHeader = ({ date }: Props) => {
    
    //config:
    const { data: config, isLoading: isConfigLoading } = useConfig()
    
    //render:
    return ((isConfigLoading || !config) ? null : (
        <MainWrapper>
            <Text>
                {date.toLocaleString([config.locale, 'en-US'], { weekday: 'short' })}
            </Text>
        </MainWrapper>
    ))
    
}
