import styled from 'styled-components'

export const RWContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-end;
    gap: ${props => `${props.theme.marginSM}px`};
`

type RWElementProps = {
    numberOfElements?: number
    breakPoint?: number
}

export const RWElement = styled.div<RWElementProps>`
    flex: ${props => `max(${props.breakPoint ?? 250}px, 100%/${props.numberOfElements ?? 2} - ${props.theme.marginSM*2}px)`};
`
