import { MutationCache, QueryCache, QueryClient } from '@tanstack/react-query'
import { message } from 'antd'
import { CACHE_DEFAULT_STALE_TIME, OOPS_ERROR } from 'app.constants'

export const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            staleTime: CACHE_DEFAULT_STALE_TIME,
            refetchOnReconnect: 'always'
        }
    },
    queryCache: new QueryCache({
        onError: () => {
            message.error(OOPS_ERROR)
        }
    }),
    mutationCache: new MutationCache({
        onError: () => {
            message.error(OOPS_ERROR)
        }
    })
})
