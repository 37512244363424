import styled from 'styled-components'
import styledContainerQuery from 'styled-container-query'

export const DatePickerWrapper = styled.div`
    flex: 1;
    display: flex;
    justify-content: space-between;
    gap: ${props => `${props.theme.marginXXS}px`};
`

export const ArrowsWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    gap: ${props => `${props.theme.marginXXS}px`};
`

export const MenuWrapper = styledContainerQuery.div`
    width: 270px;
    height: fit-content;
    display: flex;
    gap: ${(props: any) => `${props.theme.marginXS}px`};
`

type Props = {
    top: number
    zIndex: number
}

export const MainWrapper = styledContainerQuery.div<Props>`
    width: 100%;
    padding-top: ${(props: any) => `${props.theme.padding}px`};
    padding-bottom: ${(props: any) => `${props.theme.paddingXXS}px`};
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: ${(props: any) => `${props.theme.colorBgContainer}`};
    gap: ${(props: any) => `${props.theme.marginXS}px`};
    position: sticky;
    top: ${(props: any) => `${props.top}px`};
    z-index: ${(props: any) => `${props.zIndex}`};
    &:container(max-width: 470px){
        flex-direction: column;
        align-items: flex-start;
        ${MenuWrapper}{
            width: 100%;
        }
    }
`
