import { isNil, useTranslate } from 'utils'
import { BookingAction, BookingPageDispatch, BookingPageState } from 'pages/booking/booking-page/booking-page'
import { TeamMemberSelect } from 'components/team-member/team-member-select/team-member-select'

type Props = {
    pageState: BookingPageState
    pageStateDispatch: BookingPageDispatch
}

export const BookingTeamMembers = ({ pageState, pageStateDispatch }: Props) => {

    //translate:
    const { __ } = useTranslate()

    //render:
    return (
        <TeamMemberSelect
            items={pageState.service?.teamMembers ?? []}
            selectedId={pageState.teamMember?.id ?? null}
            areItemsVisible={!isNil(pageState.service)}
            itemsNotVisibleMessage={__`select_a_service`}
            onSelect={member => pageStateDispatch({ type: BookingAction.SET_TEAM_MEMBER, payload: { teamMember: member } })}
        />
    )

}
