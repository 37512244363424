import styled from 'styled-components'

export const FooterLink = styled.a`
    font-family: 'inter-medium';
    font-size: ${props => `${props.theme.fontSizeSM}px`};
    color: ${props => `${props.theme.colorTextDescription}`};
    text-decoration: none;
    &:hover, &:focus{
        text-decoration: underline;
    }
`
