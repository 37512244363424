import { Button, theme } from 'antd'
import { Avatar } from 'components/avatar/avatar'
import { useTranslate, useWindowDimensions } from 'utils'
import { HorizontalContainer, Label, labelType } from 'components/styled'
import { RegisteredApp } from 'types'

type Props = {
    app: RegisteredApp
    labelKey?: string
    labelType?: labelType
    onClick: () => void
}

export const RegisteredAppItem = ({ app, labelKey, labelType, onClick }: Props) => {

    //hooks:
    const { token: { paddingXXS, paddingXS, paddingSM, padding, borderRadius } } = theme.useToken()
    const { __ } = useTranslate()
    const { SM } = useWindowDimensions()

    return (
        <Button
            type='text'
            style={{
                width: '100%',
                height: 'auto',
                padding: SM ? `0 ${paddingXS}px 0 0` : `0 ${padding}px 0 ${paddingSM}px`,
                borderRadius: SM ? borderRadius : 0
            }}
            onClick={onClick}
        >

            <HorizontalContainer style={{ width: '100%' }}>

                {/* image and title */}
                <Avatar
                    name={app.name}
                    image={app.icon.path}
                    description={app.title}
                    style={{ paddingLeft: paddingXXS }}
                />
                
                {/* label */}
                {labelKey && labelType && (
                    <Label
                        type={labelType}
                        text={__(labelKey)}
                    />
                )}

            </HorizontalContainer>

        </Button>
    )
    
}
