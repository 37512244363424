import { useCurrentShop } from './shop.api'
import { useMutation } from '@tanstack/react-query'
import { Service } from 'types'
import { useMutationHandler, usePaginationHandler, useQueryHandler, getShopId } from 'utils'

const serviceQuery = `
    
    id
    title
    image {
        id
        path
    }

    teamMembers {
        id
        name
        image{
            id
            path
        }
    }
    
    variants {
        id
        externalId
        title
        options {
        name
        value
        }
    }

    location {
        content
    }
    eventDescription
    
    availabilities {

        type
        weekday
        date

        intervals {
            from
            to
        }

    }

    bufferTimeBefore
    bufferTimeAfter
    bufferFromNow

    groupAppointments
    groupAppointmentSlots

    storefrontStartDate
    instantBooking
    displayTeamOnStorefront

    bookingUntilDays
    disableSameDayBooking
    flexStartInterval @include(if: $flexStart)
    roundStartInterval

    customerCanCancel
    customerCanReschedule
    customerCanManageBefore
    refundMoneyOnCancel

`

//get services:
export const useServices = (search: string | null, limit: number) => {

    const { data: shop } = useCurrentShop()
    const shopId = getShopId()

    return usePaginationHandler<Service[]>({
        query: `
            query getServices (
                
                $shop: String!,
                $searchTerm: String,
                $limit: Int!,
                $after: String,
                $before: String,
                $flexStart: Boolean!

            ) {
                getServices(

                    shop: $shop,
                    searchTerm: $searchTerm,
                    limit: $limit,
                    after: $after,
                    before: $before

                ) {

                    data {
                        ${serviceQuery}
                    }
                    
                    totalItems
                    remainingItems
                    nextCursor
                    previousCursor

                }
            }
        `,
        variables: {
            shop: shopId,
            searchTerm: search,
            limit,
            after: null,
            before: null,
            flexStart: shop ? shop.isMigrated : false
        },
        cacheKey: ['services', shopId],
        limit,
        getResponse: json => json.data.getServices,
        onSuccess: response => response.data
    })

}

//get service:
export const useService = (id: string, enabled?: boolean, onNotFound?: (json: any) => void) => {

    const { data: shop } = useCurrentShop()
    const shopId = getShopId()
    
    return useQueryHandler<Service>({
        query: `
            query getService (
                $shop: String!,
                $id: String!,
                $flexStart: Boolean!
            ) {
                getService (
                    shop: $shop,
                    id: $id
                ) {
                    ${serviceQuery}
                }
            }
        `,
        variables: {
            shop: shopId,
            id,
            flexStart: shop ? shop.isMigrated : false
        },
        cacheKey: ['service', id, shopId],
        enabled,
        onSuccess: json => json.data.getService,
        onNotFound
    })
}

//create:
export const useCreateService = (service: Service) => useMutation(async () => {
    return await new Promise(resolve => {
        setTimeout(() => {
            resolve(service)
        }, 1000)
    })
})

//update:
export const useUpdateService = (service: Service) => {

    const shopId = getShopId()
    
    return useMutationHandler<boolean>({
        query: `
            mutation updateService (
                
                $shop: String!,
                $updateServiceId: String!,
                $request: UpdateServiceGraphRequest!

            ) {
                updateService (

                    shop: $shop,
                    id: $updateServiceId,
                    request: $request
                    
                ) {
                    id
                }
            }
        `,
        variables: {
            
            shop: shopId,
            updateServiceId: service.id,

            request: {

                teamMembers: service.teamMembers.map(m => m.id),
            
                location: service.location,
                eventDescription: service.eventDescription,
                
                availabilities: service.availabilities,

                bufferTimeBefore: service.bufferTimeBefore,
                bufferTimeAfter: service.bufferTimeAfter,
                bufferFromNow: service.bufferFromNow,

                groupAppointments: service.groupAppointments,
                groupAppointmentSlots: service.groupAppointmentSlots,

                storefrontStartDate: service.storefrontStartDate,
                instantBooking: service.instantBooking,
                displayTeamOnStorefront: service.displayTeamOnStorefront,

                bookingUntilDays: service.bookingUntilDays,
                disableSameDayBooking: service.disableSameDayBooking,
                flexStartInterval: service.flexStartInterval,
                roundStartInterval: service.roundStartInterval,

                customerCanCancel: service.customerCanCancel,
                customerCanReschedule: service.customerCanReschedule,
                customerCanManageBefore: service.customerCanManageBefore,
                refundMoneyOnCancel: service.refundMoneyOnCancel,

            }

        },
        onSuccess: json => json.data.updateService.id === service.id
    })
}

//remove:
export const useRemoveService = (id: string) => {

    const shopId = getShopId()

    return useMutationHandler<boolean>({
        query: `
            mutation deleteService (

                $shop: String!,
                $id: String!

            ) {
                deleteService (

                    shop: $shop,
                    id: $id

                ) {
                    message
                }
            }
        `,
        variables: {
            shop: shopId,
            id
        },
        onSuccess: json => json.data.deleteService.message === 'Done'
    })
}

//product url:
export const useProductURL = (serviceId: string) => {
    const { data: shop } = useCurrentShop()
    if(shop && shop.url){
        const shopName = shop.url.replace('.myshopify.com', '')
        return `https://admin.shopify.com/store/${shopName}/products/${serviceId}`
    }else{
        return null
    }
}
