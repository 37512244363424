import { Field } from 'react-final-form'

export const NullableField = (props: any) => (
    <Field
        parse={value => {
            if(!value || (typeof value === 'string' && value.length === 0)){
                return null
            }else{
                return value
            }
        }}
        {...props}
    />
)
