import { LoadingContainer, MainWrapper } from './vertical-paginated-list.style'
import { Button, Typography, theme } from 'antd'
import { LeftOutlined, RightOutlined } from '@ant-design/icons'
import { Forbidden, HorizontalContainer, Loading, NotFound, PageError, VerticalContainer } from 'components/styled'
import { PropsWithChildren } from 'react'
import { useTranslate } from 'utils'
const { Text } = Typography

type Props = {
    isFirstLoading: boolean
    isForbidden: boolean
    isError: boolean
    notFound: boolean
    pageNum: number
    totalPage: number
    isPreviousData: boolean
    isNextDisabled: boolean
    isPreviousDisabled: boolean
    scrollable?: boolean
    fetchNext: () => void
    fetchPrevious: () => void
}

export const VerticalPaginatedList = ({
    isFirstLoading,
    isForbidden,
    isError,
    notFound,
    isPreviousData,
    pageNum,
    totalPage,
    isNextDisabled,
    isPreviousDisabled,
    scrollable = false,
    fetchNext,
    fetchPrevious,
    children
}: PropsWithChildren<Props>) => {

    //hooks:
    const { __ } = useTranslate()
    const { token: { fontSizeSM, marginXS, marginSM } } = theme.useToken()

    return (
        <MainWrapper style={{ height: scrollable ? '100%' : 'auto' }}>

            {isFirstLoading ? <Loading/> : isForbidden ? <Forbidden/> : isError ? <PageError/> : notFound ? <NotFound message={__`nothing_found`}/> :

                <>
            
                    <VerticalContainer
                        style={{
                            height: scrollable ? '100%' : 'auto',
                            alignItems: 'center',
                            opacity: isPreviousData ? .5 : 1,
                            gap: marginXS
                        }}
                    >

                        {/* children */}
                        <div style={{ width: '100%', flex: 1, overflowY: 'auto' }}>
                            <VerticalContainer style={{ width: '100%', gap: marginXS }}>
                                {children}
                            </VerticalContainer>
                        </div>

                        {/* buttons */}
                        {totalPage > 1 && (
                            <HorizontalContainer style={{ width: 'fit-content', margin: `${marginXS}px 0`, gap: marginSM }}>
                                
                                <Button
                                    size={'middle'}
                                    disabled={isPreviousDisabled}
                                    style={{
                                        minWidth: 100,
                                        fontSize: fontSizeSM,
                                        backgroundColor: 'transparent'
                                    }}
                                    onClick={fetchPrevious}
                                >
                                    <LeftOutlined style={{ fontSize: fontSizeSM }}/>
                                    {__`previous`}
                                </Button>

                                {/* page number */}
                                <Text style={{ fontSize: fontSizeSM }}>{`${pageNum}/${totalPage}`}</Text>
                                
                                <Button
                                    size={'middle'}
                                    disabled={isNextDisabled}
                                    style={{
                                        minWidth: 100,
                                        fontSize: fontSizeSM,
                                        backgroundColor: 'transparent'
                                    }}
                                    onClick={fetchNext}
                                >
                                    {__`next`}
                                    <RightOutlined style={{ fontSize: fontSizeSM }}/>
                                </Button>

                            </HorizontalContainer>
                        )}

                    </VerticalContainer>

                    {/* loading */}
                    <LoadingContainer
                        style={{ display: isPreviousData ? 'block' : 'none' }}
                        onClick={e => e.stopPropagation()}
                    >
                        <Loading/>
                    </LoadingContainer>

                </>

            }

        </MainWrapper>
    )

}
