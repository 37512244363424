import { QuestionCircleOutlined, ExclamationCircleOutlined } from '@ant-design/icons'
import { Button, Tooltip, Typography, theme } from 'antd'
const { Text } = Typography

type Props = {
    message: string
    type?: 'info' | 'warning'
}

export const Message = ({ message, type = 'info' }: Props) => {

    //theme:
    const { token: {
        colorText,
        colorBgContainer,
        fontSizeSM,
        paddingXXS,
    }} = theme.useToken()

    //render:
    return (
        <Tooltip
            title={<Text style={{ color: colorText }}>{message}</Text>}
            color={colorBgContainer}
            overlayClassName='customZIndexTooltip' // styled in index.scss
            trigger={['focus', 'hover']}
            style={{ display: 'flex' }}
            overlayStyle={{ fontSize: fontSizeSM }}
        >
            <Button
                type='text'
                style={{
                    height: 'fit-content',
                    padding: `0 ${paddingXXS}px`,
                    color: 'inherit',
                    backgroundColor: 'transparent',
                }}
            >
                {type === 'info' ? <QuestionCircleOutlined/> : <ExclamationCircleOutlined/>}
            </Button>
        </Tooltip>
    )
}
