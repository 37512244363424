import styled from 'styled-components'

export const MainWrapper = styled.div`
    width: 100%;
    padding: ${props => `0 ${props.theme.padding}px`};
    display: flex;
    flex-direction: column;
    gap: ${props => `${props.theme.marginXXS}px`};
`

export const TitleWrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
`

export const TitleNameWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: ${props => `${props.theme.marginXXS}px`};
`

export const DescriptionWrapper = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    gap: ${props => `${props.theme.marginXXS}px`};
`
