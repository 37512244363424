import { array, object, string } from 'yup'
import { TranslateFunction } from '../utils'

export type RefreshToken = {
    userId: string
    clientId: string
    email: string
    exp: number
    iat: number
}

export type AccessToken = {
    userId: string
    clientId: string
    email: string
    exp: number
    iat: number
}

export type PersonalAccessToken = {
    id: string
    name: string
    clientId: string
    permissions: string[]
    expireAt: 'THIRTY' | 'SIXTY' | 'NINETY' | 'NEVER'
}

export const PersonalAccessTokenFormSchema = (__: TranslateFunction) => object({
    name: string().required(__`this_field_is_required`),
    permissions: array().min(1)
})
