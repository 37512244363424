import { Typography, theme } from 'antd'
import { FormInput, FormInputNumber, FormSelect } from 'components/form-inputs'
import {FormSection} from 'components/form-section/form-section'
import { RWContainer, RWElement, VerticalContainer } from 'components/styled'
import { FlowAction, FlowInterval, FlowMeta } from 'types'
import { Field, useField } from 'react-final-form'
import { useTranslate } from 'utils'
import { useEffect, useState } from 'react'
const  { Paragraph } = Typography

export const FlowConfiguration = () => {

    //theme:
    const { token: { marginXS } } = theme.useToken()

    //translate:
    const { __ } = useTranslate()

    //fields:
    const { input: { value: amount, onChange: updateAmount } } = useField('when.amount')
    const { input: { value: unit } } = useField('when.unit')
    const { input: { value: action } } = useField('action')
    const { input: { value: triggerVal } } = useField('trigger')
    const selectedTrigger = triggerVal as keyof typeof FlowMeta

    const [ lastAmount, setLastAmount ] = useState(amount)

    //save the last amount:
    useEffect(() => {
        
        // immediately:
        if(unit === FlowInterval.IMMEDIATELY){
            setLastAmount(amount)
            updateAmount(0)
        }else{
            updateAmount(lastAmount)
        }

        // max limit:
        const maxLimit = getFlowAmountMaxLimit(unit)
        if(amount > maxLimit){
            updateAmount(maxLimit)
        }

    }, [unit])

    useEffect(() => { amount && setLastAmount(amount) }, [amount])

    //render:
    return (
        <FormSection>
            
            <VerticalContainer style={{ gap: marginXS }}>

                {/* name */}
                <Field
                    name='name'
                    type='text'
                    placeholder={__`email_reminder_to_customer`}
                    component={FormInput}
                    title={__`name`}
                    description={__`pick_a_name_for_your_flow`}
                />

                {/* trigger */}
                <Field
                    name='trigger'
                    type='select'
                    options={[
                        { value: FlowMeta.APPOINTMENT_CREATED.value    , label: __(FlowMeta.APPOINTMENT_CREATED.value.toLowerCase()    )},
                        { value: FlowMeta.APPOINTMENT_RESCHEDULED.value, label: __(FlowMeta.APPOINTMENT_RESCHEDULED.value.toLowerCase())},
                        { value: FlowMeta.CUSTOMER_CHECKED_IN.value    , label: __(FlowMeta.CUSTOMER_CHECKED_IN.value.toLowerCase()    )},
                        { value: FlowMeta.APPOINTMENT_STARTED.value    , label: __(FlowMeta.APPOINTMENT_STARTED.value.toLowerCase()    )},
                        { value: FlowMeta.APPOINTMENT_CANCELLED.value  , label: __(FlowMeta.APPOINTMENT_CANCELLED.value.toLowerCase()  )},
                        { value: FlowMeta.NO_SHOW.value                , label: __(FlowMeta.NO_SHOW.value.toLowerCase()                )}
                    ]}
                    component={FormSelect}
                    title={__`trigger`}
                    description={__`the_event_that_starts_the_flow`}
                />

                {/* action */}
                <Field
                    name='action'
                    type='select'
                    options={
                        FlowMeta[selectedTrigger]?.actions.map(action => (
                            { value: action, label: __(action.toLowerCase())}
                        ))
                    }
                    notFoundContent={__`select_a_trigger`}
                    component={FormSelect}
                    title={__`action`}
                    description={__`the_type_of_action_to_do`}
                />

                {/* target */}
                <Field
                    name='target'
                    type='text'
                    placeholder={action === FlowAction.EMAIL ? __`customer_email` : __`webhook_sample_url`}
                    component={FormInput}
                    title={__`target`}
                    description={__`target_of_your_flow_s_action`}
                />

                {/* when */}
                <VerticalContainer>

                    <RWContainer>

                        <RWElement breakPoint={175}>
                            <Field
                                name='when.amount'
                                min={0}
                                max={getFlowAmountMaxLimit(unit)}
                                keyboard
                                style={{ width: '100%' }}
                                disabled={unit === FlowInterval.IMMEDIATELY}
                                component={FormInputNumber}
                                title={__`when`}
                            />
                        </RWElement>

                        <RWElement breakPoint={175}>
                            <Field
                                name='when.unit'
                                component={FormSelect}
                                type='select'
                                options={
                                    FlowMeta[selectedTrigger]?.intervals.map(interval => (
                                        { value: interval, label: __(interval.toLowerCase())}
                                    ))
                                }
                                notFoundContent={__`select_a_trigger`}
                            />
                        </RWElement>

                    </RWContainer>
                    
                    <Paragraph type='secondary' style={{ margin: 0 }}>{__`minutes_hours_or_days_after_before_the_event_happens`}</Paragraph>
                
                </VerticalContainer>

            </VerticalContainer>

        </FormSection>
    )

}

const getFlowAmountMaxLimit = (unit: FlowInterval) => (
    (unit === FlowInterval.DAYS_BEFORE    || unit === FlowInterval.DAYS_AFTER   ) ? 30 :
    (unit === FlowInterval.HOURS_BEFORE   || unit === FlowInterval.HOURS_AFTER  ) ? 30*24 :
    (unit === FlowInterval.MINUTES_BEFORE || unit === FlowInterval.MINUTES_AFTER) ? 30*24*60 :
    0 // FlowInterval.IMMEDIATELY
)
