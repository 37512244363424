import { Notification } from 'types'
import { getRefreshTokenUserId, refreshToken, useQueryHandler } from 'utils'

//useNotification:
export const useNotification = () => useQueryHandler<Notification>({
    query: `
        query getNotification {
            getNotification {
                id
                type
                message
                description
            }
        }
    `,
    cacheKey: ['notification', getRefreshTokenUserId(refreshToken())],
    onSuccess: json => json.data.getNotification
})
