import styled from 'styled-components'

type ColumnContainerProps = {
    marginTop?: number
    gap?: number
}

export const ColumnContainer = styled.div<ColumnContainerProps>`
    width: 100%;
    margin-top: ${props => `${props.marginTop ?? 0}px`};
    display: flex;
    flex-direction: column;
    gap: ${props => `${props.gap ?? 0}px`};
`
