import { getShopId, useQueryHandler, usePaginationHandler, updateShopId, isNil, queryClient, getRefreshTokenUserId, refreshToken } from 'utils'
import { Shop } from 'types'

//shop:
export const useCurrentShop = () => {

    const shopId = getShopId()

    const removeShop = () => {
        updateShopId(null)
        queryClient.removeQueries(['currentShop'])
        location.reload()
    }
    
    //handler:
    return useQueryHandler<Shop>({
        query: shopId ? `
            query getShop (
                $id: String!
            ) {
                getShop (
                    id: $id
                ) {
                    id
                    name
                    image {
                        id
                        path
                    }
                    url
                    isMigrated
                }
            }
        ` : `
            query getShops (
                $limit: Int!
            ) {
                getShops (
                    limit: $limit
                ) {
                    data {
                        id
                    }
                }
            }
        `,
        variables: shopId ? {
            id: shopId
        } : {
            limit: 1
        },
        cacheKey: ['currentShop', shopId, getRefreshTokenUserId(refreshToken())],
        enabled: !isNil(refreshToken()),
        onSuccess: json => {
            const shop: Shop = shopId ? json.data.getShop : json.data.getShops.data[0]
            if(!shopId){
                updateShopId(shop.id)
            }
            return shop
        },
        onNotFound: removeShop,
        onForbidden: removeShop,
        onUnknownError: removeShop
    })

}

//useShops:
export const useShops = (search: string | null, limit: number) => usePaginationHandler<Shop[]>({
    query: `
        query getShops (

            $searchTerm: String,
            $limit: Int!,
            $after: String,
            $before: String

        ) {
            getShops (

                searchTerm: $searchTerm,
                limit: $limit,
                after: $after,
                before: $before

            ) {

                data {
                    id
                    name
                    image {
                        id
                        path
                    }
                    url
                }

                totalItems
                remainingItems
                nextCursor
                previousCursor

            }
        }
    `,
    variables: {
        searchTerm: search,
        limit,
        after: null,
        before: null
    },
    cacheKey: ['shops', getRefreshTokenUserId(refreshToken())],
    limit,
    getResponse: json => json.data.getShops,
    onSuccess: response => response.data
})
