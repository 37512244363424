import { boolean, number, object, string } from 'yup'

export type Settings = {

    status: boolean

    timezone: string
    hideTimezoneFromStorefront: boolean
    emailCalendarEventToMerchant: boolean
    emailCalendarEventToCustomer: boolean

    calendarExperience?: 'CLASSIC' | 'V2'
    locale: string
    timeFormat: TimeFormat
    storefrontDateFormat: string | null
    reservationPeriod: number | null
    instantBooking: {
        requiredFields: string[] | null
    }
    removeBranding: boolean

}

export type TimeFormat = '12' | '24'

export const SettingsFormSchema = object({

    status:                         boolean(),

    timezone:                       string(),
    hideTimezoneFromStorefront:     boolean(),
    emailCalendarEventToMerchant:   boolean(),
    emailCalendarEventToCustomer:   boolean(),

    locale:                         string(),
    timeFormat:                     string().oneOf(['12', '24']),
    storefrontDateFormat:           string().nullable(),
    reservationPeriod:              number().oneOf([0, 5, 10, 15, 30, 60]),
    removeBranding:                 boolean()

})
