import { PropsWithChildren, createContext, useContext } from 'react'

export type CalendarDataModel = {
    date: Date
    info: any
}[][]

const defaultValue: CalendarDataModel = []

const CalendarContext = createContext<CalendarDataModel>(defaultValue)

export const useCalendar = () => useContext(CalendarContext)
export const useCell = (date: Date) => {
    const data = useCalendar()
    for(let i = 0; i < data.length; i++ ){
        for(let j = 0; j < data[i].length; j++ ){
            if(data[i][j].date === date){
                return data[i][j].info
            }
        }
    }
    return ''
}

type Props = {
    dataModel: CalendarDataModel
}

//component:
export const CalendarProvider = ({ dataModel, children }: PropsWithChildren<Props>) =>
    <CalendarContext.Provider value={dataModel}>
        {children}
    </CalendarContext.Provider>
