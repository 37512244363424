import { UserPermissionHeader } from 'components/user/user-permission-header/user-permission-header'
import { useState } from 'react'
import { theme } from 'antd'
import { Avatar } from 'components/avatar/avatar'
import { useTranslate } from 'utils'
import { RightOutlined } from '@ant-design/icons'
import { useFlows } from 'api'
import { VerticalContainer } from 'components/styled'
import { getFlowIcon } from 'types'
import { Search } from 'components/search/search'
import { VerticalPaginatedList } from 'components/vertical-paginated-list/vertical-paginated-list'

type Props = {
    onSelect: (id: string) => void
    onBack: () => void
}

export const UserPermissionFlowSelect = ({ onSelect, onBack }: Props) => {

    //translate:
    const { __ } = useTranslate()

    //theme:
    const { token: {
        fontSizeLG,
        fontSizeSM,
        colorBgSpotlight,
        margin,
        paddingXS,
        paddingSM,
        paddingLG,
        colorText
    }} = theme.useToken()

    //state:
    const [ search, setSearch ] = useState(null as string | null)

    //flows:
    const {
        data: flows,
        pageNum,
        totalPage,
        isLoading,
        isPreviousData,
        isNextDisabled,
        isPreviousDisabled,
        fetchNext,
        fetchPrevious
    } = useFlows(search, 15)

    //render:
    return (
        <VerticalContainer style={{ height: '100%', paddingBottom: paddingXS, gap: margin }}>

            <VerticalContainer>

                {/* header */}
                <UserPermissionHeader
                    title={__`flow`}
                    onBack={onBack}
                >

                    {/* search */}
                    <Search
                        placeholder={__`search_flows`}
                        onSearch={setSearch}
                    />

                </UserPermissionHeader>

            </VerticalContainer>

            <VerticalPaginatedList
                isFirstLoading={isLoading}
                isForbidden={flows === null}
                isError={flows === false}
                notFound={(flows && flows.length === 0) ?? false}
                pageNum={pageNum}
                totalPage={totalPage}
                isPreviousData={isPreviousData}
                isNextDisabled={isNextDisabled}
                isPreviousDisabled={isPreviousDisabled}
                scrollable={true}
                fetchNext={fetchNext}
                fetchPrevious={fetchPrevious}
            >
                
                {flows && flows.map(flow =>
                    <Avatar
                        key={flow.id}
                        name={flow.name}
                        image={null}
                        placeholder={<div style={{ fontSize: fontSizeLG, color: colorBgSpotlight }}>{getFlowIcon(flow.trigger)}</div>}
                        description={__(flow.trigger.toLowerCase())}
                        color={colorText}
                        size='small'
                        backgroundColor='transparent'
                        extraIcon={<RightOutlined style={{ fontSize: fontSizeSM }}/>}
                        style={{ width: '100%', paddingLeft: paddingSM, paddingRight: paddingLG, borderRadius: 0 }}
                        onClick={() => onSelect(flow.id)}
                    />
                )}

            </VerticalPaginatedList>

        </VerticalContainer>
    )

}
