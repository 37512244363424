import styled from 'styled-components'

export const MainWrapper = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
`

export const ProgressBarContainer = styled.div`
    width: 100%;
    padding: ${props => `0 ${props.theme.padding}px`};
    margin-top: ${props => `${props.theme.marginSM}px`};
`
