import { PageHeader } from 'components/page-header/page-header'
import { PlusOutlined } from '@ant-design/icons'
import { FlowItem } from 'components/flow/flow-item/flow-item'
import { isNil, useTranslate, useWindowDimensions } from 'utils'
import { useConfig, useCurrentShop, useFlows } from 'api'
import { Config, Feature } from 'types'
import { useNavigate } from 'react-router-dom'
import { VerticalPaginatedList } from 'components/vertical-paginated-list/vertical-paginated-list'
import { useEffect, useState } from 'react'
import { theme } from 'antd'
import { FormSection } from 'components/form-section/form-section'
import { PageContentWrapper, PageError, VerticalContainer } from 'components/styled'

export const FlowList = () => {

    //hooks:
    const { token: { padding, paddingMD, marginXXS, margin } } = theme.useToken()
    const { SM, LG } = useWindowDimensions()
    const { __ } = useTranslate()
    const { data: config } = useConfig()
    const { data: shop } = useCurrentShop()
    const navigate = useNavigate()

    //state:
    const [ search, setSearch ] = useState(null as string | null)
    
    //flows:
    const {
        data: flows,
        total,
        pageNum,
        totalPage,
        isLoading,
        isPreviousData,
        isNextDisabled,
        isPreviousDisabled,
        fetchNext,
        fetchPrevious
    } = useFlows(search, 15)

    // redirect if it doesn't have access to flow:
    useEffect(() => {
        if(config){
            if(!config.features.includes(Feature.FLOWS)){
                navigate('/')
            }
        }
    }, [config])

    //render:
    return (!config || !shop) ? <PageError/> : (
        <PageContentWrapper>

            <VerticalContainer style={{ gap: SM ? margin : marginXXS }}>

                {/* header */}
                <PageHeader
                    title={__`flows`}
                    description={__`manage_all_your_flows`}
                    primaryButton={{
                        name: LG ? __`create_flow` : __`create`,
                        icon: <PlusOutlined/>,
                        mode: canCreateFlow(config, !isNil(flows), total) ? 'normal' : 'disabled',
                        onClick: () => navigate('/flow/presets')
                    }}
                />

                <div style={{ padding: SM ? `0 ${padding}px` : 0 }}>
                
                    <FormSection
                        title={SM ? __`flows` : undefined }
                        childrenWrapperStyle={{ padding: SM ? `0 ${paddingMD}px` : 0 }}
                        searchInput={{
                            placeholder: __`search_flows`,
                            onSearch: setSearch
                        }}
                    >

                        {/* list */}
                        <VerticalPaginatedList
                            isFirstLoading={isLoading}
                            isForbidden={flows === null}
                            isError={flows === false}
                            notFound={(flows && flows.length === 0) ?? false}
                            totalPage={totalPage}
                            pageNum={pageNum}
                            isPreviousData={isPreviousData}
                            isNextDisabled={isNextDisabled}
                            isPreviousDisabled={isPreviousDisabled}
                            fetchNext={fetchNext}
                            fetchPrevious={fetchPrevious}
                        >
                            {flows && flows.map(flow => <FlowItem key={flow.id} flow={flow}/>)}
                        </VerticalPaginatedList>

                    </FormSection>

                </div>

            </VerticalContainer>

        </PageContentWrapper>
    )
    
}

//canCreateFlow:
export const canCreateFlow = (config: Config, dataLoaded: boolean, total: number) => {
    if(dataLoaded){
        if(config.plan?.maxFlows && total < config.plan.maxFlows){
            return true
        }
    }
    return false
}
