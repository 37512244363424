import { theme } from 'antd'
import { PageHeader } from 'components/page-header/page-header'
import { CloudUploadOutlined } from '@ant-design/icons'
import { HorizontalContainer, PageContentWrapper, VerticalContainer } from 'components/styled'
import { Form } from 'react-final-form'
import { useTranslate, useWindowDimensions } from 'utils'
import { FormZone } from 'components/form-zone/form-zone'
import { Flow, FlowData } from 'types'
import { FlowConfiguration } from 'components/flow/flow-configuration/flow-configuration'
import { FlowVariables } from 'components/flow/flow-variables/flow-variables'
import { FlowRemove } from 'components/flow/flow-remove/flow-remove'
import { FlowStatus } from 'components/flow/flow-status/flow-status'
import { FlowTemplate } from 'components/flow/flow-template/flow-template'

type Props = {
    initialValues: FlowData
    isCreate: boolean
    isSaving: boolean
    onBodyExtensionChanged: (index: number) => void
    onValidate: (values: Flow) => object | undefined
    onSave: (values: Flow) => void
    onBack: () => void
}

export const FlowForm = ({
    initialValues,
    isCreate,
    isSaving,
    onBodyExtensionChanged,
    onValidate,
    onSave,
    onBack
}: Props) => {
    
    //hooks:
    const { token: { padding, margin, marginLG, marginXL } } = theme.useToken()
    const { SM, XL } = useWindowDimensions()
    const { __ } = useTranslate()
    
    return (
        <PageContentWrapper>
            
            <Form
                onSubmit={onSave}
                subscription={{ dirty: true }}
                validate={onValidate}
                initialValues={initialValues}
                render={({ form, dirty, handleSubmit }) => (

                    <VerticalContainer style={{ gap: margin }}>

                        {/* header */}
                        <PageHeader
                            title={initialValues.name !== '' ? initialValues.name : __`create_flow`}
                            description={__`build_your_own_flow_as_you_want_the_sky_is_the_limit`}
                            primaryButton={{
                                name: isCreate ? __`create` : __`save`,
                                icon: <CloudUploadOutlined/>,
                                mode: (isSaving ? 'loading' : (dirty || isCreate) ? 'normal': 'disabled'),
                                onClick: handleSubmit
                            }}
                            secondaryButton={dirty ? {
                                name: __`discard`,
                                mode: isSaving ? 'disabled' : 'normal',
                                onClick: () => form.reset()
                            } : undefined}
                            backButton={{
                                name: __`back`,
                                onClick: onBack
                            }}
                            isFormDirty={dirty}
                        />

                        <VerticalContainer style={{ padding: SM ? `0 ${padding}px`: 0, gap: SM ? marginXL : marginLG }}>
                            
                            {/* configuration */}
                            <FormZone
                                title={__`flow_configuration`}
                                description={__`configure_when_and_how_this_flow_should_be_triggered`}
                                divider={false}
                            >
                                <VerticalContainer style={{ gap: margin }}>
                                    <FlowStatus/>
                                    <FlowConfiguration/>
                                </VerticalContainer>
                            </FormZone>

                            {/* template */}
                            <FormZone
                                title={__`flow_template`}
                                description={__`manage_your_flow_email_api_template`}
                                forceVertical
                            >

                                <HorizontalContainer style={{
                                    flexDirection: XL ? 'row' : 'column',
                                    alignItems: 'stretch',
                                    gap: margin
                                }}>
                                    <div style={{ width: XL ? `calc(100% - 300px - ${margin}px)` : '100%' }}>
                                        <FlowTemplate onBodyExtensionChanged={onBodyExtensionChanged}/>
                                    </div>
                                    <div style={{ width: XL ? 300 : '100%' }}>
                                        <FlowVariables/>
                                    </div>
                                </HorizontalContainer>
                                
                            </FormZone>

                            {/* remove */}
                            {!isCreate &&
                                <FormZone
                                    title={__`remove_flow`}
                                    description={__`remove_this_flow_and_all_its_settings`}
                                    forceVertical
                                >
                                    <FlowRemove/>
                                </FormZone>
                            }

                        </VerticalContainer>
                    
                    </VerticalContainer>
                    
                )
            }/>
            
        </PageContentWrapper>
    )

}
