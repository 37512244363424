import styled from 'styled-components'

export const MainWrapper = styled.div`
    width: 100%;
    min-height: 50px;
    position: relative;
`

export const LoadingContainer = styled.div`
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
`
