import { PropsWithChildren } from 'react'
import { Typography } from 'antd'
import { InputError, VerticalContainer } from 'components/styled'
import { isNil, objectAssign } from 'utils'
const { Text, Paragraph } = Typography

export const FormInputWrapper = ({ children, meta, ...rest }: PropsWithChildren<any>) => (
    <VerticalContainer>
        {rest.title && (
            <Text style={objectAssign({ fontFamily: 'inter-medium', paddingLeft: 1 }, rest.titleStyle)}>
                {rest.title}
            </Text>
        )}
        {children}
        {(!isNil(meta.error) && meta.touched) ? <InputError style={rest.errorStyle} error={meta.error}/> :
        !isNil(rest.description) ? <Paragraph type='secondary' style={{ margin: 0, paddingLeft: 1 }}>{rest.description}</Paragraph> :
        null}
    </VerticalContainer>
)
