import { useEffect, useState } from 'react'
import { setIn } from 'final-form'
import { CloudUploadOutlined } from '@ant-design/icons'
import { PageContentWrapper, VerticalContainer } from 'components/styled'
import { queryClient, useTranslate, useWindowDimensions } from 'utils'
import { Field, Form } from 'react-final-form'
import { message, theme } from 'antd'
import { PageHeader } from 'components/page-header/page-header'
import { useNavigate } from 'react-router-dom'
import { useConfig, useCreateToken } from 'api'
import { Feature, PersonalAccessToken, PersonalAccessTokenFormSchema } from 'types'
import { UserPermissionList } from '../../../components/user/user-permission-list/user-permission-list'
import { FormSection } from '../../../components/form-section/form-section'
import { FormInput } from '../../../components/form-inputs'
import { TokenModal } from '../token-modal/token-modal'

export const TokenForm = () => {

    //hooks:
    const { token: { padding, margin, marginLG } } = theme.useToken()
    const { SM } = useWindowDimensions()
    const { __ } = useTranslate()
    const navigate = useNavigate()

    //state:
    const [ initialValues ] = useState(defaultValues)
    const [ savingValues, setSavingValues ] = useState(defaultValues)
    const [ clientId, setClientId] = useState(null as string | null)
    const [ token, setToken] = useState(null as string | null)
    const [ isModalOpen, setIsModalOpen] = useState(false)
    const { data: config } = useConfig()

    // redirect if it doesn't have access to token:
    useEffect(() => {
        if(config){
            if(!config.features.includes(Feature.PERSONAL_ACCESS_TOKEN)){
                navigate('/')
            }
        }
    }, [config])

    //mutation:
    const { mutate: createToken, isLoading: isCreateLoading, data: createResponse } = useCreateToken(savingValues)

    const create = (values: PersonalAccessToken) => {
        setSavingValues(values)
        createToken()
    }

    //after create:
    useEffect(() => {
        if(createResponse){
            if(createResponse === 'existingName'){
                message.error(__`the_name_is_used_before`)
            }else if(createResponse === 'exceedMaxToken'){
                message.error(__`you_exceeded_the_max_token_limit`)
            }else{
                queryClient.invalidateQueries(['tokens'])
                setClientId(createResponse.clientId)
                setToken(createResponse.token)
                setIsModalOpen(true)
            }
        }
    }, [isCreateLoading])

    return (
        <PageContentWrapper>

            <Form
                subscription={{ dirty: true }}
                initialValues={initialValues}
                onSubmit={(values: PersonalAccessToken) => (
                    PersonalAccessTokenFormSchema(__).validate(values).then(
                        data => create(data as PersonalAccessToken)
                    )
                )}
                validate={validateFormValues(PersonalAccessTokenFormSchema(__))}
                render={({ form, dirty, handleSubmit }) => (

                    <VerticalContainer style={{ gap: margin }}>

                        {/* header */}
                        <PageHeader
                            title={__`create_token`}
                            description={__`create_new_personal_access_token`}
                            primaryButton={{
                                name: __`create`,
                                icon: <CloudUploadOutlined/>,
                                mode: isCreateLoading ? 'loading' : dirty ? 'normal': 'disabled',
                                onClick: handleSubmit
                            }}
                            secondaryButton={dirty ? {
                                name: __`discard`,
                                mode: isCreateLoading ? 'disabled' : 'normal',
                                onClick: () => form.reset()
                            } : undefined}
                            backButton={{
                                name: __`back`,
                                onClick: () => navigate('/token')
                            }}
                            isFormDirty={dirty}
                        />

                        <VerticalContainer style={{ padding: SM ? `0 ${padding}px` : 0, gap: marginLG }}>

                            {/* info */}
                            <FormSection title={__`info`}>

                                {/* name */}
                                <Field
                                    name="name"
                                    title={__`name`}
                                    placeholder={__`mobile_app_token`}
                                    description={__`the_name_of_the_token`}
                                    component={FormInput}
                                />

                            </FormSection>
                            
                            {/* permission list */}
                            <UserPermissionList/>

                        </VerticalContainer>

                    </VerticalContainer>
                )}
            />

            {/* modal */}
            {(clientId && token) && (
                <TokenModal
                    clientId={clientId}
                    token={token}
                    isModalOpen={isModalOpen}
                />
            )}

        </PageContentWrapper>
    )
    
}

//default values:
const defaultValues: PersonalAccessToken = {
    id: '',
    name: '',
    permissions: [],
    clientId: '',
    expireAt: 'NEVER'
}

//validateFormValues:
const validateFormValues = (schema: any) => async (values: PersonalAccessToken) => {
    if(typeof schema === 'function')
        schema = schema()
    try{
        await schema.validate(values, { abortEarly: false })
    }catch(err: any){
        const errors = err.inner.reduce((formError: object, innerError: any) => {
            return setIn(formError, innerError.path, innerError.message)
        }, {})
        return errors
    }
}
