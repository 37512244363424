import { useEffect, useRef, useState } from 'react'

export const useElementScrollBreak = (elementId: string, scrollBreakpoint: number) => {

    const lastEventTimeoutId = useRef(0)

    const calcScrollBreak = () => {
        const element = document.getElementById(elementId)
        const scrollToTop = element?.scrollTop ?? 0
        return scrollToTop > scrollBreakpoint
    }
    
    const [ isScrollBreak, setIsScrollBreak ] = useState(calcScrollBreak())

    useEffect(() => {

        const handleEvent = () => {
            clearTimeout(lastEventTimeoutId.current)
            const timeoutId = setTimeout(() => setIsScrollBreak(calcScrollBreak()), 10)
            lastEventTimeoutId.current = timeoutId as unknown as number
        }

        //listener:
        const element = document.getElementById(elementId)
        element?.addEventListener('scroll', handleEvent)

        return () => element?.removeEventListener('scroll', handleEvent)

    }, [])

    return { isScrollBreak }

}
