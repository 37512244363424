import styled from 'styled-components'
import styledContainerQuery from 'styled-container-query'

export const SelectWrapper = styled.div`
    width: 100%;
    min-width: 120px;
`

export const DateWrapper = styled.div`
    width: 100%;
    max-width: 180px;
`

export const MenuWrapper = styledContainerQuery.div`
    flex: 1;
    display: flex;
    gap: ${(props: any) => `${props.theme.marginXXS}px`};
    &:container(max-width: 380px){
        flex-direction: column;
        ${SelectWrapper}{
            min-width: unset;
        }
        ${DateWrapper}{
            max-width: unset;
        }
    }
`

export const QueryWrapper = styled.div`
    display: flex;
    gap: ${props => `${props.theme.marginXXS}px`};
`

export const PickerWrapper = styledContainerQuery.div`
    flex: 1;
    max-width: 260px;
`

export const StartEndWrapper = styledContainerQuery.div`
    display: flex;
    gap: ${(props: any) => `${props.theme.marginXXS}px`};
    &:container(max-width: 250px){
        flex-direction: column;
    }
`

export const SearchWrapper = styled.div`
    width: 100%;
    max-width: 250px;
`

export const MainWrapper = styledContainerQuery.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: ${(props: any) => `${props.theme.marginXS}px`};
    gap: ${(props: any) => `${props.theme.marginXXS}px`};
    &:container(max-width: 650px){
        flex-direction: column;
        ${MenuWrapper}{
            width: 100%;
            ${PickerWrapper}{
                max-width: unset;
            }
        }
        ${SearchWrapper}{
            max-width: unset;
        }
    }
`
