import styled from 'styled-components'
import styledContainerQuery from 'styled-container-query'

export const Container = styled.div`
    display: flex;
    gap: ${props => `${props.theme.marginXS}px`};
`

export const LabelsWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: ${props => props.theme.marginSM + 'px'};
`

export const ButtonsWrapper = styled.div`
    padding-right: ${props => `${props.theme.paddingXS}px` };
    display: flex;
    gap: ${props => `${props.theme.marginXXS}px`};
`

export const DropdownWrapper = styled.div`
    display: none;
`

export const MainWrapper = styledContainerQuery.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: ${(props: any) => `${props.theme.marginXS}px`};
    &:container(max-width: 600px){
        ${ButtonsWrapper}{
            display: none;
        }
        ${DropdownWrapper}{
            display: block;
        }
    }
`
