import { useEffect } from 'react'
import { getShopId, queryClient, useTranslate } from 'utils'
import { useField } from 'react-final-form'
import { FormRemove } from 'components/form-remove/form-remove'
import { useNavigate } from 'react-router-dom'
import { useRemoveTeamMember } from 'api'
import { TeamMember } from 'types'
import { message } from 'antd'

type Props = {
    savedValues: TeamMember
}

export const TeamMemberRemove = ({ savedValues }: Props) => {

    //translate:
    const { __ } = useTranslate()

    //navigation:
    const navigate = useNavigate()

    //field:
    const { input: { value: id } } = useField('id')
    const { input: { value: name } } = useField('name')

    //mutation:
    const { mutate, isLoading, isSuccess } = useRemoveTeamMember(id)

    //after remove:
    useEffect(() => {
        if(isSuccess){
            queryClient.invalidateQueries(['teamMembers', getShopId()])
            message.success(__`team_member_removed`)
            navigate('/team')
        }
    }, [isSuccess])
    
    //render:
    return (
        <FormRemove
            title={`${__`remove`} "${name}"`}
            message={__`all_settings_will_be_deleted_this_action_cant_be_undone`}
            description={__`the_team_member_should_not_be_assigned_to_any_services`}
            isLoading={isLoading}
            isDisabled={savedValues.services.length > 0}
            onRemove={mutate}
        />
    )

}
