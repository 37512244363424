import { canCreateTeamMember } from '../team-members-list/team-members-list'
import { useEffect, useState } from 'react'
import { setIn } from 'final-form'
import { useConfig, useCreateTeamMember, useTeamMember, useTeamMembers, useUpdateTeamMember } from 'api'
import { TeamMember, TeamMemberFormSchema } from 'types'
import { Loading, PageContentWrapper, PageError, RWContainer, RWElement, VerticalContainer } from 'components/styled'
import { CloudUploadOutlined } from '@ant-design/icons'
import { getShopId, queryClient, useTranslate, useWindowDimensions } from 'utils'
import { useNavigate, useParams } from 'react-router-dom'
import { FormSection } from 'components/form-section/form-section'
import { TeamMemberProfile } from 'components/team-member/team-member-profile/team-member-profile'
import { TeamMemberAvailabilities } from 'components/team-member/team-member-availabilities/team-member-availabilities'
import { TeamMemberServiceList } from 'components/team-member/team-member-service-list/team-member-service-list'
import { Tabs, Typography, message, theme } from 'antd'
import { TeamMemberStatus } from 'components/team-member/team-member-status/team-member-status'
import { TeamMemberNotification } from 'components/team-member/team-member-notification/team-member-notification'
import { TeamMemberGoogleSync } from 'components/team-member/team-member-google-sync/team-member-google-sync'
import { TeamMemberRemove } from 'components/team-member/team-member-remove/team-member-remove'
import { PageHeader } from 'components/page-header/page-header'
import { Form } from 'react-final-form'
import { ImageUpload } from 'components/image-upload/image-upload'
const { Text } = Typography

//form:
export const TeamMemberForm = () => {
    const params = useParams()
    return params.id ? <EditTeamMember id={params.id}/> : <CreateTeamMember/>
}

//create team member:
const CreateTeamMember = () => {

    //hooks:
    const { data: config, isLoading: isConfigLoading } = useConfig()
    const navigate = useNavigate()

    //api:
    const { data: teamMembers, total, isLoading: isTeamMembersLoading } = useTeamMembers(null, 15)

    //redirect if reached max limit:
    useEffect(() => {
        if(config && teamMembers){
            if(!canCreateTeamMember(config, true, total)){
                navigate('/team')
            }
        }
    }, [config, teamMembers])

    //render:
    return (
        (isConfigLoading || isTeamMembersLoading) ? <Loading/> :
        (!config || !teamMembers) ? <PageError/> :
        <TeamMemberFormController teamMember={null}/>
    )

}

//load for edit:
type EditTeamMemberParams = { id: string }
const EditTeamMember = ({ id }: EditTeamMemberParams) => {

    //hooks:
    const navigate = useNavigate()
    const { data: teamMember, isLoading } = useTeamMember(id, () => navigate('/team'))

    //redirect if not found:
    useEffect(() => {
        if(teamMember === null || teamMember === false){
            navigate('/team')
        }
    }, [isLoading])

    //render:
    return isLoading ? <Loading/> : !teamMember ? <PageError/> : <TeamMemberFormController teamMember={teamMember}/>

}

//form controller:
type TeamMemberControllerProps = { teamMember: TeamMember | null }
export const TeamMemberFormController = ({ teamMember }: TeamMemberControllerProps) => {

    const isCreate = !teamMember

    //hooks:
    const { token: { marginXS, margin, padding, paddingLG } } = theme.useToken()
    const { SM } = useWindowDimensions()
    const { __ } = useTranslate()
    const navigate = useNavigate()

    //state:
    const [ initialValues, setInitialValues ] = useState(teamMember ?? defaultValues)
    const [ savingValues, setSavingValues ] = useState(initialValues)

    useEffect(() => {
        if(teamMember){
            setInitialValues({
                ...initialValues,
                calendarSync: teamMember.calendarSync //exception
            })
        }
    }, [teamMember])

    //mutation:
    const { mutate: createTeamMember, isLoading: isCreateLoading, data: createResponse } = useCreateTeamMember(savingValues)
    const { mutate: updateTeamMember, isLoading: isUpdateLoading, data: updateResponse } = useUpdateTeamMember(savingValues)

    //create:
    const create = (values: TeamMember) => {
        setSavingValues(values)
        createTeamMember()
    }
    const update = (values: TeamMember) => {
        setSavingValues(values)
        updateTeamMember()
    }

    //after create:
    useEffect(() => {
        if(createResponse){
            queryClient.invalidateQueries(['teamMembers', getShopId()])
            message.success(__`team_member_created`)
            navigate(`/team`)
        }
    }, [isCreateLoading])

    //after update:
    useEffect(() => {
        if(updateResponse){
            setInitialValues({
                ...savingValues,
                calendarSync: updateResponse
            })
            queryClient.invalidateQueries(['teamMember', savingValues.id])
            queryClient.invalidateQueries(['teamMembers', getShopId()])
            queryClient.invalidateQueries(['services', getShopId()])
            initialValues.services.map(service => {
                queryClient.resetQueries(['service', service.id])
            })
            savingValues.services.map(service => {
                queryClient.resetQueries(['service', service.id])
            })
            message.success(__`team_member_updated`)
        }
    }, [isUpdateLoading])

    //render:
    return (
        <PageContentWrapper>
            <Form
                initialValues={initialValues}
                subscription={{ dirty: true }}
                validate={validateFormValues(TeamMemberFormSchema(__))}
                onSubmit={values =>
                    TeamMemberFormSchema(__).validate(values).then(
                        data => isCreate ? create(data as TeamMember) : update(data as TeamMember)
                    )
                }
                render={({ form, dirty, handleSubmit }) => (
                    
                    <VerticalContainer style={{ gap: margin }}>

                        {/* header */}
                        <PageHeader
                            title={isCreate ? __`create_team_member` : initialValues.name}
                            description={isCreate ? __`create_new_team_member_and_determine_availability` : __`manage_your_team_member`}
                            primaryButton={{
                                name: isCreate ? __`create` : __`save`,
                                icon: <CloudUploadOutlined/>,
                                mode: (isCreateLoading || isUpdateLoading) ? 'loading' : dirty ? 'normal': 'disabled',
                                onClick: handleSubmit
                            }}
                            secondaryButton={dirty ? {
                                name: __`discard`,
                                mode: (isCreateLoading || isUpdateLoading) ? 'disabled' : 'normal',
                                onClick: () => form.reset()
                            } : undefined}
                            backButton={{
                                name: __`back`,
                                onClick: () => navigate('/team')
                            }}
                            isFormDirty={dirty}
                        />

                        <Tabs
                            defaultActiveKey="basics"
                            style={{ padding: SM ? `0 ${paddingLG}px` : `0 ${padding}px` }}
                            items={[
                                {
                                    key: 'basics',
                                    label: __`basics`,
                                    children: (
                                        <div style={{ margin: SM ? `0 ${-marginXS}px` : `0 ${-margin}px` }}>
                                            <BasicContents/>
                                        </div>
                                    )
                                },
                                {
                                    key: 'advanced',
                                    label: __`advanced`,
                                    children: (
                                        <div style={{ margin: SM ? `0 ${-marginXS}px` : `0 ${-margin}px` }}>
                                            <AdvancedContents isCreate={isCreate} dirty={dirty} savedValues={initialValues}/>
                                        </div>
                                    )
                                }
                            ]}
                        />

                    </VerticalContainer>
                )}
            />
            
        </PageContentWrapper>
    )
    
}

//defaultValues:
const defaultValues: TeamMember = {
    id: '',
    name: '',
    email: null,
    mobile: null,
    description: null,
    image: {
        id: null,
        path: null
    },
    status: true,
    notificationEmailStatus: false,
    eventDescription: null,
    calendarSync: {
        canUseCalendarSync: true,
        calendarSyncStatus: null
    },
    availabilities: [],
    services: []
}

//validateFormValues:
const validateFormValues = (schema: any) => async (values: TeamMember) => {
    if(typeof schema === 'function')
        schema = schema()
    try{
        await schema.validate(values, { abortEarly: false })
    }catch(err: any){
        const errors = err.inner.reduce((formError: object, innerError: any) => {
            return setIn(formError, innerError.path, innerError.message)
        }, {})
        return errors
    }
}

//BasicContents:
const BasicContents = () => {

    //hooks:
    const { __ } = useTranslate()
    const { token: { paddingXS, marginXS, marginLG, marginXL } } = theme.useToken()
    const { SM } = useWindowDimensions()

    return (
        <VerticalContainer style={{ paddingTop: paddingXS, gap: marginXL }}>
            
            {/* profile & image */}
            <FormSection style={{ minHeight: 0, paddingBottom: SM ? marginLG : 0 }}>

                <RWContainer style={{ alignItems: 'normal', gap: marginLG }}>

                    {/* info */}
                    <RWElement breakPoint={300}>
                        <TeamMemberProfile/>
                    </RWElement>

                    {/* image */}
                    <RWElement breakPoint={300}>

                        <VerticalContainer style={{ height: '100%', gap: marginXS }}>

                            <Text style={{ fontFamily: 'inter-medium' }}>
                                {__`image`}
                            </Text>

                            <ImageUpload
                                type='team-member'
                                nameField='name'
                                imageField='image'
                            />

                        </VerticalContainer>

                    </RWElement>

                </RWContainer>

            </FormSection>

            {/* availabilities */}
            <TeamMemberAvailabilities/>

            {/* service list */}
            <TeamMemberServiceList/>
                
        </VerticalContainer>
    )
}

//AdvancedContents:
type AdvancedContentsProps = {
    isCreate: boolean,
    dirty: boolean,
    savedValues: TeamMember
}
const AdvancedContents = ({ isCreate, dirty, savedValues }: AdvancedContentsProps) => {

    //theme:
    const { token: { marginXL, paddingXS } } = theme.useToken()

    //render:
    return (
        <VerticalContainer style={{ paddingTop: paddingXS, gap: marginXL }}>

            {/* status */}
            <TeamMemberStatus/>
            
            {/* notification */}
            <TeamMemberNotification/>

            {/* google sync */}
            <TeamMemberGoogleSync dirty={dirty}/>

            {/* remove */}
            {!isCreate && <TeamMemberRemove savedValues={savedValues}/>}

        </VerticalContainer>
    )
}
