import { routes } from 'data'
import { useRoutes } from 'react-router-dom'

export const App = () => {

    //router:
    const router = useRoutes(routes)
    
    //render:
    return <>{router}</>
    
}
