import styled from 'styled-components'

type MainWrapperProps = {
    index: number
    isAvailable: boolean
}

export const MainWrapper = styled.div<MainWrapperProps>`
    width: 100%;
    height: 120px;
    background-color: ${props => props.isAvailable ? 'transparent' : props.theme.colorFillQuaternary};
    border-bottom: solid 1px ${props => props.theme.colorBorderSecondary};
    border-right: solid 1px ${props => props.theme.colorBorderSecondary};
    border-left: ${props => props.index === 0 ? `solid 1px ${props.theme.colorBorderSecondary}` : 'none'};
    display: flex;
    justify-content: center;
    position: relative;
`

export const ContentWrapper = styled.div`
    width: 100%;
    height: 100%;
    align-items: flex-start;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    gap: ${props => `${props.theme.marginXXS}px`};
`
