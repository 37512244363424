import { ExtensionItem } from '../app-extension-item/app-extension-item'
import { AppExtensionForm } from '../app-extension-form/app-extension-form'
import { message, Modal, theme } from 'antd'
import { FormSection } from 'components/form-section/form-section'
import { NotFound, VerticalContainer } from 'components/styled'
import { useField } from 'react-final-form'
import { AppExtension } from 'types'
import { PlusOutlined } from '@ant-design/icons'
import { useTranslate, useWindowDimensions } from 'utils'
import { useState } from 'react'

export const AppExtensionsList = () => {

    //hooks:
    const { __ } = useTranslate()
    const { LG } = useWindowDimensions()
    const { token: { fontSizeSM, marginSM, colorTextDescription } } = theme.useToken()
    const { input: { value: extensions, onChange: updateExtensions } } = useField('extensions')

    //state:
    const [ isModalOpen, setIsModalOpen] = useState(false)

    return (
        <FormSection
            title={__`extensions`}
            topButton={{
                label: __`add_extension`,
                icon: <PlusOutlined/>,
                onClick: () => setIsModalOpen(true)
            }}
        >

                {/* list */}
                {extensions.length === 0 ? <NotFound message='No extensions'/> : (

                    <VerticalContainer>

                        {LG && (
                            <ExtensionItem
                                name={__`name`}
                                target={__`target`}
                                url={__`url`}
                                style={{
                                    fontSize: fontSizeSM,
                                    color: colorTextDescription
                                }}
                            />
                        )}

                        <VerticalContainer style={{ gap: LG ? 0 : marginSM }}>

                            {extensions.map((extension: AppExtension, i: number) => (

                                <ExtensionItem
                                    key={`${extension.name}-${extension.target}-${extension.url}`}
                                    name={extension.name}
                                    target={extension.target}
                                    url={extension.url}
                                    onDelete={() => {
                                        const newExtensions = [...extensions]
                                        newExtensions.splice(i, 1)
                                        updateExtensions([...newExtensions])
                                    }}
                                />

                            ))}

                        </VerticalContainer>

                    </VerticalContainer>

                )}

            {/* modal */}
            <Modal
                title={__`add_extension`}
                open={isModalOpen}
                closeIcon={null}
                footer={null}
                destroyOnClose
                onCancel={() => setIsModalOpen(false)}
            >
                
                <AppExtensionForm
                    onAdd={extension => {
                        if((extensions.filter((ex: AppExtension) => ex.target === extension.target)).length === 0){
                            updateExtensions([...extensions, extension])
                        }else{
                            message.error(__`you_can_register_just_one_extension_for_each_target`)
                        }
                        setIsModalOpen(false)
                    }}
                    onCancel={() => setIsModalOpen(false)}
                />

            </Modal>

        </FormSection>
    )

}
