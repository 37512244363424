import { FlexSpacer, MainContainer, MainWrapper } from '../appointment-details/appointment-details.style'
import { AppointmentDetailNavigation } from '../appointment-details-navigation/appointment-details-navigation'
import { AppointmentDetailsHeader } from '../appointment-details-header/appointment-details-header'
import { AppointmentDetailsTeamMember } from '../appointment-details-team-member/appointment-details-team-member'
import { AppointmentDetailsCustomer } from '../appointment-details-customer/appointment-details-customer'
import { useConfig } from 'api'
import { useTranslate, useWindowDimensions } from 'utils'
import { theme } from 'antd'
import { ColumnContainer } from 'components/styled'
import { getDateTimeRange } from 'components/calendar/slot-details/slot-details'
import { AppointmentTeamMember, CalendarSlot } from 'types'

type Props = {
    slot: CalendarSlot
    teamMember: AppointmentTeamMember
    onClose: () => void
}

export const ReservationDetails = ({
    slot,
    teamMember,
    onClose
}: Props) => {

    //hooks:
    const { token: { paddingXXS, paddingXS, marginXXS, margin, marginMD, }} = theme.useToken()
    const { SM } = useWindowDimensions()
    const { __ } = useTranslate()
    const { data: config, isLoading: isConfigLoading } = useConfig()

    //render:
    return (isConfigLoading || !config) ? null : (

        <MainWrapper style={{ paddingBottom: SM ? 0 : `${paddingXXS}px` }}>

            <MainContainer>

                <div style={{ padding: `0 ${SM ? paddingXS : 0}px` }}>

                    {/* navigation */}
                    <AppointmentDetailNavigation onClose={onClose}/>

                    {/* header */}
                    <ColumnContainer marginTop={marginXXS}>
                        <AppointmentDetailsHeader
                            title={slot.service?.title ?? __`deleted_service`}
                            description={getDateTimeRange(slot.dateTime, slot.duration, config)}
                            tag={slot.duration + ' ' + __`min`}
                        />
                    </ColumnContainer>

                    {/* customer */}
                    <ColumnContainer marginTop={marginMD}>
                        <AppointmentDetailsCustomer customer={null} isReserved={true}/>
                    </ColumnContainer>

                </div>

                {/* team member */}
                <div style={{ padding: `0 ${SM ? paddingXS : 0}px` }}>
                    <ColumnContainer marginTop={margin}>
                        <AppointmentDetailsTeamMember teamMember={teamMember}/>
                    </ColumnContainer>
                </div>

                {/* spacer to force the events button to move to the bottom if there is space */}
                <FlexSpacer/>
            
            </MainContainer>

        </MainWrapper>
    )

}
