import styled from 'styled-components'

export const MainWrapper = styled.div`
    width: 100%;
    padding: ${props => `0 ${props.theme.padding}px`};
`

export const MainContainer = styled.div`
    width: 100%;
    padding: ${props => `${props.theme.marginXS}px`};
    background-color: ${props => `${props.theme.colorBgLayout}`};
    border: solid 1px ${props => `${props.theme.colorBorderSecondary}`};
    border-radius: ${props => `${props.theme.borderRadiusLG}px`};
    display: flex;
    justify-content: space-between;
`

export const InfoWrapper = styled.div`
    padding: ${props => `
        ${props.theme.marginXS}px
        0
        ${props.theme.marginXS}px
        ${props.theme.marginXS}px
    `};
    display: flex;
    flex-direction: column;
    gap: ${props => `${props.theme.marginXXS}px`}
`
