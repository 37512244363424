import styled from 'styled-components'

export const MainWrapper = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: ${props => props.theme.marginLG + 'px'};
`

export const ContentWrapper = styled.div`
    width: 100%;
    padding: ${props => `0 ${props.theme.padding}px`};
`
