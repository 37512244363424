import styled from 'styled-components'
import { SLOT_DETAILS_EVENTS_Z_INDEX } from 'z-indexes'

export const MainWrapper = styled.div`
    width: 100%;
    background-color: ${props => props.theme.colorBgContainer};
    border-top-left-radius: ${props => `${props.theme.borderRadiusLG}px`};
    border-top-right-radius: ${props => `${props.theme.borderRadiusLG}px`};
    display: flex;
    flex-direction: column;
    position: absolute;
    bottom: 0;
    left: 0;
    overflow-y: auto;
    z-index: ${SLOT_DETAILS_EVENTS_Z_INDEX};
    transition: all 300ms ease-in-out;
`

export const TimelineContainer = styled.div`
    width: 100%;
    flex: 1;
    padding: ${props => `
        ${props.theme.paddingSM}px
        ${props.theme.paddingMD}px
        ${props.theme.padding}px
        ${props.theme.paddingMD}px
    `};
    background-color: ${props => props.theme.colorBgContainer};
    overflow-y: auto;
`

export const EventChildrenWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: ${props => `${props.theme.marginXXS}px`};
`

export const EventTitleContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 4px;
`
