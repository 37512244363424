import { Button, Dropdown, MenuProps, message, theme } from 'antd'
import { PageHeader } from 'components/page-header/page-header'
import { MoreOutlined, PlusOutlined } from '@ant-design/icons'
import { PageContentWrapper, VerticalContainer } from 'components/styled'
import { queryClient, useTranslate, useWindowDimensions } from 'utils'
import { useConfig, useRemoveToken, useTokens } from 'api'
import { useNavigate } from 'react-router-dom'
import { Feature, PersonalAccessToken } from 'types'
import { Avatar } from 'components/avatar/avatar'
import { VerticalPaginatedList } from 'components/vertical-paginated-list/vertical-paginated-list'
import { useEffect, useState } from 'react'
import { FormSection } from 'components/form-section/form-section'

export const TokenList = () => {

    //hooks:
    const { token: {
        marginXXS,
        margin,
        paddingXXS,
        paddingXS,
        padding,
        paddingMD,
        borderRadius,
    }} = theme.useToken()
    const { __ } = useTranslate()
    const navigate = useNavigate()
    const { SM } = useWindowDimensions()
    const { data: config } = useConfig()

    //state:
    const [ search, setSearch ] = useState<string | null>(null)
    const [ toRemoveClientId, setToRemoveClientId ] = useState<string | null>(null)

    // redirect if it doesn't have access to token:
    useEffect(() => {
        if(config){
            if(!config.features.includes(Feature.PERSONAL_ACCESS_TOKEN)){
                navigate('/')
            }
        }
    }, [config])
    
    //api:
    const {
        data: tokens,
        pageNum,
        totalPage,
        isLoading: isTokensLoading,
        isPreviousData,
        isNextDisabled,
        isPreviousDisabled,
        fetchNext,
        fetchPrevious
    } = useTokens(search, 100)
    const {
        mutate: remove,
        isLoading: isRemoveLoading,
        data: removeResponse
    } = useRemoveToken(toRemoveClientId ?? '')

    //remove:
    useEffect(() => { toRemoveClientId && remove() }, [toRemoveClientId])

    //after remove:
    useEffect(() => {
        if(removeResponse){
            message.success(__`token_removed`)
            queryClient.invalidateQueries(['tokens'])
        }
    }, [isRemoveLoading])

    const items: MenuProps['items'] = [
        { key: 'copy', label: __`copy_client_id` },
        { key: 'remove', label: __`remove_token` }
    ]

    const handleClick = (key: string, token: PersonalAccessToken) => {
        switch(key){
            case 'copy':
                navigator.clipboard.writeText(token.clientId)
                message.success(__`copied`)
                break
            case 'remove':
                setToRemoveClientId(token.clientId)
                break
        }
    }
    
    return (
        <PageContentWrapper>

            <VerticalContainer style={{ gap: SM ? margin : marginXXS }}>

                {/* header */}
                <PageHeader
                    title={__`personal_access_tokens`}
                    description={__`manage_all_the_personal_access_tokens`}
                    primaryButton={{
                        name: SM ? __`create_token` : __`create`,
                        title: (config && config.canSetPermissions) ? undefined : __`you_dont_have_access_to_create_a_token`,
                        icon: <PlusOutlined/>,
                        mode: (config && config.canSetPermissions) ? 'normal' : 'disabled',
                        onClick: () => navigate('/token/new')
                    }}
                />

                <div style={{ padding: SM ? `0 ${padding}px` : 0 }}>
                
                    <FormSection
                        title={SM ? __`tokens` : undefined}
                        childrenWrapperStyle={{ padding: SM ? `0 ${paddingMD}px` : 0 }}
                        searchInput={{
                            placeholder: __`search_tokens`,
                            onSearch: setSearch
                        }}
                    >

                        {/* list */}
                        <VerticalPaginatedList
                            isFirstLoading={isTokensLoading}
                            isForbidden={tokens === null}
                            isError={tokens === false}
                            notFound={(tokens && tokens.length === 0) ?? false}
                            totalPage={totalPage}
                            pageNum={pageNum}
                            isPreviousData={isPreviousData}
                            isNextDisabled={isNextDisabled}
                            isPreviousDisabled={isPreviousDisabled}
                            fetchNext={fetchNext}
                            fetchPrevious={fetchPrevious}
                        >
                            {tokens && tokens.map((token: PersonalAccessToken) => (
                                <Avatar
                                    key={token.id}
                                    name={token.name}
                                    description={token.clientId}
                                    style={{
                                        width: '100%',
                                        padding: SM ? `${paddingXXS}px 0 ${paddingXXS}px ${paddingXS}px` : `${paddingXXS}px ${paddingXS}px ${paddingXXS}px ${paddingMD}px`,
                                        borderRadius: SM ? borderRadius : 0
                                    }}
                                    extraIcon={
                                        <Dropdown
                                            menu={{
                                                items,
                                                onClick: e => handleClick(e.key, token)
                                            }}
                                            trigger={['click']}
                                        >
                                            <Button
                                                type={'text'}
                                                shape={'circle'}
                                                size={'large'}
                                                icon={<MoreOutlined/>}
                                                onClick={e => {
                                                    e.preventDefault()
                                                    e.stopPropagation()
                                                }}
                                            />
                                        </Dropdown> 
                                    }
                                />
                            ))}
                        </VerticalPaginatedList>

                    </FormSection>

                </div>

            </VerticalContainer>
            
        </PageContentWrapper>
    )
    
}
