import styled from 'styled-components'
import { BOOKING_CALENDAR_MESSAGE_CONTAINER_Z_INDEX } from 'z-indexes'

export const CalendarLoadingContainer = styled.div`
    flex: 1;
    width: 100%;
    position: relative;
`

export const CalendarContainer = styled.div`
    width: 100%;
    display: contents;
    position: absolute;
    top: 0;
    left: 0;
`

export const MessageContainer = styled.div`
    position: absolute;
    top: 20%;
    left: 50%;
    transform: translate(-50%, 0);
    z-index: ${BOOKING_CALENDAR_MESSAGE_CONTAINER_Z_INDEX};
`
