import styled from 'styled-components'

export const MainWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: ${props => `${props.theme.marginSM}px`};
`

export const OptionsWrapper = styled.div`
    width: 100%;
    padding-left: ${props => `${props.theme.padding}px`};
    padding-right: ${props => `${props.theme.padding}px`};
    display: flex;
    flex-direction: column;
    gap: ${props => `${props.theme.marginSM}px`};
`
