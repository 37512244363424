import { DescriptionWrapper, MainWrapper, TitleNameWrapper, TitleWrapper } from './appointment-details-order.style'
import { Info } from 'components/info/info'
import { getStatusText, getStatusType, useTranslate } from 'utils'
import { Typography, theme } from 'antd'
import { Label } from 'components/styled'
const { Text } = Typography

type Props = {
    id: string
    name: string | null
    price: number
    currency: string
    status: string
}

export const AppointmentDetailsOrder = ({ id, name, price, currency, status }: Props) => {

    //translate:
    const { __ } = useTranslate()

    //theme:
    const { token: { fontSizeSM, colorTextDescription } } = theme.useToken()

    //render:
    return (
        <MainWrapper>

            <TitleWrapper>

                <TitleNameWrapper>

                    <Text style={{ fontFamily: 'inter-medium' }}>
                        {__`order`}
                    </Text>

                    <Info value={name ?? id}/>

                </TitleNameWrapper>

                <Label
                    text={getStatusText(status, __)}
                    type={getStatusType(status)}
                />

            </TitleWrapper>

            <DescriptionWrapper>

                <Text
                    title={__`order_id`}
                    style={{
                        fontSize: fontSizeSM,
                        color: colorTextDescription
                    }}
                >
                    {id}
                </Text>
                <Text
                    style={{
                        fontSize: fontSizeSM,
                        color: colorTextDescription
                    }}
                >
                    • {price.toString()} {currency}
                </Text>

            </DescriptionWrapper>

        </MainWrapper>
    )
}
