import { Button, theme } from 'antd'
import { Avatar } from 'components/avatar/avatar'
import { getShopId, setUnavailableCache, useTranslate, useWindowDimensions } from 'utils'
import { HorizontalContainer, Label } from 'components/styled'
import { TeamMember } from 'types'
import { useNavigate } from 'react-router-dom'

type Props = {
    teamMember: TeamMember
}

export const TeamMemberItem = ({ teamMember }: Props) => {

    //hooks:
    const { token: { paddingXXS, paddingXS, paddingSM, padding, borderRadius } } = theme.useToken()
    const { __ } = useTranslate()
    const navigate = useNavigate()
    const { SM } = useWindowDimensions()

    return (
        <Button
            type='text'
            style={{
                width: '100%',
                height: 'auto',
                padding: SM ? `0 ${paddingXS}px 0 0` : `0 ${padding}px 0 ${paddingSM}px`,
                borderRadius: SM ? borderRadius : 0
            }}
            onClick={() => {
                setUnavailableCache(['teamMember', teamMember.id, getShopId()], teamMember)
                navigate(`/team/${teamMember.id}`)
            }}
        >

            <HorizontalContainer style={{ width: '100%' }}>

                {/* image and title */}
                <Avatar
                    name={teamMember.name}
                    image={teamMember.image.path}
                    description={teamMember.description}
                    style={{ paddingLeft: paddingXXS }}
                />
                
                {/* label */}
                <Label
                    type={teamMember.status ? 'success' : 'disable'}
                    text={teamMember.status ? __`enabled`: __`disabled`}
                />

            </HorizontalContainer>

        </Button>
    )
    
}
