import { Button, Dropdown, MenuProps, message, theme } from 'antd'
import { Avatar } from 'components/avatar/avatar'
import { getShopId, queryClient, useTranslate, useWindowDimensions } from 'utils'
import { MoreOutlined } from '@ant-design/icons'
import { HorizontalContainer, Label, labelType } from 'components/styled'
import { ShopApp } from 'types'
import { useUninstallApp } from 'api'
import { useEffect } from 'react'

type Props = {
    app: ShopApp
    labelKey?: string
    labelType?: labelType
    onClick: () => void
}

export const InstalledAppItem = ({ app, labelKey, labelType, onClick }: Props) => {

    //hooks:
    const { token: { paddingXXS, paddingXS, paddingSM, padding, borderRadius } } = theme.useToken()
    const { __ } = useTranslate()
    const { SM } = useWindowDimensions()
    const { mutate: uninstall, data: uninstallResponse } = useUninstallApp(app.id)

    //items:
    const items: MenuProps['items'] = [
        { key: 'uninstall', label: __`uninstall`}
    ]

    //on menu clicked:
    const onMenuClick: MenuProps['onClick'] = ({ domEvent, key }) => {
        domEvent.stopPropagation()
        switch(key){
            case 'uninstall':
                uninstall()
                break
        }
    }

    useEffect(() => {
        if(uninstallResponse){
            queryClient.invalidateQueries(['installedApps', getShopId()])
            message.success(__('app_name_uninstalled', { appName: app.title }))
        }
    }, [uninstallResponse])

    return (
        <Button
            type='text'
            style={{
                width: '100%',
                height: 'auto',
                padding: SM ? `0 ${paddingXS}px 0 0` : `0 ${padding}px 0 ${paddingSM}px`,
                borderRadius: SM ? borderRadius : 0
            }}
            onClick={onClick}
        >

            <HorizontalContainer style={{ width: '100%' }}>

                <HorizontalContainer>

                    {/* image and title */}
                    <Avatar
                        name={app.title || app.name}
                        image={app.icon.path}
                        description={app.description}
                        style={{ paddingLeft: paddingXXS }}
                    />
                    
                    {/* label */}
                    {labelKey && labelType && (
                        <Label
                            type={labelType}
                            text={__(labelKey)}
                        />
                    )}

                </HorizontalContainer>

                {/* options */}
                <Dropdown menu={{ items, onClick: onMenuClick }} trigger={['click']}>
                    <Button
                        type={'text'}
                        shape={'circle'}
                        size={'large'}
                        icon={<MoreOutlined/>}
                        onClick={e => e.stopPropagation()}
                    />
                </Dropdown>

            </HorizontalContainer>

        </Button>
    )
    
}
