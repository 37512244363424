export const flowVariables = [
    '{{CustomerName}}',
    '{{CustomerEmail}}',
    '{{CustomerPhone}}',
    '{{CustomerBillingPhone}}',
    '{{CustomerManagementLink}}',
    '{{CustomerTimezone}}',
    '{{ServiceName}}',
    '{{ServiceDuration}}',
    '{{ServicePrice}}',
    '{{ServiceDescription}}',
    '{{ServiceLocation}}',
    '{{ServiceImageURL}}',
    '{{VariantTitle}}',
    '{{AppointmentID}}',
    '{{AppointmentCreatedAt}}',
    '{{OrderID}}',
    '{{Quantity}}',
    '{{LineItemProperties}}',
    '{{TeamMemberName}}',
    '{{TeamMemberBio}}',
    '{{TeamMemberEmail}}',
    '{{TeamMemberPhone}}',
    '{{TeamMemberDescription}}',
    '{{DateTime}}'
]
