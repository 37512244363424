import { ReactNode } from 'react'
import {
    DesktopOutlined,
    CalendarOutlined,
    CustomerServiceOutlined,
    RetweetOutlined,
    SettingOutlined,
    AppstoreAddOutlined,
    CodeOutlined,
    TeamOutlined,
    SecurityScanOutlined
} from '@ant-design/icons'
import { Button, Typography, theme } from 'antd'
import { useNavigate } from 'react-router-dom'
import { useTranslate } from 'utils'
import { FormSection } from 'components/form-section/form-section'
import { useConfig } from 'api'
import { isMenuEnable, isMenuVisible } from 'data'
const { Text } = Typography

//menuItems:
const menuItems = [
    { title: 'dashboard'   , navTo: '/'          , getIcon: (fontSize: number, color: string) => <DesktopOutlined         style={{ fontSize, color }} />},
    { title: 'calendar'    , navTo: '/calendar'  , getIcon: (fontSize: number, color: string) => <CalendarOutlined        style={{ fontSize, color }} />},
    { title: 'services'    , navTo: '/service'   , getIcon: (fontSize: number, color: string) => <CustomerServiceOutlined style={{ fontSize, color }} />},
    { title: 'team'        , navTo: '/team'      , getIcon: (fontSize: number, color: string) => <TeamOutlined            style={{ fontSize, color }} />},
    { title: 'flows'       , navTo: '/flow'      , getIcon: (fontSize: number, color: string) => <RetweetOutlined         style={{ fontSize, color }} />},
    { title: 'users'       , navTo: '/user'      , getIcon: (fontSize: number, color: string) => <TeamOutlined            style={{ fontSize, color }} />},
    { title: 'apps'        , navTo: '/app'       , getIcon: (fontSize: number, color: string) => <AppstoreAddOutlined     style={{ fontSize, color }} />},
    { title: 'developers'  , navTo: '/developers', getIcon: (fontSize: number, color: string) => <CodeOutlined            style={{ fontSize, color }} />},
    { title: 'tokens'      , navTo: '/token'     , getIcon: (fontSize: number, color: string) => <SecurityScanOutlined    style={{ fontSize, color }} />},
    { title: 'settings'    , navTo: '/settings'  , getIcon: (fontSize: number, color: string) => <SettingOutlined         style={{ fontSize, color }} />}
]

export const SideMenu = () => {

    //hooks:
    const { token: { marginXXS, marginXS, padding }} = theme.useToken()
    const { __ } = useTranslate()

    return (
        <FormSection
            style={{
                paddingTop: padding,
                backgroundColor: 'transparent',
                border: 'none',
                boxShadow: 'none',
                position: 'sticky',
                top: `${
                    60 //header's height
                    + marginXXS // gap
                    + marginXS // self margin
                }px`
            }}
            childrenWrapperStyle={{ gap: 0 }}
        >

            {menuItems.map(item => (
                <MenuItem
                    key={item.title}
                    title={__(item.title)}
                    getIcon={item.getIcon}
                    navTo={item.navTo}
                    isSelected={isNavSelected(item.navTo)}
                />
            ))}

        </FormSection>
    )

}

type MenuItemProps = {
    title: string
    isSelected: boolean
    navTo: string
    getIcon: (fontSize: number, color: string) => ReactNode
}

const MenuItem = ({ title, isSelected, navTo, getIcon }: MenuItemProps) => {

    //hooks:
    const { token: {
        fontSizeLG,
        paddingXXS,
        paddingLG,
        marginSM,
        colorText,
        colorTextSecondary,
        colorBgContainer,
        colorInfo,
        borderRadiusLG
    }} = theme.useToken()
    const { data: config } = useConfig()
    const navigate = useNavigate()
    
    return config && isMenuVisible(navTo, config) ? (
        <Button
            type='text'
            size='large'
            style={{
                minWidth: 175,
                padding: `${paddingXXS}px ${paddingLG}px`,
                display: 'flex',
                justifyContent: 'flex-start',
                alignItems: 'center',
                backgroundColor: isSelected ? colorBgContainer : undefined,
                borderRadius: borderRadiusLG * 3,
                gap: marginSM
            }}
            disabled={!isMenuEnable(navTo, config)}
            onClick={() => navigate(navTo)}
        >
            {getIcon(fontSizeLG, isSelected ? colorInfo : colorTextSecondary)}
            <Text style={{
                margin: 0,
                fontFamily: 'inter-medium',
                fontSize: fontSizeLG,
                color: isSelected ? colorText : colorTextSecondary
            }}>
                {title}
            </Text>
        </Button>
    ) : <></>

}

//isNavSelected:
const isNavSelected = (navTo: string) => {

    const path = location.pathname
    let isSelected = false

    if(navTo === '/'){
        isSelected = path === '/' || path === '/appointments'
    }else{
        isSelected = path.includes(navTo)
    }

    return isSelected

}
