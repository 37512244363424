import { TranslateFunction } from './translate'

//getStatusText:
export const getStatusText = (status: string, __ : TranslateFunction) => {
    switch(status){
        case 'pending':
        case 'paid':
        case 'cancelled':
        case 'refunded':
            return __(status)
        default:
            return status
    }
}

//getStatusType:
export const getStatusType = (status: string) => {
    switch(status){
        case 'pending':
            return 'warning'
        case 'paid':
            return 'success'
        case 'cancelled':
            return 'danger'
        default:
            return 'disable'
    }
}
