import { PropsWithChildren, ReactNode } from 'react'
import { Typography, theme } from 'antd'
import { Logo, VerticalContainer } from 'components/styled'
import { FooterButtonAction, LoginFooter } from 'components/login/login-footer/login-footer'
import { FormSection } from 'components/form-section/form-section'
import { useWindowDimensions } from 'utils'
import { ScreenSize } from 'types'
const { Text, Paragraph } = Typography

type Prop = {
    title: string
    description: string
    footerHelperText: string | null
    footerButtonAction: FooterButtonAction | null
    footerButtonIcon: ReactNode | null
    onFooterSelect: (action: FooterButtonAction) => void
}

export const LoginUiWrapper = ({
    title,
    description,
    children,
    footerHelperText,
    footerButtonAction,
    footerButtonIcon,
    onFooterSelect
}: PropsWithChildren<Prop>) => {

    //theme:
    const { token: {
        marginLG,
        marginXL,
        paddingSM,
        paddingXS,
        paddingXL,
        fontSizeSM,
        fontSizeLG,
        colorBgLayout,
        colorBgContainer
    }} = theme.useToken()

    //screen:
    const { height, SM } = useWindowDimensions()
    
    //render:
    return (
        <div style={{
            width: '100%',
            height: '100%',
            backgroundColor: (SM && height > ScreenSize.SM) ? colorBgLayout : colorBgContainer
        }}>

            <FormSection
                style={{
                    width: '100%',
                    maxWidth: 380,
                    maxHeight: '100%',
                    padding: `${SM ? paddingXL : paddingSM}px ${paddingXL}px `,
                    border: (height > ScreenSize.SM) ? undefined : 'none',
                    boxShadow: (height > ScreenSize.SM) ? undefined : 'none',
                    position: 'absolute',
                    top: SM ? '47%' : '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)'
                }}
                childrenWrapperStyle={{ padding: 0 }}
            >

                <VerticalContainer style={{ gap: marginLG, padding: `${paddingSM}px ${paddingXS}px` }}>
                
                    {/* logo */}
                    <Logo withName={true}/>

                    <VerticalContainer>

                        <VerticalContainer style={{ gap: 0 }}>

                            {/* message */}
                            <Text style={{ width: '100%', fontSize: fontSizeLG, fontFamily: 'inter-medium', textAlign: 'center' }}>
                                {title}
                            </Text>

                            {/* description */}
                            <Paragraph
                                type='secondary'
                                style={{ fontSize: fontSizeSM, textAlign: 'center' }}
                            >
                                {description}
                            </Paragraph>

                        </VerticalContainer>

                        <VerticalContainer style={{ gap: marginXL }}>

                            {children}

                            {/* footer */}
                            <LoginFooter
                                helperText={footerHelperText}
                                buttonTextKey={footerButtonAction}
                                buttonIcon={footerButtonIcon}
                                onSelect={onFooterSelect}
                            />
                            
                        </VerticalContainer>

                    </VerticalContainer>

                </VerticalContainer>

            </FormSection>
        
        </div>
    )

}
