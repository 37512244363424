import { MainWrapper } from './appointment-details-navigation.style'
import { LeftOutlined } from '@ant-design/icons'
import { Button, theme } from 'antd'
import { Avatar } from 'components/avatar/avatar'

type Props = {
    image?: string
    onClose: () => void
}

export const AppointmentDetailNavigation = ({ image, onClose }: Props) => {

    //theme:
    const { token: { marginSM } } = theme.useToken()

    //render:
    return (
        <MainWrapper>
            
            <Button
                type='text'
                shape='circle'
                size='large'
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    marginTop: -marginSM,
                    marginLeft: -marginSM
                }}
                onClick={onClose}
            >
                <LeftOutlined/>
            </Button>

            {image &&
                <Avatar
                    image={image}
                    size={'tiny'}
                />
            }

        </MainWrapper>
    )
}
