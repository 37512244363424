import styled from 'styled-components'

type MembersWrapperProp = {
    isEmpty: boolean
}

export const MembersWrapper = styled.div<MembersWrapperProp>`
    display: flex;
    flex-wrap: wrap;
    justify-content: ${props => props.isEmpty ? 'center' : 'flex-start'};
    gap: ${props => `${props.theme.marginSM}px`};
`

type AddMembersProp = {
    isVisible: boolean
}

export const AddMembersWrapper = styled.div<AddMembersProp>`
    margin-top: ${props => `${props.theme.paddingSM}px`};
    padding-top: ${props => `${props.theme.padding}px`};
    border-top: solid 1px ${props => props.theme.colorBorderSecondary};
    display: ${props => props.isVisible ? 'flex' : 'none'};
    flex-direction: column;
    gap: ${props => `${props.theme.margin}px`};
`
