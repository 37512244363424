import { InfoWrapper, MainContainer, MainWrapper } from './appointment-details-customer.style'
import { Typography, theme } from 'antd'
import { Avatar } from 'components/avatar/avatar'
import { useTouchDetector, useTranslate } from 'utils'
import { CheckOutlined, CopyOutlined } from '@ant-design/icons'
import { useState } from 'react'
import { Customer } from 'types'
const { Text, Paragraph } = Typography

type Props = {
    customer: Customer | null
    isReserved: boolean
}

export const AppointmentDetailsCustomer = ({ customer, isReserved }: Props) => {

    //translate:
    const { __ } = useTranslate()

    //theme:
    const { token: { fontSizeLG, fontSizeSM, paddingXXS, colorInfoActive, colorTextDescription, colorBgContainer } } = theme.useToken()

    //touchscreen
    const { isTouchscreen } = useTouchDetector()

    //name:
    const customerName  = customer?.name ?? __`unknown`
    const customerPhone = customer?.phone ?? customer?.billingPhone ?? __`unknown`
    const customerEmail = customer?.email ?? __`unknown`

    //state:
    const [ hoveredText, setHoveredText ] = useState('')

    //render:
    return (
        <MainWrapper>

            <MainContainer>

                <InfoWrapper>

                    {/* name */}
                    <Text
                        style={{
                            fontSize: fontSizeLG,
                            fontFamily: 'inter-medium'
                        }}
                    >
                        {isReserved ? __`reserved` : customerName}
                    </Text>

                    {/* phone */}
                    {!isReserved && (
                        <Paragraph
                            style={{
                                fontSize: fontSizeSM,
                                color: colorTextDescription,
                                width: 'fit-content',
                                margin: 0,
                                display: 'flex',
                                alignItems: 'center'
                            }}
                            copyable={{
                                icon: [
                                    <CopyOutlined  key={1} style={{ opacity: hoveredText === customerPhone || isTouchscreen ? 1 : 0, padding: paddingXXS, color: colorTextDescription }}/>,
                                    <CheckOutlined key={2} style={{ opacity: hoveredText === customerPhone || isTouchscreen ? 1 : 0, padding: paddingXXS, color: colorInfoActive      }}/>
                                ],
                                tooltips: [__`copy`, __`copied`]
                            }}
                            onMouseEnter={() => setHoveredText(customerPhone)}
                            onMouseLeave={() => setHoveredText('')}
                        >
                            {customerPhone}
                        </Paragraph>
                    )}

                    {/* email */}
                    {!isReserved && (
                        <Paragraph
                            style={{
                                fontSize: fontSizeSM,
                                color: colorTextDescription,
                                width: 'fit-content',
                                margin: 0,
                                display: 'flex',
                                alignItems: 'center'
                            }}
                            copyable={{
                                icon: [
                                    <CopyOutlined  key={1} style={{ opacity: hoveredText === customerEmail || isTouchscreen ? 1 : 0, padding: paddingXXS, color: colorTextDescription }}/>,
                                    <CheckOutlined key={2} style={{ opacity: hoveredText === customerEmail || isTouchscreen ? 1 : 0, padding: paddingXXS, color: colorInfoActive      }}/>
                                ],
                                tooltips: [__`copy`, __`copied`]
                            }}
                            onMouseEnter={() => setHoveredText(customerEmail)}
                            onMouseLeave={() => setHoveredText('')}
                        >
                            {customerEmail}
                        </Paragraph>
                    )}

                </InfoWrapper>

                {/* avatar */}
                {!isReserved && (
                    <Avatar
                        image={customer?.image.path ?? null}
                        color={colorTextDescription}
                        backgroundColor={colorBgContainer}
                        size='small'
                    />
                )}

            </MainContainer>

        </MainWrapper>
    )
}
