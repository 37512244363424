import { AppContainer } from './all-apps-list.style'
import { theme } from 'antd'
import { useTranslate } from 'utils'
import { PageContentWrapper, VerticalContainer } from 'components/styled'
import { useNavigate } from 'react-router-dom'
import { useEffect } from 'react'
import { useAllApps, useConfig } from 'api'
import { Feature } from 'types'
import { VerticalPaginatedList } from 'components/vertical-paginated-list/vertical-paginated-list'
import { AppCard } from 'components/app/app-card/app-card'
import { PageHeader } from 'components/page-header/page-header'

export const AllAppsList = () => {

    //hooks:
    const { token: { marginXS } } = theme.useToken()
    const { data: config } = useConfig()
    const { __ } = useTranslate()
    const navigate = useNavigate()

    //all apps:
    const {
        data: apps,
        pageNum,
        totalPage,
        isLoading,
        isPreviousData,
        isNextDisabled,
        isPreviousDisabled,
        fetchNext,
        fetchPrevious
    } = useAllApps(null, 12)

    //if apps are not enabled:
    useEffect(() => { config && !config.features.includes(Feature.APPS) && navigate('/') }, [config])
    
    return (!config || !config.features.includes(Feature.APPS)) ? <></> : (
        
        <PageContentWrapper fullWidth>

            <VerticalContainer style={{ gap: marginXS }}>

                {/* header */}
                <PageHeader
                    title={__`all_apps`}
                    description={__`choose_an_app_for_your_shop`}
                    backButton={{
                        name: __`back`,
                        onClick: () => navigate('/app')
                    }}
                />

                {/* list */}
                <VerticalPaginatedList
                    isFirstLoading={isLoading}
                    isForbidden={apps === null}
                    isError={false}
                    notFound={(apps && apps.length === 0) ?? false}
                    totalPage={totalPage}
                    pageNum={pageNum}
                    isPreviousData={isPreviousData}
                    isNextDisabled={isNextDisabled}
                    isPreviousDisabled={isPreviousDisabled}
                    fetchNext={fetchNext}
                    fetchPrevious={fetchPrevious}
                >
                    
                    <AppContainer>
                        {apps && apps.map(app => <AppCard key={app.id} app={app}/>)}
                    </AppContainer>

                </VerticalPaginatedList>

            </VerticalContainer>

        </PageContentWrapper>
    )
    
}
