import { AvatarSize } from './avatar'
import styled from 'styled-components'

export const DivContainer = styled.div`
    width: fit-content;
    max-width: 100%;
    height: auto;
    overflow: hidden;
`

type MainWrapperProps = {
    isVertical: boolean
    size: AvatarSize
}

export const MainWrapper = styled.div<MainWrapperProps>`
    height: ${props => props.isVertical ? '100%' : 'unset'};
    display: flex;
    flex-direction: ${props => props.isVertical ? 'column' : 'row'};
    align-items: center;
    gap: ${
        props => props.isVertical ? (
            props.size === 'tiny'   ? `${props.theme.marginXXS}px` :
            props.size === 'small'  ? `${props.theme.marginXXS}px` :
            props.size === 'medium' ? `${props.theme.marginXS }px` :
            props.size === 'large'  ? `${props.theme.marginXS }px` : null
        ) : (
            props.size === 'tiny'   ? `${props.theme.marginXS }px` :
            props.size === 'small'  ? `${props.theme.marginXS }px` :
            props.size === 'medium' ? `${props.theme.margin }px` :
            props.size === 'large'  ? `${props.theme.marginMD }px` : null
        )
    };
`

type ImageContainerProps = {
    size: number
}

export const ImageContainer = styled.div<ImageContainerProps>`
    width: ${props => `${props.size}px`};
    height: ${props => `${props.size}px`};
`

export const NameDescriptionContainer = styled.div`
    flex: 1;
    max-width: 100%;
    display: flex;
    flex-direction: column;
    gap: ${props => `${props.theme.marginXXS/2}px`};
    overflow: hidden;
`

type NameTagContainer = {
    isVertical: boolean
}

export const NameTagContainer = styled.div<NameTagContainer>`
    flex: 1;
    display: flex;
    flex-direction: ${props => props.isVertical ? 'column' : 'row'};
    align-items: center;
    gap: ${props => `${props.theme.marginXXS}px`};
    overflow: hidden;
`
