import { useEffect, useState } from 'react'

export const useNow = (timezoneOffset: number) => {

    const [ now, setNow ] = useState(calcNowWithOffset(timezoneOffset))
  
    useEffect(() => {
        const interval = setInterval(() => {
            const nnn = calcNowWithOffset(timezoneOffset)
            setNow(nnn)
        }, 1000)
        return () => clearInterval(interval)
    }, [timezoneOffset])

    return now
    
}

//calcNowWithOffset:
const calcNowWithOffset = (timezoneOffset: number) => {
    const browserNow = new Date()
    return new Date(browserNow.getTime() + timezoneOffset*60*1000)
}
