import { isNil } from './is-nil'
import { useNavigate } from 'react-router-dom'
import { ACCESS_TOKEN_LCL_STR_KEY, REFRESH_TOKEN_LCL_STR_KEY } from 'app.constants'
import { requestLogout } from 'api'

// an interface declaration to handle the window types:
declare global {
    interface Window {
        refreshToken: string | null,
        accessToken: string | null,
        isAccessTokenRefreshing: boolean
    }
}

//useAuthentication:
export const useAuthentication = () => {

    //navigate:
    const navigate = useNavigate()

    const isLoggedIn = refreshToken() !== null

    const login = (refreshToken: string, accessToken: string) => {
        updateRefreshToken(refreshToken)
        updateAccessToken(accessToken)
        navigate('/')
    }

    const logout = () => {
        requestLogout()
        updateRefreshToken(null)
        updateAccessToken(null)
        navigate('/login')
    }

    return { login, isLoggedIn, logout }

}

export const refreshToken = () => {
    let token = null
    try{
        token = localStorage.getItem(REFRESH_TOKEN_LCL_STR_KEY)
    }catch(e){
        token = window.refreshToken
    }
    return isNil(token) ? null : token
}

export const updateRefreshToken = (token: string | null) => {
    try{
        if(token){
            localStorage.setItem(REFRESH_TOKEN_LCL_STR_KEY, token)
        }else{
            localStorage.removeItem(REFRESH_TOKEN_LCL_STR_KEY)
        }
    }catch(e){
        window.refreshToken = token
    }
}

export const accessToken = () => {
    let token = null
    try{
        token = localStorage.getItem(ACCESS_TOKEN_LCL_STR_KEY)
    }catch(e){
        token = window.accessToken
    }
    return isNil(token) ? null : token
}

export const updateAccessToken = (token: string | null) => {
    try{
        if(token){
            localStorage.setItem(ACCESS_TOKEN_LCL_STR_KEY, token)
        }else{
            localStorage.removeItem(ACCESS_TOKEN_LCL_STR_KEY)
        }
    }catch(e){
        window.accessToken = token
    }
}
