import styled from 'styled-components'

export const Table = styled.table`
    width: 100%;
    border-spacing: 0;
`

export const Row = styled.tr`
    width: 100%;
`

export const Head = styled.th`
    width: 1%;
`

export const Cell = styled.td`
    width: 1%;
    padding: 0;
`
